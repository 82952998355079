/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appId, cateringId, apiUrlV2, reservationId} from "../Helpers/Config";
import { getReferenceID, randomId, showLoader, hideLoader, showAlert, showCartItemCount, showCustomAlert, stripslashes } from "../Helpers/SettingHelper";
import cookie from 'react-cookies';
import axios from 'axios';
var qs = require('qs');
import Parser from 'html-react-parser';
import ClockImg from "../../common/images/clock.png";
import CantDlyImg from "../../common/images/Cant_Deliver.png";

import { GET_RES_AVAILABLE_DATE, GET_RES_AVAILABLE_TIME} from '../../actions';

class Reservationtimelist extends Component {


    constructor(props) {
        super(props);

        this.state = {
            avlTimelist: '',
            avlTime:'',
            time_flag_next: 'NEXT',
        };
        
        this.getAvailabileTimeHtml = this.getAvailabileTimeHtml.bind(this);
		
		
    }
    
    componentDidMount() {
		
		if(typeof cookie.load('reservation_show_time')!='undefined' && typeof cookie.load('reservation_show_time')!='') {
			this.setState({avlTime:cookie.load('reservation_show_time')});
		}
		cookie.save("reservation_nextPrev", '');
		//console.log('asas',this.state.avlTime);
	}
    
    componentWillReceiveProps(ResProps) {
		
		var TimeListArr = ResProps.TimelistOptions.timeListArr;
		
		if(Object.keys(TimeListArr).length > 0) {
			this.setState({avlTimelist:TimeListArr},function(){this.getAvailabileTimeHtml()});
		}
		
	}
	
	setReservationTime(selectedRegTime){
		var RegTime = selectedRegTime['time'];
		this.setState({avlTime:RegTime},function(){this.reservationTimeAvl(selectedRegTime); this.getAvailabileTimeHtml()}.bind(this));
		
	}
	
	reservationTimeAvl(selectedTime){
		
		var Seltime = Array();
		
		Seltime['reservation_time'] = selectedTime['time'];
		Seltime['reservation_time_type'] = selectedTime['type'];
		
		this.props.SelectedTimeOption(Seltime);
		
	}

	showNextTimes(currentTime, prevNext, stopprevNext) {
		if(stopprevNext==0) {
		
			var today = new Date();
			
			var month = today.getMonth()+1;
			if(today.getMonth()+1<10) {
				month = '0'+(today.getMonth()+1);
			}
			
			var dateDate = today.getFullYear().toString().substr(-2)+'-'+month+'-'+today.getDate();

			var time_avail = Array();
			time_avail['app_id'] = appId;
			time_avail['outlet_id'] = this.props.stateVal.reservation_outlet_id;
			if(prevNext!='') {
				time_avail['flag_key'] = prevNext;
			}
			else {
				day_avail['flag_key'] = this.state.reservation_flag_next;	
			}
			this.setState({time_flag_next:prevNext});
			
			time_avail['availability_date'] = this.props.stateVal.user_reservation_date;
			time_avail['flag_time'] = currentTime;
			this.props.timeAvlfun(time_avail);		
		}
	}
	

	formatAMPM(date) {
		  var hours = date.getHours();
		  var minutes = date.getMinutes();
		  var ampm = hours >= 12 ? 'pm' : 'am';
		  hours = hours % 12;
		  hours = hours ? hours : 12; // the hour '0' should be '12'
		  minutes = minutes < 10 ? '0'+minutes : minutes;
		  if(hours<10) {
		  	hours = '0'+hours;
		  }
	
		  var minutesNew = Math.ceil(minutes / 15) * 15;
		  if(minutesNew>60) {
		  	minutesNew = 0;
		  }
		  if(minutesNew<10) {
		  	minutesNew = '0'+minutesNew;
		  }
		  

		  var strTime = hours + ':' + minutesNew + ' ' + ampm;
		  return strTime;
	}
	
	
	getAvailabileTimeHtml(){
		
		var AvailabileTimeList = this.state.avlTimelist;
		var availabileTimeListLenght = AvailabileTimeList.length;
		var AvlTimeList = '';
		var thisVal = this;
		if(Object.keys(AvailabileTimeList).length > 0) {
			
			AvlTimeList = <div className="reg_timeList">
							{this.loadTimes(AvailabileTimeList)}
						  </div>
		}else{
			AvlTimeList = <div className="reg_timeList">
							<p>No available Times..</p>
						</div>
		}
		
		this.setState({AvlTimeList:AvlTimeList});
	}
	
	loadTimes(AvailabileTimeList) {
		var availabileTimeListLenght = AvailabileTimeList.length;
		const items = [];
		var disablePrev = 0;
		if(typeof this.props.TimelistOptions.times_divide_pre!='undefined' && this.props.TimelistOptions.times_divide_pre==0) {
			disablePrev = 1;
		}
		var disableNext = 0;
		if(typeof this.props.TimelistOptions.times_divide_next!='undefined' && this.props.TimelistOptions.times_divide_next==0) {
			disableNext = 1;
		}
		var thisVal = this;
		for (var i = 0; i <= 12; i++) {
			var timelist = AvailabileTimeList[i];
			if(i==0) {
				cookie.save("reservation_next_start_time", timelist.time);	
			}			
			if(i==10) {
				items.push(
					<li className={(disablePrev==1)?"days-pre-close-disable btn-disable-action":"catering_date"} onClick={thisVal.showNextTimes.bind(thisVal,AvailabileTimeList[0]['time'], 'PRE', disablePrev)} key={i+'p'}>
						<dl className="cl-next-days">
							<div>
								<span>Go BAck To Previous</span>
							</div>
						</dl>
					</li>
				);
			}
			if(typeof timelist!=='undefined') {
					items.push(
					<div className="TimeList" key={i}>
					<div>
					<li className="catering_time" onClick={thisVal.setReservationTime.bind(thisVal,timelist)}>
						<dl className={(thisVal.state.avlTime== timelist.time)?"time_inner active":"time_inner"}  id={timelist.time}>
							<div>
								<dt>Time Range</dt>
								<dd>{timelist.time}</dd>
							</div>
						</dl>
					</li>
					</div>
					</div>
					);
				}
				else {
					items.push(
					<div className="TimeList" key={i}>
						<div>
							<li className="catering_time"></li>
						</div>
					</div>
					);
				}
				if(i==12) {
					items.push(
						<li className={(disableNext==1)?"days-pre-close-disable btn-disable-action":"catering_date"}  onClick={thisVal.showNextTimes.bind(thisVal,AvailabileTimeList[availabileTimeListLenght-1]['time'], 'NEXT', disableNext)}  key={i+'n'}>
							<dl className="cl-next-days">
								<div>
									<span>Show Next Time</span>
								</div>
							</dl>
						</li>
					);
				}
		}
		return items;
	}

	
    /* ViewProducts */

    render() {
		
        return (
		<div className="choose-date">
			<div className="choose-time">
				<h3>Choose a time</h3>
			</div>
			<div className="resp-datepicker datepicker_main_div">
			   <ul className="catering_days_ul">
			   
					{this.state.AvlTimeList}
					
			   </ul>
			</div>
		</div>
		);
     }
}

const mapStateTopProps = (state) => {
	//console.log('resr',this.state.reservationdate);
  
}

const mapDispatchToProps = (dispatch) => {
  return {
	  
	getResAvailabileDateList: (avlDateFlg) => {
      dispatch({ type: GET_RES_AVAILABLE_DATE, avlDateFlg });
    },
   
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(Reservationtimelist);
