/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { format } from "date-fns";
import {
  appId,
  apiUrl,
  cateringId,
  apiUrlV2,
  CountryTxt,
} from "../../Helpers/Config";
import {
  getReferenceID,
  showLoader,
  hideLoader,
  showAlert,
  timeToConv12,
  dateConvFun,
  showCartItemCount,
  showCustomAlert,
  getGstValue,
  stripslashes,
  getPromoCkValue,
  removePromoCkValue,
  smoothScroll,
  getOrderDateTime,
  getCalculatedCateringAmount,
} from "../../Helpers/SettingHelper";
import cookie from "react-cookies";
import axios from "axios";
var qs = require("qs");
import Parser from "html-react-parser";

import { GET_CATERINGCART_DETAIL } from "../../../actions";

import Cartdetails from "./Cartdetails";
import Packageupdate from "./Packageupdate";
import CantDlyImg from "../../../common/images/Cant_Deliver.png";
import loadingImage from "../../../common/images/loading_popup.gif";
import tickImage from "../../../common/images/tick_popup.png";

class Checkout extends Component {
  constructor(props) {
    super(props);

    var getPromoCkVal = getPromoCkValue();
    var servicesurchargeArr = Array();
    servicesurchargeArr["servicechrg_displaylabel"] = "Service Charge";
    servicesurchargeArr["servicechrg_type"] = "percentage";
    servicesurchargeArr["servicechrg_per"] = 0;
    servicesurchargeArr["servicechrg_amount"] = 0;
    servicesurchargeArr["surcharge_amount"] = 0;
    servicesurchargeArr["surcharge_type"] = "";
    servicesurchargeArr["surcharge_date"] = "";
    servicesurchargeArr["surcharge_frmtime"] = "";
    servicesurchargeArr["surcharge_totime"] = "";
    this.state = {
      settings: [],
      staticblacks: [],
      catering_postal_code: "",
      catering_address_line: "",
      catering_address_line2: "",
      catering_floor_no: "",
      catering_unit_no: "",
      billing_addrs_sameas: "yes",
      billing_postal_code: "",
      billing_address_line: "",
      billing_floor_no: "",
      billing_unit_no: "",
      catering_specil_note: "",
      breaktime_count: 0,
      breaktime_enable: "",
      breaktime_list: [],
      edite_packageid: "",
      cart_packagedata: [],
      cart_maindata: [],
      edit_flag: 0,
      promotion_count: 0,
      reward_point_count: 0,
      promotion_applied: getPromoCkVal["promotionApplied"],
      promotion_type: getPromoCkVal["promotionType"],
      promotion_amount: getPromoCkVal["promotionAmount"],
      promotion_source: getPromoCkVal["promotionSource"],
      promoIs_delivery: getPromoCkVal["promoIsDelivery"],
      reward_point_val: getPromoCkVal["reedemPointVal"],
      promo_code_val: getPromoCkVal["promoCodeVal"],
      used_reward_point: getPromoCkVal["usedPoints"],
      cartDetailsData: [],
      cartItemsList: [],
      placingorderimage: loadingImage,
      completingpaymentimage: loadingImage,
      finalCartDetails: [],
      finalcartItems: [],
      catering_tarms_contn: "No",
      catering_tarms_contnew: "No",
      zone_id: "",
      termsAndConditions: "",
      servicesurchargeval: servicesurchargeArr,
    };
  }

  componentWillReceiveProps(categoryProps) {
    if (Object.keys(categoryProps).length > 0) {
      var categoryStateArr = categoryProps.categoryState;
      for (var key in categoryStateArr) {
        this.state[key] = categoryStateArr[key];
      }
    }

    var trgLgin = categoryStateArr.trigger_checkout_submit;
    if (trgLgin === "Yes") {
      cookie.save("loginpopupTrigger", "fromchecatering");
      this.props.sateValChangefun("checkout_submit", "Ok");
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var userIdTxt =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var ckeckOutVld = cookie.load("fromCkeckOutVld");
    var totalItems = cookie.load("cartTotalItems");

    if (userIdTxt !== "" && ckeckOutVld === "Yes" && parseInt(totalItems) > 0) {
      cookie.save("fromCkeckOutVld", "No");
      smoothScroll(100, 70);
      this.props.setActiveTab(4);
    }

    if (
      userIdTxt !== "" &&
      cookie.load("applyCatrngPromo") === "Yes" &&
      parseInt(totalItems) > 0
    ) {
      cookie.save("applyCatrngPromo", "No");
      this.props.setActiveTab(4);
    }

    if (this.state.active_tabflag === 4) {
      /*this.props.getCateringCartDetail();*/
      this.loadRewardPoints();
      this.loadCartList();
    }

    /*if(this.state.active_tabflag === 4 && this.state.cartItemsList !== categoryProps.cateringCartItems) {
			this.setState({cartDetailsData : categoryProps.cateringCartDetails, cartItemsList: categoryProps.cateringCartItems});
		}*/

    if (this.state.add_new_item === "Yes") {
      this.removePointsAndPromo();
      this.props.sateValChangefun("add_new_item", "No");
    }

    var CheckoutFromCart = cookie.load("cateringCheckoutFromCart");
    if (CheckoutFromCart === "Yes") {
      cookie.remove("cateringCheckoutFromCart");
      let $_this = this;
      setTimeout(function() {
        $_this.props.setActiveTab(4);
      }, 300);
    }

    var fromVenueFlg = cookie.load("fromVenueFlg");
    if (fromVenueFlg === "Yes") {
      cookie.remove("fromVenueFlg");
      this.props.setActiveTab(2);
      /*let $_this = this;
			setTimeout(function () {
				$_this.props.setActiveTab(2);
			}, 200);*/
    }

    if (categoryProps.staticblack !== this.state.staticblacks) {
      var termsConditions = "";
      if (Object.keys(categoryProps.staticblack).length > 0) {
        categoryProps.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "terms-and-conditions") {
            termsConditions = data.staticblocks_description;
            return "";
          }
        });
      }
      termsConditions =
        termsConditions !== "" ? Parser(termsConditions) : termsConditions;
      this.setState({
        staticblacks: categoryProps.staticblack,
        termsAndConditions: termsConditions,
      });

      setTimeout(function() {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }, 800);
    }

    setTimeout(function() {
      if ($(".checkout-terms-and-condition").length > 0) {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }
    }, 800);
    if (categoryProps.categoryState.callsurchareg === "Yes") {
      this.props.sateValChangefun("callsurchareg", "No");
      this.loadSurchare();
    }
  }

  componentDidMount() {
    this.getCommonSetting("");

    var fromOtherPageFlg =
      typeof cookie.load("fromOtherPageFlg") === "undefined"
        ? ""
        : parseInt(cookie.load("fromOtherPageFlg"));
    if (fromOtherPageFlg != "") {
      cookie.remove("fromOtherPageFlg");
      let $_this = this;
      setTimeout(function() {
        $_this.props.setActiveTab(fromOtherPageFlg);
      }, 1000);
    }

    setTimeout(function() {
      if ($(".checkout-terms-and-condition").length > 0) {
        $(".checkout-terms-and-condition").mCustomScrollbar();
      }
    }, 400);

    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function() {
        $(this)
          .parents(".focus-out")
          .addClass("focused");
      });
      $(".input-focus").blur(function() {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this)
            .parents(".focus-out")
            .removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }
    this.loadSurchare();
  }
  loadSurchare() {
    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? ""
        : cookie.load("orderTAT");
    var orderDateTmTxt = getOrderDateTime("", orderTAT);
    var formatedDate = format(orderDateTmTxt, "yyyy-MM-dd");
    var OrderHours = orderDateTmTxt.getHours();
    var OrderMunts = orderDateTmTxt.getMinutes();
    var orderTime = this.pad(OrderHours) + ":" + this.pad(OrderMunts);
    var OrdDateTimeArr = Array();
    OrdDateTimeArr["OrdDate"] = formatedDate;
    OrdDateTimeArr["OrdTime"] = orderTime;
    this.getServiceChargeAmt(OrdDateTimeArr);
  }

  getServiceChargeAmt(OrdDateTimeArr) {
    var availabilityIdTxt = cateringId;
    var orderOutletId = cookie.load("cateringOutletId");
    var orderDate = "";
    var orderTime = "";

    orderDate = OrdDateTimeArr["OrdDate"];
    orderTime = OrdDateTimeArr["OrdTime"];

    var servicesurchargeArr = this.state.servicesurchargeval;
    var online_servicecharge_type = servicesurchargeArr["servicechrg_type"];
    var onlineservice_displaylabel =
      servicesurchargeArr["servicechrg_displaylabel"];
    var online_servicecharge_per = 0,
      online_servicecharge_amount = 0,
      subchr_amount = 0;
    var subchr_type = "",
      subchr_date = "",
      subchr_frmtime = "",
      subchr_totime = "";

    var urlPrms =
      "&availabilityid=" +
      cateringId +
      "&outletid=" +
      orderOutletId +
      "&orderdate=" +
      orderDate +
      "&ordertime=" +
      orderTime +
      "&";
    var urlShringTxt =
      apiUrl + "outlets/outletsubchargenew?app_id=" + appId + urlPrms;
    axios.get(urlShringTxt).then((res) => {
      if (res.data.status === "ok") {
        online_servicecharge_per =
          res.data.online_service_charge !== ""
            ? parseFloat(res.data.online_service_charge)
            : 0;
        online_servicecharge_amount = 0;

        if (res.data.subcharge_apply === "Yes") {
          var subchr_data = res.data.result_set[0];
          if (res.data.subcharge_apply === "Yes") {
            subchr_amount =
              subchr_data.subchr_value !== ""
                ? parseFloat(subchr_data.subchr_value)
                : 0;
            subchr_type = subchr_data.subchr_type;
            if (subchr_data.subchr_type === "Time") {
              subchr_frmtime = this.tmConv24(subchr_data.subchr_from_time);
              subchr_totime = this.tmConv24(subchr_data.subchr_to_time);
            } else {
              subchr_date = subchr_data.subchr_date;
            }
          }
        }
      }

      servicesurchargeArr["servicechrg_displaylabel"] =
        res.data.onlineservice_displaylbl;
      servicesurchargeArr["servicechrg_type"] = res.data.onlineservice_type;
      servicesurchargeArr["servicechrg_per"] = res.data.online_service_charge;
      servicesurchargeArr["surcharge_amount"] = subchr_amount;
      servicesurchargeArr["surcharge_type"] = subchr_type;
      servicesurchargeArr["surcharge_date"] = subchr_date;
      servicesurchargeArr["surcharge_frmtime"] = subchr_frmtime;
      servicesurchargeArr["surcharge_totime"] = subchr_totime;

      this.setState({ servicesurchargeval: servicesurchargeArr });
    });
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  tmConv24(time24) {
    var ts = time24;
    if (ts !== "") {
      var H = +ts.substr(0, 2);
      var h = H % 12 || 12;
      h = h < 10 ? "0" + h : h;
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
    }
    return ts;
  }

  getCommonSetting(pstCode) {
    var pstCode = pstCode === "" ? this.state.catering_postal_code : "";
    var postCdVal = pstCode != "" ? "&postal_code=" + pstCode : "";
    axios
      .get(apiUrl + "settings/get_common_settings?app_id=" + appId + postCdVal)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set !== "undefined") {
            this.setState({ settings: res.data.result_set });
          }
        }
      });
  }

  loadCartList() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + cookie.load("referenceId");
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }

    axios
      .get(apiUrlV2 + "cateringcart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          var cartDetailsMn = res.data.result_set.cart_details;
          var cartItemsLst = res.data.result_set.cart_items;
        } else {
          var cartDetailsMn = [];
          var cartItemsLst = [];
        }

        this.setState({
          cartDetailsData: cartDetailsMn,
          cartItemsList: cartItemsLst,
        });
      });
  }

  loadRewardPoints() {
    var customerParam = "&status=A&customer_id=" + cookie.load("UserId");
    var actArr = [];
    actArr.push("promotionwithoutuqc");
    actArr.push("reward");
    var actArrParam = "&act_arr=" + JSON.stringify(actArr);

    axios
      .get(
        apiUrl +
          "reports/activity_counts1?app_id=" +
          appId +
          actArrParam +
          customerParam
      )
      .then((res) => {
        if (res.data.status === "ok") {
          var promoCount = res.data.result_set.promotionwithoutuqc;
          var rwdpointCnt = res.data.result_set.reward_ponits;
        } else {
          var promoCount = 0;
          var rwdpointCnt = 0;
        }

        this.setState({
          promotion_count: promoCount,
          reward_point_count: rwdpointCnt,
        });
      });
  }

  satePackChange = (field, value) => {
    if (field === "editpackage_deta") {
      var cartDataDetail = value;
      var CrdPkgData = cartDataDetail["cartLst"];
      var CrdMainData = cartDataDetail["cartMain"];
      if (Object.keys(CrdPkgData).length > 0) {
        var editePackageid = CrdPkgData.cart_item_product_id;
        var editFlag = CrdPkgData.cart_item_breaktime_indexflag;
        this.setState(
          {
            edite_packageid: editePackageid,
            edit_flag: editFlag,
            cart_packagedata: CrdPkgData,
            cart_maindata: CrdMainData,
          },
          function() {
            this.openPackagePopup();
          }.bind(this)
        );
      }
    }
  };

  openPackagePopup() {
    $("#PackageEditMdl").modal({ backdrop: "static", keyboard: false });
  }

  handlerTestFun() {}

  /* Validate floor && Unit No. */
  validatezip(e) {
    const re = /[0-9A-F:]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* Validate Float Value */
  validateFloatval(e) {
    const re = /[0-9.]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* Validate Int Value */
  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* post code */
  changePostalCode(isFrm, event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;
    var outletIdVl = cookie.load("cateringOutletId");
    if (isFrm === "cust") {
      $(".postal_code_cls").removeClass("err_alt");
      $(".cust_address_cls").removeClass("err_alt");
    }

    var isSame = this.state.billing_addrs_sameas;
    if (isFrm === "billing" || isSame === "yes") {
      $(".billing_postal_code_cls").removeClass("err_alt");
      $(".billing_address_cls").removeClass("err_alt");
    }

    if (postalCode !== "" && parseInt(postCdLth) === 6 && outletIdVl !== "") {
      /* zone based */
      var urlShringTxt =
        apiUrlV2 +
        "outlets/findOutletZone?app_id=" +
        appId +
        "&outlet_id=" +
        outletIdVl +
        "&availability_id=" +
        cateringId +
        "&postal_code=" +
        postalCode;

      /* with out zone */
      /*var urlShringTxt = apiUrl + 'outlets/find_outlet?app_id=' + appId +'&availability_id='+ cateringId+'&postal_code='+postalCode;*/

      axios.get(urlShringTxt).then((res) => {
        var custAddress = "";
        var zoneIdTxt = "";
        if (res.data.status === "ok") {
          var outltResulSet = res.data.result_set;
          if (Object.keys(outltResulSet.postal_code_information).length > 0) {
            zoneIdTxt = outltResulSet.zone_id;

            var outletAddress =
              outltResulSet.postal_code_information.zip_buno != ""
                ? outltResulSet.postal_code_information.zip_buno +
                  ", " +
                  outltResulSet.postal_code_information.zip_sname
                : outltResulSet.postal_code_information.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
          }
        }

        if (isFrm === "cust") {
          this.getCommonSetting(postalCode);
          this.setState(
            {
              catering_postal_code: postalCode,
              catering_address_line: custAddress,
              zone_id: zoneIdTxt,
            },
            function() {
              this.setBillingAddrs();
            }.bind(this)
          );
        } else if (isFrm === "billing") {
          this.setState({
            billing_postal_code: postalCode,
            billing_address_line: custAddress,
            zone_id: zoneIdTxt,
          });
        }
      });
    } else {
      var temparrs = "";
      if (isFrm === "cust") {
        $(".cust_address_cls").addClass("err_alt");
        this.setState(
          {
            catering_postal_code: postalCode,
            catering_address_line: temparrs,
            zone_id: temparrs,
          },
          function() {
            this.setBillingAddrs();
          }.bind(this)
        );
      } else if (isFrm === "billing") {
        this.setState({
          billing_postal_code: postalCode,
          billing_address_line: temparrs,
          zone_id: temparrs,
        });
      }

      if (isFrm === "billing" || isSame === "yes") {
        $(".billing_address_cls").addClass("err_alt");
      }
    }
  }

  CheckBillingAddrChk(type) {
    var isSame = this.state.billing_addrs_sameas;
    var chkBox = false;
    var diplayTxt = "block";
    if (isSame === "yes") {
      chkBox = true;
      diplayTxt = "none";
    }

    return type === "checkbox" ? chkBox : diplayTxt;
  }

  changeBillingAddrChk() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var tempvlu = "";
      this.setState({
        billing_postal_code: tempvlu,
        billing_address_line: tempvlu,
        billing_floor_no: tempvlu,
        billing_unit_no: tempvlu,
        billing_addrs_sameas: "no",
      });
    } else {
      this.setState(
        { billing_addrs_sameas: "yes" },
        function() {
          this.setBillingAddrs();
        }.bind(this)
      );
    }
  }

  setBillingAddrs() {
    var isSame = this.state.billing_addrs_sameas;
    if (isSame === "yes") {
      var postalCode = this.state.catering_postal_code;
      var custAddress = this.state.catering_address_line;
      var floorNo = this.state.catering_floor_no;
      var unitNo = this.state.catering_unit_no;

      this.setState({
        billing_postal_code: postalCode,
        billing_address_line: custAddress,
        billing_floor_no: floorNo,
        billing_unit_no: unitNo,
      });
    }
    $(".cust_floor_no").removeClass("err_alt");
    $(".billing_floor_no").removeClass("err_alt");
  }

  handleAddrChange(event) {
    if (event.target.name === "address_line2") {
      this.setState({ catering_address_line2: event.target.value });
    } else if (event.target.name === "floor_no") {
      this.setState(
        { catering_floor_no: event.target.value },
        function() {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "unit_no") {
      this.setState(
        { catering_unit_no: event.target.value },
        function() {
          this.setBillingAddrs();
        }.bind(this)
      );
    } else if (event.target.name === "specil_note") {
      this.setState({ catering_specil_note: event.target.value });
    } else if (event.target.name === "reward_point") {
      this.setState({ reward_point_val: event.target.value });
    } else if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    } else if (event.target.name === "bill_floor_no") {
      this.setState({ billing_floor_no: event.target.value });
      $(".billing_floor_no").removeClass("err_alt");
    } else if (event.target.name === "bill_unit_no") {
      this.setState({ billing_unit_no: event.target.value });
      $(".billing_floor_no").removeClass("err_alt");
    }
  }

  setPropActiveTab(tagvalue) {
    var tabflag = this.props.categoryState.active_tabflag;
    if (parseInt(tabflag) !== parseInt(tagvalue)) {
      this.props.setActiveTab(parseInt(tagvalue));
    } else {
      this.props.sateValChangefun("active_tabflag", 0);
    }
  }

  setDateTimeFun() {
    cookie.save("dateTimeTrigger", "yes");
    this.props.setActiveTab(2);
  }

  swtCartFun(stpflg, stpTxt) {
    $.magnificPopup.open({
      items: {
        src: "#swt_popup_id",
      },
      type: "inline",
    });
  }

  yesProcdFun() {
    this.cartDestoryFun();
    this.props.setActiveTab(2);
  }

  cartDestoryFun() {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert("success", "Nice! Your cart is empty");
          $.magnificPopup.close();
          var cartDetails = Array();
          showCartItemCount(cartDetails);
          this.props.history.push("/catering");
        } else {
          $.magnificPopup.close();
          showCustomAlert("error", "Sorry! Products can`t update your cart");
        }
      })
      .catch(function(error) {
        console.log(error);
        showCustomAlert("error", "something went wrong");
        $.magnificPopup.close();
      });
  }

  applyPointsAndPromo(type) {
    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;
    var reedemPointVal = this.state.reward_point_val;
    var promoCodeVal = this.state.promo_code_val;
    var usedPoints = this.state.used_reward_point;

    /*var cartDetailsArr = (typeof cookie.load('cartDetail') !== 'undefined' && cookie.load('cartDetail') !== '') ? cookie.load('cartDetail') : Array();
		var cartItemsSateArr = (typeof cookie.load('cartItemsList') !== 'undefined' && cookie.load('cartItemsList') !== '') ? cookie.load('cartItemsList') : Array();*/

    var cartDetailsArr = this.state.cartDetailsData;
    var cartItemsSateArr = this.state.cartItemsList;

    var subTotal =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_sub_total
        : 0;
    var totalItems =
      Object.keys(cartDetailsArr).length > 0
        ? cartDetailsArr.cart_total_items
        : 0;

    if (type === "points") {
      showLoader("applypoints_cls", "class");

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        redeem_point: reedemPointVal,
        cart_amount: subTotal,
      };

      axios
        .post(apiUrl + "loyalty/apply_loyalityv1", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            promotionApplied = "Yes";
            promotionType = "points";
            promotionAmount = pointDet.points_amount;
            promotionSource = reedemPointVal;
            promoIsDelivery = "";
            promoCodeVal = "";
            usedPoints = reedemPointVal;

            showCustomAlert(
              "success",
              "Point has been redeemed successfully!."
            );
            this.handleShowAlertFun(
              "success",
              "Point has been redeemed successfully!."
            );
          } else {
            if (promotionType === "points") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
              usedPoints = 0;
            }
          }

          hideLoader("applypoints_cls", "class");

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
          });
        });
    } else if (type === "promoCode") {
      showLoader("applypromo_cls", "class");

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        promo_code: promoCodeVal,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: cateringId,
      };

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            reedemPointVal = "";
            usedPoints = 0;

            showCustomAlert(
              "success",
              "Promotion has been applied successfully!."
            );
            this.handleShowAlertFun(
              "success",
              "Promotion has been applied successfully!."
            );
          } else {
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promotionSource = "";
              promoIsDelivery = "";
            }
            var msgTxt = res.data.message
              ? res.data.message
              : "Sorry! Did not applied promo code";
            showCustomAlert("error", msgTxt);
          }

          hideLoader("applypromo_cls", "class");

          cookie.save("reedemPointVal", reedemPointVal);
          cookie.save("promoCodeVal", promoCodeVal);
          cookie.save("promotionApplied", promotionApplied);
          cookie.save("promotionType", promotionType);
          cookie.save("promotionAmount", promotionAmount);
          cookie.save("promotionSource", promotionSource);
          cookie.save("promoIsDelivery", promoIsDelivery);
          cookie.save("usedPoints", usedPoints);

          this.setState({
            reward_point_val: reedemPointVal,
            promo_code_val: promoCodeVal,
            promotion_applied: promotionApplied,
            promotion_type: promotionType,
            promotion_amount: promotionAmount,
            promotion_source: promotionSource,
            promoIs_delivery: promoIsDelivery,
            used_reward_point: usedPoints,
          });
        });
    }
  }

  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  removePointsAndPromo() {
    removePromoCkValue();

    this.setState({
      reward_point_val: "",
      promo_code_val: "",
      promotion_applied: "",
      promotion_type: "",
      promotion_amount: "",
      promotion_source: "",
      promoIs_delivery: "",
      used_reward_point: 0,
    });
  }

  chkoutSateValChange = (field, value, value2) => {
    if (field === "cartLstData") {
      this.setState({ cartDetailsData: value, cartItemsList: value2 });
    } else if (field === "removePromo") {
      this.removePointsAndPromo();
    }
  };

  /* show online payment mode loading */
  orderBCLoading() {
    return (
      <div className="process_col">
        <div className="process_left">
          <img src={this.state.placingorderimage} />
        </div>
        <div className="process_right">
          <h5>Processing</h5>
          <p>Placing your order now.</p>
        </div>
      </div>
    );
  }
  /**/

  orderSubmition() {
    var errorFlg = "No";
    showLoader("checkout-continue-cls", "class");

    var eventType = cookie.load("catering_eventType");

    var postalCode = this.state.catering_postal_code;
    var addressLine = this.state.catering_address_line;
    var BillPostalCode = this.state.billing_postal_code;
    var BillAddressLine = this.state.billing_address_line;

    var unitnumber1 = this.state.catering_floor_no;
    var unitnumber2 = this.state.catering_unit_no;
    var BillingUnitNo1 = this.state.billing_floor_no;
    var BillingUnitNo2 = this.state.billing_unit_no;

    if (eventType !== "hall" && postalCode === "") {
      errorFlg = "Yes";
      $(".postal_code_cls").addClass("err_alt");
      window.scrollTo(0, 450);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && addressLine === "") {
      errorFlg = "Yes";
      $(".cust_address_cls").addClass("err_alt");
      window.scrollTo(0, 300);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && unitnumber1 === "" && unitnumber2 === "") {
      errorFlg = "Yes";
      $(".cust_floor_no").addClass("err_alt");
      window.scrollTo(0, 450);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && BillPostalCode === "") {
      errorFlg = "Yes";
      $(".billing_postal_code_cls").addClass("err_alt");
      window.scrollTo(0, 650);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (eventType !== "hall" && BillAddressLine === "") {
      errorFlg = "Yes";
      $(".billing_address_cls").addClass("err_alt");
      window.scrollTo(0, 500);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (
      eventType !== "hall" &&
      BillingUnitNo1 === "" &&
      BillingUnitNo1 === ""
    ) {
      errorFlg = "Yes";
      $(".billing_floor_no").addClass("err_alt");
      window.scrollTo(0, 650);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (
      this.state.catering_tarms_contn !== "Yes" &&
      this.state.termsAndConditions !== ""
    ) {
      errorFlg = "Yes";
      $(".tarms_chkbox_div").addClass("err_tarms_chk");
      window.scrollTo(0, 1000);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (this.state.catering_tarms_contnew !== "Yes") {
      errorFlg = "Yes";
      $(".tarms_chkbox_divnew").addClass("err_tarms_chk");
      window.scrollTo(0, 1000);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    var cateringDate =
      typeof cookie.load("cateringDate") !== "undefined"
        ? cookie.load("cateringDate")
        : "";
    var cateringTime =
      typeof cookie.load("cateringTime") !== "undefined"
        ? cookie.load("cateringDate")
        : "";

    if (cateringDate === "") {
      errorFlg = "Yes";
      $(".catering_date_cls").addClass("err_alt");
      window.scrollTo(0, 600);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    if (cateringTime === "") {
      errorFlg = "Yes";
      $(".catering_time_cls").addClass("err_alt");
      window.scrollTo(0, 600);
      hideLoader("checkout-continue-cls", "class");
      return false;
    }

    var userId =
      typeof cookie.load("UserId") !== "undefined" ? cookie.load("UserId") : "";
    if (userId === "") {
      errorFlg = "Yes";
      showCustomAlert(
        "error",
        "Sorry! User account has not valid, please login properly."
      );
      return false;
    }

    if (errorFlg === "No") {
      axios
        .get(
          apiUrlV2 +
            "cateringcart/contents?app_id=" +
            appId +
            "&customer_id=" +
            userId
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var cartDetailsMn = res.data.result_set.cart_details;
            var cartItemsLst = res.data.result_set.cart_items;
          } else {
            var cartDetailsMn = [];
            var cartItemsLst = [];
          }

          this.setState(
            { finalCartDetails: cartDetailsMn, finalcartItems: cartItemsLst },
            function() {
              this.orderToPlace();
            }.bind(this)
          );
        });
    }
  }

  orderToPlace() {
    var finalCartDetails = this.state.finalCartDetails;
    var finalcartItems = this.state.finalcartItems;

    if (
      Object.keys(finalCartDetails).length > 0 &&
      Object.keys(finalcartItems).length > 0
    ) {
      $.magnificPopup.open({
        items: {
          src: ".processing",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        mainClass: "mfp-fade",
        closeOnBgClick: false,
      });

      this.postOrder();
    } else {
      showCustomAlert("error", "Sorry! Your cart is empty.");
      hideLoader("checkout-continue-cls", "class");
    }
  }

  postOrder() {
    var validationOnly = "No";
    var finalcartItems = this.state.finalcartItems;

    var products = [];

    var row = this.state.finalcartItems.map(
      function(item, i) {
        var modifierdata = [];
        var addons = [];
        var addonsSetup = [];
        var equipments = [];
        var setupdata = [];

        var modifierLen = item.modifiers.length;
        if (modifierLen > 0) {
          for (var l = 0; l < modifierLen; l++) {
            var modifierVal = [];

            var modValuesArr = item.modifiers[l].modifiers_values;
            if (modValuesArr.length > 0) {
              for (var key in modValuesArr) {
                modifierVal.push({
                  modifier_value_id: modValuesArr[key].cart_modifier_id,
                  modifier_value_qty: modValuesArr[key].cart_modifier_qty,
                  modifier_value_name: stripslashes(
                    modValuesArr[key].cart_modifier_name
                  ),
                  modifier_value_price: modValuesArr[key].cart_modifier_price,
                });
              }
            }

            modifierdata.push({
              modifier_id: item.modifiers[l].cart_modifier_id,
              modifier_name: stripslashes(item.modifiers[l].cart_modifier_name),
              modifiers_values: modifierVal,
            });
          }
        }

        var addonsSetupLen = item.addons_setup.length;
        if (addonsSetupLen > 0) {
          for (var l = 0; l < addonsSetupLen; l++) {
            var tempArr = [];
            var addonsValueArr = item.addons_setup[l].addons_setup_values;
            for (var key in addonsValueArr) {
              tempArr.push({
                addon_setup_val_id: addonsValueArr[key].cart_addon_setup_val_id,
                addon_setup_val_title:
                  addonsValueArr[key].cart_addon_setup_val_title,
                addon_setup_val_desc:
                  addonsValueArr[key].cart_addon_setup_val_desc,
                addon_setup_val_price:
                  addonsValueArr[key].cart_addon_setup_val_price,
                addon_setup_val_qty:
                  addonsValueArr[key].cart_addon_setup_val_qty,
              });
            }

            addonsSetup.push({
              addon_setup_id: item.addons_setup[l].cart_addon_setup_id,
              addon_setup_title: item.addons_setup[l].cart_addon_setup_title,
              addon_setup_selection:
                item.addons_setup[l].cart_addon_setup_selection,
              addon_setup_amount: item.addons_setup[l].cat_addon_setup_amount,
              addons_setup_values: tempArr,
            });
          }
        }

        var setupLen = item.setup.length;
        if (setupLen > 0) {
          for (var l = 0; l < setupLen; l++) {
            setupdata.push({
              setup_p_id: item.setup[l].cart_setup_primary_key,
              setup_id: item.setup[l].cart_setup_id,
              setup_name: stripslashes(item.setup[l].cart_setup_name),
              setup_type: item.setup[l].cart_setup_type,
              setup_description: item.setup[l].cart_setup_description,
              setup_qty: item.setup[l].cart_setup_qty,
              setup_price: item.setup[l].cart_setup_price,
              setup_total_price: item.setup[l].cart_setup_tatalprice,
            });
          }
        }

        products.push({
          product_id: item.cart_item_product_id,
          product_name: stripslashes(item.cart_item_product_name),
          product_image: item.cart_item_product_image,
          product_specification: item.cart_item_special_notes,
          product_sku: stripslashes(item.cart_item_product_sku),
          product_qty: item.cart_item_qty,
          product_unit_price: item.cart_item_unit_price,
          product_total_amount: item.cart_item_total_price,
          modifiers: modifierdata,
          addons: addons,
          addons_setup: addonsSetup,
          equipments: equipments,
          setup: setupdata,
          breaktime_indexflag: item.cart_item_breaktime_indexflag,
          breaktime_started: item.cart_item_breaktime_started,
          breaktime_ended: item.cart_item_breaktime_ended,
        });

        return products;
      }.bind(this)
    );

    var eventType = cookie.load("catering_eventType");

    /* insert customner details */
    if (validationOnly !== "Yes") {
      var addressObject = {};
      var customerAddressId = 0;
      addressObject = {
        app_id: appId,
        customer_first_name: cookie.load("UserFname"),
        customer_last_name: cookie.load("UserLname"),
        customer_phone: cookie.load("UserMobile"),
        customer_email: cookie.load("UserEmail"),
        customer_address_line1: this.state.catering_address_line,
        customer_address_line2: this.state.catering_address_line2,
        customer_postal_code: this.state.catering_postal_code,
        customer_address_name: this.state.catering_floor_no,
        customer_address_name2: this.state.catering_unit_no,
        customer_status: "A",
        customer_order_status: "order",
        refrence: cookie.load("UserId"),
      };
      if (eventType !== "hall") {
        axios
          .post(
            apiUrl + "customer/secondary_address_add",
            qs.stringify(addressObject)
          )
          .then((res) => {
            if (res.data.status === "ok") {
              customerAddressId = res.data.insert_id;
            }
          });
      }
    }

    var cateringDateTxt = cookie.load("cateringDate");
    var cateringTimeTxt = cookie.load("cateringTime");
    var orderDate = cateringDateTxt + " " + cateringTimeTxt;

    var orderAddress = this.state.catering_address_line;
    var orderAddress2 = this.state.catering_address_line2;
    var orderPostalCode = this.state.catering_postal_code;
    var unitnumber1 = this.state.catering_floor_no;
    var unitnumber2 = this.state.catering_unit_no;

    var BillingAddress = this.state.billing_address_line;
    var BillingPostalCode = this.state.billing_postal_code;
    var BillingUnitNo1 = this.state.billing_floor_no;
    var BillingUnitNo2 = this.state.billing_unit_no;

    var postHall = [];
    if (eventType === "hall") {
      orderAddress = cookie.load("cateringHalladdressLine");
      orderPostalCode = cookie.load("cateringHallPostalcode");
      orderAddress2 = "";
      unitnumber1 = "";
      unitnumber2 = "";
      BillingAddress = "";
      BillingPostalCode = "";
      BillingUnitNo1 = "";
      BillingUnitNo2 = "";
      postHall.push({
        catering_venue_type: "catering_hall",
        catering_hall_id: cookie.load("cateringHallId"),
        hall_charges: cookie.load("cateringHallCharge"),
      });
    }

    var finalCartDetails = this.state.finalCartDetails;
    var cartSubTotal = finalCartDetails.cart_sub_total;
    var cartGrandTotal = finalCartDetails.cart_grand_total;

    var promotionApplied = this.state.promotion_applied;
    var promotionType = this.state.promotion_type;
    var promotionAmount = this.state.promotion_amount;
    var promotionSource = this.state.promotion_source;
    var promoIsDelivery = this.state.promoIs_delivery;

    promotionAmount = promotionAmount != "" ? promotionAmount : 0;

    if (promotionApplied === "Yes") {
      cartGrandTotal =
        parseFloat(cartGrandTotal) > parseFloat(promotionAmount)
          ? parseFloat(cartGrandTotal) - parseFloat(promotionAmount)
          : 0;
    }

    var comSettings = this.state.settings;
    var gstPercentage = 0;
    var gstAmount = 0;
    var deliveryCharge = 0;
    if (Object.keys(comSettings).length > 0) {
      deliveryCharge =
        comSettings.client_catering_delivery_charge !== ""
          ? parseFloat(comSettings.client_catering_delivery_charge)
          : 0;
      gstPercentage =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;

      var gstAmount = getGstValue(gstPercentage, cartGrandTotal, "format");
      var gstAmountTxt = getGstValue(gstPercentage, cartGrandTotal, "");

      cartGrandTotal = parseFloat(cartGrandTotal) + parseFloat(gstAmountTxt);
    }

    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    var servicesurchargeval = this.state.servicesurchargeval;

    var CalculatedCateringAmount = getCalculatedCateringAmount(
      comSettings,
      finalCartDetails,
      promoTionArr,
      servicesurchargeval
    );

    var postObject = {};
    postObject = {
      /* cart details */
      app_id: appId,
      availability_id: cateringId,
      outlet_id: cookie.load("cateringOutletId"),
      zone_id: this.state.zone_id,
      sub_total: CalculatedCateringAmount["cartSubTotalAmount"],
      grand_total: CalculatedCateringAmount["grandTotalAmount"],

      /* Sur & Service Chareges */
      order_subcharge_amount: parseFloat(
        CalculatedCateringAmount["surCharge"]
      ).toFixed(2),
      order_service_charge: CalculatedCateringAmount["servicechargePer"],
      order_service_charge_amount: parseFloat(
        CalculatedCateringAmount["serviceCharge"]
      ).toFixed(2),
      order_servicecharge_displaylabel:
        CalculatedCateringAmount["servicechrgDisplaylbl"],

      order_source: "Web",
      order_status: 1,
      payment_mode: "1",
      payment_type: "CASH",
      validation_only: validationOnly,

      order_date: orderDate,
      order_remarks: this.state.catering_specil_note,
      products: JSON.stringify(products),

      cateringhall: JSON.stringify(postHall),

      tax_charge: CalculatedCateringAmount["orderDisplayGst"],
      order_tax_calculate_amount: CalculatedCateringAmount["orderGstAmount"],
      delivery_charge: deliveryCharge,

      order_tat_time: "",
      order_is_advanced: "No",
      order_advanced_date: "",
      pickup_time: orderDate,
      pickup_outlet_id: "",
      call_center_admin_id: "",

      /* customer  Details */
      customer_id: cookie.load("UserId"),
      customer_address_id: customerAddressId,
      customer_fname: cookie.load("UserFname"),
      customer_lname: cookie.load("UserLname"),
      customer_mobile_no: cookie.load("UserMobile"),
      customer_email: cookie.load("UserEmail"),
      customer_address_line1: orderAddress,
      customer_address_line2: orderAddress2,
      customer_postal_code: orderPostalCode,
      customer_unit_no1: unitnumber1,
      customer_unit_no2: unitnumber2,

      customer_city: "",
      customer_state: "",
      customer_country: "",

      breaktime_enable: finalCartDetails.cart_breaktime_enable,
      breaktime_count: finalCartDetails.cart_breaktime_count,
      breaktime_list: finalCartDetails.cart_breaktime_list,

      /* discount */
      redeem_applied: "No",
      voucher_applied: "No",
      coupon_applied: "No",
      discount_applied: "No",
      discount_amount: "No",
    };

    if (promotionApplied === "Yes" && parseFloat(promotionAmount) > 0) {
      postObject["discount_applied"] = "Yes";
      postObject["discount_amount"] = promotionAmount;

      if (promotionType === "promoCode") {
        var categoryIdsDet = this.getProductIdsDet(finalcartItems);

        postObject["coupon_applied"] = "Yes";
        postObject["promo_code"] = promotionSource;
        postObject["cart_quantity"] = finalCartDetails.cart_total_items;
        postObject["category_id"] = categoryIdsDet;
        postObject["coupon_amount"] = promotionAmount;
        postObject["promotion_delivery_charge_applied"] = promoIsDelivery;
      }

      if (promotionType === "points") {
        postObject["redeem_applied"] = "Yes";
        postObject["redeem_point"] = promotionSource;
        postObject["redeem_amount"] = promotionAmount;
      }
    }

    postObject["reward_point_status"] = "Yes";

    axios
      .post(apiUrlV2 + "Cateringorders/submit_order", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          if (validationOnly !== "Yes") {
            showCustomAlert("success", "Nice! Your order placed successfully.");
            var localOrderNo = res.data.common.local_order_no;
            this.setState({ placingorderimage: tickImage });
            this.showSuccessPage(localOrderNo);
          } else {
            hideLoader("checkout-continue-cls", "class");
          }
        } else {
          showCustomAlert(
            "error",
            "Sorry! Your order can not placed, Something is wrong."
          );
          if (res.data.form_error !== undefined) {
            showAlert("Error", res.data.form_error);
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          hideLoader("checkout-continue-cls", "class");
        }
      });
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.destroyCart();
    this.deleteOrderCookie();
    const { history } = this.props;
    setTimeout(function() {
      $.magnificPopup.close();
      history.push("/catering/thankyou/" + localOrderNo);
    }, 1000);
  }

  /* this function used to delte all  cart items */
  destroyCart() {
    var postObject = {};
    postObject = { app_id: appId };
    postObject["customer_id"] = cookie.load("UserId");
    axios.post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postObject));
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("catering_categoryPryId");
    cookie.remove("catering_categoryId");
    cookie.remove("catering_categoryName");

    cookie.remove("venueTypeInitail");
    cookie.remove("catering_eventType");
    cookie.remove("cateringOutletId");
    cookie.remove("cateringOutletName");
    cookie.remove("cateringSelectedOutlet");
    cookie.remove("cateringOutletPostalcode");
    cookie.remove("cateringOutletaddressLine");
    cookie.remove("cateringOutletAddress");

    cookie.remove("cateringHallId");
    cookie.remove("cateringHallName");
    cookie.remove("cateringHallPostalcode");
    cookie.remove("cateringHalladdressLine");
    cookie.remove("cateringHalladdress");
    cookie.remove("cateringHallPax");
    cookie.remove("cateringHallCharge");

    cookie.remove("cateringDate");
    cookie.remove("cateringTime");
    cookie.remove("breaktimeEnable");
    cookie.remove("breaktimeCount");
    cookie.remove("breaktimeList");

    cookie.remove("cartDetail");
    cookie.remove("cartTotalItems");
    cookie.remove("fromCkeckOutVld");
    cookie.remove("applyCatrngPromo");
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  downloadCartItem() {
    showLoader("download-quote", "class");
    var customerId = cookie.load("UserId");
    var customerEmail = cookie.load("UserEmail");
    var promotionAmount = this.state.promotion_amount;
    var comSettings = this.state.settings;
    var gstPar = 0;
    if (Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
    }
    var outletIdVl = cookie.load("cateringOutletId");

    var servicesurchargeval = this.state.servicesurchargeval;
    var finalCartDetails = this.props.cateringCartDetails;
    var promoTionArr = Array();
    promoTionArr["promotionApplied"] = this.state.promotion_applied;
    promoTionArr["promotionAmount"] = this.state.promotion_amount;
    var CalculatedCateringAmount = getCalculatedCateringAmount(
      comSettings,
      finalCartDetails,
      promoTionArr,
      servicesurchargeval
    );
    var urlParam =
      "&customer_id=" +
      customerId +
      "&request_email=" +
      customerEmail +
      "&client_gst_charge=" +
      gstPar +
      "&promotion=" +
      promotionAmount +
      "&outlet_id=" +
      outletIdVl +
      "&servicechrgDisplaylbl=" +
      CalculatedCateringAmount["servicechrgDisplaylbl"] +
      "&servicechrg_type=" +
      this.state.servicesurchargeval["servicechrg_type"] +
      "&servicechargePer=" +
      CalculatedCateringAmount["servicechargePer"] +
      "&serviceCharge=" +
      CalculatedCateringAmount["serviceCharge"];

    axios
      .get(
        apiUrlV2 + "cateringcart/downloadQtnItems?app_id=" + appId + urlParam
      )
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert(
            "success",
            "Your Quotation request has been sent to your registered email please check!."
          );
          this.handleShowAlertFun(
            "success",
            "Your Quotation request has been sent to your registered email please check!."
          );
        } else {
          showCustomAlert("error", "Sorry! Your Quotation request was failed.");
          this.handleShowAlertFun(
            "error",
            "Sorry! Your Quotation request was failed."
          );
        }
        hideLoader("download-quote", "class");
      });
  }

  CheckTarmsContnChk() {
    var tarmsContn = this.state.catering_tarms_contn;
    var chkBox = false;
    if (tarmsContn === "Yes") {
      chkBox = true;
    }
    return chkBox;
  }

  changeTarmsContnChk() {
    var tarmsContn = this.state.catering_tarms_contn;
    if (tarmsContn === "Yes") {
      this.setState({ catering_tarms_contn: "No" });
    } else {
      this.setState({ catering_tarms_contn: "Yes" });
    }
    $(".tarms_chkbox_div").removeClass("err_tarms_chk");
  }

  CheckTarmsContnChknew() {
    var tarmsContn = this.state.catering_tarms_contnew;
    var chkBox = false;
    if (tarmsContn === "Yes") {
      chkBox = true;
    }
    return chkBox;
  }
  changeTarmsContnChknew() {
    var tarmsContn = this.state.catering_tarms_contnew;
    if (tarmsContn === "Yes") {
      this.setState({ catering_tarms_contnew: "No" });
    } else {
      this.setState({ catering_tarms_contnew: "Yes" });
    }
    $(".tarms_chkbox_divnew").removeClass("err_tarms_chk");
  }

  /* ViewProducts */

  render() {
    var eventTypeTxt =
      typeof cookie.load("catering_eventType") !== "undefined"
        ? cookie.load("catering_eventType")
        : "";
    var showLocationName = cookie.load("cateringHallName");
    var showLocationAddr = cookie.load("cateringHalladdress");

    var cateringDateTxt = cookie.load("cateringDate");
    var cateringTimeTxt = cookie.load("cateringTime");

    var showRdmPoints =
      parseFloat(this.state.reward_point_count) -
      parseFloat(this.state.used_reward_point);
    showRdmPoints = showRdmPoints.toFixed(2);

    return (
      <div>
        {/*<h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.props.showsActiveHdr(4)} onClick={this.setPropActiveTab.bind(this,4)}>CHECKOUT<i></i></h4>*/}
        <div
          className="catering_checout catering_checout_maindiv tab_mobrow"
          style={{ display: this.props.showsActiveDiv(4) }}
        >
          <form
            id="checkoutpage_form"
            className=" form_sec checkout-total"
            method="post"
            acceptCharset="utf-8"
          >
            <div className="cdd-details">
              {/* cdd-details left start */}
              <div className="cdd-details-lhs fl">
                <div className="text-center checkout-heading">
                  <span className="text-uppercase">Your Location & Time</span>
                </div>

                {eventTypeTxt === "venue" && (
                  <div className="checkout-control-group-top">
                    <label className="chk_hea">Event Location</label>
                    <div className="form-group">
                      <div className="focus-out controls-single">
                        <input
                          type="text"
                          className="cust_address_cls form-control input-focus"
                          placeholder=""
                          value={this.state.catering_address_line}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-group controls-three">
                      <div className="focus-out">
                        <label>Postal Code</label>
                        <input
                          type="text"
                          className="form-control input-focus postal_code_cls"
                          value={this.state.catering_postal_code}
                          onChange={this.changePostalCode.bind(this, "cust")}
                          onKeyPress={(e) => this.validateIntval(e)}
                        />
                      </div>

                      <div className="focus-out">
                        <label>Unit No 01</label>
                        <input
                          type="text"
                          name="floor_no"
                          className="form-control input-focus cust_floor_no"
                          value={this.state.catering_floor_no}
                          onKeyPress={(e) => this.validatezip(e)}
                          onChange={this.handleAddrChange.bind(this)}
                        />
                      </div>

                      <div className="focus-out">
                        <label>Unit No 02</label>
                        <input
                          type="text"
                          name="unit_no"
                          value={this.state.catering_unit_no}
                          className="form-control input-focus"
                          onKeyPress={(e) => this.validatezip(e)}
                          onChange={this.handleAddrChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {eventTypeTxt === "venue" && (
                  <div className="checkout-control-group-top">
                    <label className="chk_hea">
                      Billing Address{" "}
                      <span>
                        Same As Delivery Address{" "}
                        <div className="custom_checkbox">
                          <input
                            type="checkbox"
                            name="bill_chk"
                            className="addon_list_single"
                            onChange={this.changeBillingAddrChk.bind(this)}
                            checked={this.CheckBillingAddrChk("checkbox")}
                          />
                          <span></span>{" "}
                        </div>
                      </span>
                    </label>
                    <div
                      className="check-billing"
                      style={{ display: this.CheckBillingAddrChk("display") }}
                    >
                      <div className="form-group">
                        <div className="focus-out controls-single">
                          <input
                            type="text"
                            className="form-control input-focus billing_address_cls"
                            placeholder=""
                            readOnly
                            value={this.state.billing_address_line}
                          />
                        </div>
                      </div>
                      <div className="form-group controls-three">
                        <div className="focus-out">
                          <label>Postal Code</label>
                          <input
                            type="text"
                            name="billing_postcode"
                            value={this.state.billing_postal_code}
                            onChange={this.changePostalCode.bind(
                              this,
                              "billing"
                            )}
                            onKeyPress={(e) => this.validateIntval(e)}
                            className="form-control input-focus billing_postal_code_cls"
                          />
                        </div>

                        <div className="focus-out">
                          <label className="unit-num">Unit No 01</label>
                          <input
                            type="text"
                            name="bill_floor_no"
                            value={this.state.billing_floor_no}
                            onKeyPress={(e) => this.validatezip(e)}
                            onChange={this.handleAddrChange.bind(this)}
                            className="form-control input-focus billing_floor_no"
                          />
                        </div>

                        <div className="focus-out">
                          <label className="unit-num">Unit No 02</label>
                          <input
                            type="text"
                            name="bill_unit_no"
                            value={this.state.billing_unit_no}
                            onKeyPress={(e) => this.validatezip(e)}
                            onChange={this.handleAddrChange.bind(this)}
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {eventTypeTxt !== "venue" && (
                  <div className="checkout-control-group-top">
                    <label className="chk_hea">Event Location</label>
                    <div>
                      <span className="making-place display_block">
                        {showLocationName}
                      </span>
                      <span>{showLocationAddr}</span>
                    </div>
                    <a
                      href="javascript:void(0);"
                      onClick={this.swtCartFun.bind(this)}
                      className=""
                    >
                      Change Location
                    </a>
                  </div>
                )}

                <div className="checkout-control-group-middle">
                  <label className="chk_hea">Event Date & Time</label>

                  <div className="row-replace">
                    <div className="col-md-cls col-xs-cls">
                      <div className="form-group">
                        <div className="input_area">
                          <input
                            type="text"
                            name="catering_date"
                            className="form-control datetimepicker filled catering_date_cls"
                            value={dateConvFun(cateringDateTxt, 2)}
                            id="datetimepicker3"
                            placeholder="30 - 10- 2018"
                            readOnly
                          />
                          <span className="cal_icon">
                            <b></b>
                          </span>
                        </div>

                        <div className="input_area">
                          <input
                            type="text"
                            name="catering_time"
                            className="form-control datetimepicker filled catering_time_cls"
                            value={timeToConv12(cateringTimeTxt)}
                            id="datetimepicker4"
                            placeholder="12:00 PM"
                            readOnly
                          />
                          <span className="cal_icon">
                            <b></b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <a href="javascript:void(0);" onClick={this.setPropActiveTab.bind(this,2)} className="change-edt">
										Change Event Date & Time
										</a> */}
                  <a
                    href="javascript:void(0);"
                    onClick={this.setDateTimeFun.bind(this)}
                    className="change-edt"
                  >
                    Change Event Date & Time
                  </a>
                </div>

                <div className="checkout-control-group-bottom">
                  <label className="chk_hea">Special Instruction</label>
                  <textarea
                    className=""
                    name="specil_note"
                    value={this.state.catering_specil_note}
                    onChange={this.handleAddrChange.bind(this)}
                    placeholder="Please enter your special message here..."
                    id="orderRemarks"
                  ></textarea>

                  {this.state.termsAndConditions !== "" && (
                    <div>
                      <div className="checkout-terms-and-condition">
                        {this.state.termsAndConditions}
                      </div>
                      <div className="custom_checkbox tarms_chkbox_div">
                        <input
                          type="checkbox"
                          name="tarms_chk"
                          onClick={this.changeTarmsContnChk.bind(this)}
                          defaultChecked={this.CheckTarmsContnChk()}
                        />
                        <span>I have read and accept terms and conditions</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="cate_note">
                  Note: Deposit of 50% of total bill required to confirm event
                </div>

                <div
                  className="white-popup mfp-hide popup_sec"
                  id="swt_popup_id"
                  style={{ maxWidth: 500 }}
                >
                  <div className="custom_alert">
                    <div className="custom_alertin">
                      <div className="alert_height">
                        <div
                          className="alert_header"
                          style={{ textAlign: "center" }}
                        >
                          Wait a second !
                        </div>
                        <div className="alert_body">
                          <img src={CantDlyImg} />
                          <p>By switching you are about to clear your cart.</p>
                          <p>Do you wish to proceed ?</p>
                          <div className="alt_btns">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-sm btn_yellow popup-modal-dismiss"
                              style={{ background: "#e0dddd" }}
                            >
                              Cancel
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="btn btn-sm btn_yellow"
                              onClick={this.yesProcdFun.bind(this)}
                            >
                              Yes Proceed
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* cdd-details left end */}

              {/* cart details Start */}
              <div className="cdd-details-rhs fl">
                <Cartdetails
                  chekoutState={this.state}
                  satePackChangefun={this.satePackChange}
                  prpActiveTab={this.props.setActiveTab}
                  chkoutSateVal={this.chkoutSateValChange}
                />
              </div>
              {/* cart details End */}
            </div>
          </form>

          {/* redeem points & promo Start */}
          <div className="redeem">
            <div className="redeem-row">
              {/*<div className="redeem-col">
									<div className="redeem-item">
									<div className="redeem-item-hea">
										<div className="redeem-item-hea-inner">
											<h4>Get Redeem</h4>
											<span>Redeem your points here</span>
										</div>
										{(parseFloat(this.state.reward_point_count)>0)?<Link to={"/rewards"} className="points">You have <b>{showRdmPoints} points</b> available</Link>:''}
									</div>
									<div className="redeem_apply focus-out applypoints_cls">
										<div className="focus-out">
											{(parseFloat(this.state.reward_point_count)>0)?<label>You Can Redeem {showRdmPoints} Points</label>:<label>No Points Available</label>}
											<input type="input" name="reward_point" value={this.state.reward_point_val} className="form-control input-focus" onKeyPress={(e) => this.validateFloatval(e)} onChange={this.handleAddrChange.bind(this)} />
										</div>
										{(this.state.promotion_applied === 'Yes' && this.state.promotion_type === 'points')?<button className="btn btn_black btn_minwid" onClick={this.removePointsAndPromo.bind(this)} >Remove</button>:<button className="btn btn_black btn_minwid" onClick={this.applyPointsAndPromo.bind(this,'points')} >Apply Now</button>}
									</div>
									</div>
							</div>*/}
              <div className="redeem-col redeem-col-full redeem-col-right">
                <div className="redeem-item">
                  <div className="redeem-item-hea">
                    <div className="redeem-item-hea-inner">
                      <h4>Promo Code</h4>
                      <span>Apply your promo code here</span>
                    </div>
                    {parseFloat(this.state.promotion_count) > 0 ? (
                      <Link to={"/mypromotions"} className="points">
                        You have <b>{this.state.promotion_count} Promotions</b>{" "}
                        available
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="redeem_apply focus-out applypromo_cls">
                    <div
                      className={
                        this.state.promo_code_val != ""
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <label>Add your promo code here</label>
                      <input
                        type="input"
                        name="promo_code"
                        value={this.state.promo_code_val}
                        className="form-control input-focus"
                        onChange={this.handleAddrChange.bind(this)}
                      />
                    </div>
                    {this.state.promotion_applied === "Yes" &&
                    this.state.promotion_type === "promoCode" ? (
                      <button
                        className="btn btn_black btn_minwid"
                        onClick={this.removePointsAndPromo.bind(this)}
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        className="btn btn_black btn_minwid"
                        onClick={this.applyPointsAndPromo.bind(
                          this,
                          "promoCode"
                        )}
                      >
                        Apply Now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* redeem points & promo End */}

          <div>
            <div className="custom_checkbox tarms_chkbox_divnew">
              <input
                type="checkbox"
                name="tarms_chk"
                onClick={this.changeTarmsContnChknew.bind(this)}
                defaultChecked={this.CheckTarmsContnChknew()}
              />
              <span>I agree </span>
            </div>
            <a
              target="_blank"
              className="sign_reg_uline"
              href="/terms-and-conditions"
            >
              {" "}
              Terms of Service.
            </a>
          </div>

          <div className="chk-payment-btn">
            <div className="chk-payment-btn-row checkout-continue-cls">
              <div className="row-replace">
                <div className="col-md-cls go_second download-quote">
                  <a
                    href="javascript:void(0)"
                    onClick={this.downloadCartItem.bind(this)}
                    className="button"
                    title="Next"
                  >
                    Download Quotation
                  </a>
                </div>
                <div className="col-md-cls con_first">
                  <a
                    href="javascript:void(0)"
                    onClick={this.orderSubmition.bind(this)}
                    className="button"
                    title="Next"
                  >
                    Place Order
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">We Are Processing Your Order</h3>
            <div className="process_inner">{this.orderBCLoading()}</div>
          </div>
        </div>

        <Packageupdate
          chekoutState={this.state}
          prpActiveTab={this.props.setActiveTab}
          chkoutSateVal={this.chkoutSateValChange}
        />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllCateringCart = Array();
  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllCateringCart = resultSetArr;
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    overAllCateringCart: overAllCateringCart,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Checkout));
