/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_MENU_NAVIGATION,
  SET_MENU_NAVIGATION,
  GET_PRODUCT_LIST,
  SET_PRODUCT,
} from "../actions";
import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  defaultOutletID,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMenuNavigation = function*() {
  yield takeEvery(GET_MENU_NAVIGATION, workerGetMenuNavigation);
};

export const watchGetProducts = function*() {
  yield takeEvery(GET_PRODUCT_LIST, workerGetProducts);
};

function* workerGetMenuNavigation({
  menu_category_id,
  menu_slug,
  menu_name,
  menu_type,
}) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? pickupId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? defaultOutletID
        : cookie.load("orderOutletId");
    const uri =
      apiUrl +
      "products/navigation_menu_categorybased?app_id=" +
      appId +
      "&status=A&availability=" +
      availabilityId +
      "&outletId=" +
      orderOutletId;
    const result = yield call(Axios.get, uri);

    var resultArr = [];
    resultArr.push(result.data);
    if (result.data.status == "ok") {
      console.log(menu_type, "menu_typemenu_type");
      if (
        menu_type !== "" &&
        typeof menu_type !== undefined &&
        typeof menu_type !== "undefined" &&
        menu_type == "category"
      ) {
        if (result.data.result_set.length > 0) {
          var suburinew = "";
          result.data.result_set.map((item) => {
            console.log(
              menu_category_id,
              item.pro_cate_slug,
              "item.pro_cate_slug"
            );
            if (item.pro_cate_slug === menu_category_id) {
              console.log(item, "itemitem");
              menu_slug = item.pro_cate_slug;
              cookie.save("firstNavigation", menu_slug);
              menu_category_id = item.menu_category_id;
              menu_name = item.menu_custom_title;
              resultArr.push({
                menu_slug: menu_slug,
                menu_cat_id: menu_category_id,
                menu_name: menu_name,
              });
              suburinew =
                apiUrl +
                "products/subcategory?app_id=" +
                appId +
                "&status=A&availability=" +
                cookie.load("defaultAvilablityId") +
                "&outlet=" +
                orderOutletId +
                "&category_id=" +
                menu_category_id;
            }
          });
        }
        if (suburinew !== "") {
          const subresult = yield call(Axios.get, suburinew);
          resultArr.push(subresult.data);
        }
      } else {
        if (menu_slug === null || menu_slug === "" || menu_slug === undefined) {
          menu_slug = result.data.result_set[0].pro_cate_slug;
        }

        if (
          menu_category_id === null ||
          menu_category_id === "" ||
          menu_category_id === undefined
        ) {
          menu_category_id = result.data.result_set[0].menu_category_id;
        }

        if (menu_name === null || menu_name === "" || menu_name === undefined) {
          menu_name = result.data.result_set[0]
            ? result.data.result_set[0].menu_custom_title
            : "";
        }

        resultArr.push({
          menu_slug: menu_slug,
          menu_cat_id: menu_category_id,
          menu_name: menu_name,
        });

        const suburi =
          apiUrl +
          "products/subcategory?app_id=" +
          appId +
          "&status=A&availability=" +
          cookie.load("defaultAvilablityId") +
          "&outlet=" +
          orderOutletId +
          "&category_id=" +
          menu_category_id;

        const subresult = yield call(Axios.get, suburi);
        resultArr.push(subresult.data);
      }
    }

    yield put({ type: SET_MENU_NAVIGATION, value: resultArr });
  } catch (err) {
    console.log(err, "Get Products Failed-menu-list");
  }
}

function* workerGetProducts({ catslug, subcatslug, outletid }) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? ""
        : cookie.load("orderOutletId");

    var deliveryDate = "";
    var deliveryDateTxt =
      cookie.load("deliveryDate") !== undefined
        ? cookie.load("deliveryDate")
        : "";
    if (deliveryDateTxt != "") {
      var deliveryDateArr = deliveryDateTxt.split("/");
      deliveryDate =
        deliveryDateArr[2] +
        "-" +
        deliveryDateArr[1] +
        "-" +
        deliveryDateArr[0];
    }

    const uri =
      apiUrl +
      "products/search_products_withoutmodifier?app_id=" +
      appId +
      "&status=A&availability=" +
      availabilityId +
      "&category_id=" +
      catslug +
      "&selected_outlet_id=" +
      orderOutletId +
      "&specialprice_applicable=orderdate&order_datetxt=" +
      deliveryDate;

    /*const uri = apiUrl+'products/search_products_withoutmodifier?app_id='+appId+'&status=A&availability='+availabilityId+'&subcat_slug='+subcatslug+'&selected_outlet_id='+orderOutletId;*/

    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT, value: resultArr });
  } catch (err) {
    console.log(err.message);
  }
}
