/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Scrollbars } from "react-custom-scrollbars";

import { GET_ACTIVITYCOUNT } from "../../actions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.currentpage,      
      status: "OK",
      order_all: 0,
      overall_orders: 0,
      ordercount: 0,
      member_benifits_info: "",
    };
  }

  componentDidMount() {
    this.getActivityCountsNew();

    setTimeout(function() {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 400);
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
          });
        }
      }
    } else {
      this.setState({ order_all: 0, overall_orders: 0 });
    }
  }

  getActivityCountsNew() {
    const inputKeys = ["order_all", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  render() {
    var initialSlide = 0;
    if (this.state.activePage === "myaccount") {
      initialSlide = 0;
    } else if (this.state.activePage === "myorders") {
      initialSlide = 1;
    } else if (this.state.activePage === "rewards") {
      initialSlide = 2;
    } else if (this.state.activePage === "mypromotions") {
      initialSlide = 3;
    } else if (this.state.activePage === "myvouchers") {
      initialSlide = 4;
    }

    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: initialSlide,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };
    return (
      <div className="mainacc_menuout ">
        <ul className="mainacc_menulist">
          <li
            className={this.props.currentpage === "myaccount" ? "active" : ""}
          >
            <Link to="/myaccount" title="My Account">
              <span>Account Details</span>
            </Link>
          </li>
          <li className={this.props.currentpage === "myorders" ? "active" : ""}>
            <Link to="/myorders" title="My Orders">
              <span>Orders</span>
              {parseFloat(this.state.overall_orders) > 0 && (
                <span id="masterCount">{this.state.overall_orders}</span>
              )}
            </Link>
          </li>
          <li className={this.props.currentpage === "rewards" ? "active" : ""}>
            <Link to="/rewards" title="My Rewards">
              <span>Rewards</span>
            </Link>
          </li>
          <li
            className={
              this.props.currentpage === "mypromotions" ? "active" : ""
            }
          >
            <Link to="/mypromotions" title="Promo Vouchers">
              <span>Promo Vouchers</span>
            </Link>
          </li>
          <li
            className={this.props.currentpage === "myvouchers" ? "active" : ""}
          >
            <Link to="/myvouchers" title="Gift Vouchers">
              <span>Gift Vouchers </span>
            </Link>
          </li>
          <li>
            <a href="#change-password-popup" className="open-popup-link">
              <i className="setting_menu_list_icon password_icon"></i>
              Change Password
            </a>
          </li>
          {this.state.member_benifits_info && (
            <li>
              <a href="javascript:void(0)">
                <i className="sprite_setting gift setting_icon"></i>
                View Member Benefits
              </a>
              <ul className="myacc_dropmenu">
                <li>
                  <div className="drop_div_nfc">
                    <div className="list_nfcn">
                      <Scrollbars style={{ width: 500, height: 300 }}>
                        {this.state.member_benifits_info}
                        <div class="more_inform">
                          <p>
                            For more information on our terms and conditions,
                            please{" "}
                            <a href="/terms-conditions" target="_blank">
                              click here
                            </a>{" "}
                            section.
                          </p>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          )}
          <li>
            <a href={void 0}>
              <i className="setting_menu_list_icon setting_icon"></i>
              Settings
            </a>
            <ul className="myacc_dropmenu">
              <li>
                <a href={void 0}>Push Notification</a>
                <ul className="tg-list">
                  <li className="tg-list-item">
                    <input
                      className="tgl tgl-light cb1"
                      data-id="push"
                      value="1"
                      defaultChecked
                      id="cb1"
                      type="checkbox"
                    />
                    <label
                      className="tgl-btn notification_settings"
                      data-section="web"
                      data-id="push"
                      htmlFor="cb1"
                    ></label>
                  </li>
                </ul>
              </li>

              <li>
                <a href={void 0}>Email Notification</a>
                <ul className="tg-list">
                  <li className="tg-list-item">
                    <input
                      className="tgl tgl-light cb2"
                      data-id="email"
                      value="1"
                      defaultChecked
                      id="cb2"
                      type="checkbox"
                    />
                    <label
                      className="tgl-btn notification_settings"
                      data-section="web"
                      data-id="email"
                      htmlFor="cb2"
                    ></label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <a href="/logout">
              <i className="setting_menu_list_icon logout_icon"></i>
              Logout
            </a>
          </li>
        </ul>
        <div className="mbacc_mslidersec mbacc_mslider">
          <Slider {...settingsMyAcc}>
            <div className={this.props.currentpage === "myaccount" ? "mbacc_mslide active" : "mbacc_mslide"}>
              <Link to="/myaccount" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div className={this.props.currentpage === "myorders" ? "mbacc_mslide active" : "mbacc_mslide"}>
              <Link to="/myorders" title="My Orders">
                <span>Orders</span>
              </Link>
            </div>
            <div className={this.props.currentpage === "rewards" ? "mbacc_mslide active" : "mbacc_mslide"}>
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div className={this.props.currentpage === "mypromotions" ? "mbacc_mslide active" : "mbacc_mslide"}>
              <Link to="/mypromotions" title="Promo Vouchers">
                <span>Promo Vouchers</span>
              </Link>
            </div>
            <div className={this.props.currentpage === "myvouchers" ? "mbacc_mslide active" : "mbacc_mslide"}>
              <Link to="/myvouchers" title="Gift Vouchers">
                <span>Gift Vouchers</span>
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
