/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  deliveryId,
  cateringId,
  pickupId,
  apiUrlV2,
  CountryTxt,
  productNoImg,
  baseUrl,
  timThumpUrl,
} from "../../Helpers/Config";
import {
  getReferenceID,
  showLoader,
  hideLoader,
  callImage,
  showAlert,
  timeToConv12,
  dateConvFun,
  showPriceValue,
  showCartItemCount,
  showCustomAlert,
  showCustomCenterAlert,
  smoothScroll,
  stripslashes,
  jsUcfirstFun,
} from "../../Helpers/SettingHelper";
import cookie from "react-cookies";
import axios from "axios";

import Parser from "html-react-parser";
/*import Select from 'react-select';*/

import Cartpopup from "./Cartpopup";
import CantDlyImg from "../../../common/images/Cant_Deliver.png";
import ClockImg from "../../../common/images/time.png";
import PkgImg from "../../../common/images/pkg_item1.jpg";
import PkgDetImg from "../../../common/images/pkglist_item1.jpg";

import {
  GET_CATERING_PRODCUTLIST,
  GET_CATERING_PRODCUTDETAIL,
  GET_OWNVENUE_CATEGORY,
  GET_HALL_CATEGORY,
} from "../../../actions";

var qs = require("qs");

class Packagedetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      staticblacks: [],
      productDisclaimer: "",
      productsdetail: [],
      productfeilsdetail: [],
      product_addons: [],
      product_setup: [],
      product_modifiers: [],
      product_modifiers_price: [],
      breaktime_count: 0,
      breaktime_enable: "",
      breaktime_list: [],
      pro_subcate_name: "",
      product_list: [],
      selected_pakageIds: [],
      proListDropDown: "",
      product_calculated_amount: [],
      selected_category_name: "",
      category_id: "",
      cart_flag: "no",
      cartData: [],
    };

    if (typeof cookie.load("breaktimeEnable") !== "undefined") {
      this.state["breaktime_enable"] = cookie.load("breaktimeEnable");
    }
    if (typeof cookie.load("breaktimeList") !== "undefined") {
      this.state["breaktime_list"] = cookie.load("breaktimeList");
    }
    if (typeof cookie.load("catering_categoryName") !== "undefined") {
      this.state["selected_category_name"] = cookie.load(
        "catering_categoryName"
      );
    }
  }

  componentWillReceiveProps(categoryProps) {
    if (this.state.category_id !== categoryProps.categoryState.category_id) {
      this.setState(
        {
          category_id: categoryProps.categoryState.category_id,
          selected_category_name: categoryProps.categoryState.category_name,
        },
        function() {
          this.resetAllpackData();
        }.bind(this)
      );
    }

    if (categoryProps.staticblack !== this.state.staticblacks) {
      var proDisclaimer = "";
      if (Object.keys(categoryProps.staticblack).length > 0) {
        categoryProps.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "product-disclaimer") {
            proDisclaimer = data.staticblocks_description;
            return "";
          }
        });
      }
      proDisclaimer =
        proDisclaimer !== "" ? Parser(proDisclaimer) : proDisclaimer;
      this.setState({
        staticblacks: categoryProps.staticblack,
        productDisclaimer: proDisclaimer,
      });
    }

    if (
      this.state.category_id !== categoryProps.categoryState.category_id ||
      this.state.catering_outlet_id !==
        categoryProps.categoryState.catering_outlet_id ||
      this.state.catering_hall_id !==
        categoryProps.categoryState.catering_hall_id
    ) {
      var eventType =
        typeof cookie.load("catering_eventType") !== "undefined"
          ? cookie.load("catering_eventType")
          : "";

      var categoryId = categoryProps.categoryState.category_id;
      var outletId = categoryProps.categoryState.catering_outlet_id;
      var HallId = categoryProps.categoryState.catering_hall_id;

      /*var categoryId = (typeof cookie.load('catering_categoryId') !== 'undefined') ? cookie.load('catering_categoryId') : '';
		var outletId = (typeof cookie.load('cateringOutletId') !== 'undefined') ? cookie.load('cateringOutletId') : '';
		var HallId = (typeof cookie.load('cateringHallId') !== 'undefined') ? cookie.load('cateringHallId') : '';*/

      if (
        categoryId !== "" &&
        eventType !== "" &&
        (outletId !== "" || HallId !== "")
      ) {
        $(".dvLoaderCls").show();
        var proFlgArr = Array();
        proFlgArr["category_id"] = categoryId;
        proFlgArr["catering_type"] = eventType;
        proFlgArr["catering_outlet_id"] = outletId;
        proFlgArr["catering_hall_id"] = HallId;
        this.props.getCateringProductList(proFlgArr);
        this.props.getCateringHallsn(proFlgArr);
        this.props.getCateringVenues(proFlgArr);
      } else {
        var productslist = [];
        var image_source = "";
        this.setState(
          { product_list: productslist, product_image_source: image_source },
          function() {
            this.productListBox();
          }.bind(this)
        );
      }
    }

    if (Object.keys(categoryProps).length > 0) {
      var categoryStateArr = categoryProps.categoryState;
      for (var key in categoryStateArr) {
        this.state[key] = categoryStateArr[key];
      }
    }

    if (this.state.product_list !== categoryProps.catrgprodcutlistArr) {
      $(".dvLoaderCls").fadeOut(500);
      this.setState(
        {
          product_list: categoryProps.catrgprodcutlistArr,
          product_image_source: categoryProps.catrgprodcutimageurl,
        },
        function() {
          this.productListBox();
        }.bind(this)
      );
    }
  }

  componentDidMount() {}

  getProductDetailById(indFlg, productId) {
    var kyIndx = "Index_" + indFlg;
    var sateProductsdetail = this.state.productsdetail;
    var sateProductModifr = this.state.product_modifiers;
    var sateProductModifrPrice = this.state.product_modifiers_price;
    var sateProductAddons = this.state.product_addons;
    var sateProductSetup = this.state.product_setup;
    var sateProductFeilsDet = this.state.productfeilsdetail;
    $(".dvLoaderCls").show();

    axios
      .get(
        apiUrlV2 +
          "catering/cateringproducts_detail?app_id=" +
          appId +
          "&product_id=" +
          productId +
          "&availability=" +
          cateringId +
          "&status=A&"
      )
      .then((res) => {
        var proDet = Array();
        if (res.data.status === "success") {
          proDet["resultset"] = res.data.result_set;
          proDet["commonurl"] = res.data.common;
        }
        sateProductsdetail[kyIndx] = proDet;
        sateProductModifr[kyIndx] = Array();
        sateProductModifrPrice[kyIndx] = Array();
        sateProductAddons[kyIndx] = Array();
        sateProductSetup[kyIndx] = Array();
        sateProductFeilsDet[kyIndx] = Array();
        this.setState(
          {
            productsdetail: sateProductsdetail,
            productfeilsdetail: sateProductFeilsDet,
            product_modifiers: sateProductModifr,
            product_modifiers_price: sateProductModifrPrice,
            product_addons: sateProductAddons,
            product_setup: sateProductSetup,
          },
          function() {
            this.setDefaultMdfrChk(indFlg);
          }.bind(this)
        );
        $(".dvLoaderCls").fadeOut(500);
      });
  }

  setDefaultMdfrChk(indFlg) {
    var productModfr = this.state.product_modifiers;
    var productModfrPrice = this.state.product_modifiers_price;
    var indKey = "Index_" + indFlg;
    var productsDetailArr = Array();
    var stateProDetArr = this.state.productsdetail;
    var overallMdfPrice = 0;
    if (Object.keys(stateProDetArr).length > 0) {
      var temArr = !(indKey in stateProDetArr)
        ? Array()
        : stateProDetArr[indKey];
      if (Object.keys(temArr).length > 0) {
        productsDetailArr =
          Object.keys(temArr["resultset"]).length > 0
            ? temArr["resultset"][0]
            : Array();
      }
    }

    if (Object.keys(productsDetailArr).length > 0) {
      if (Object.keys(productsDetailArr.modifiers).length > 0) {
        var proModifiers = productsDetailArr.modifiers;
        for (var mfkey in proModifiers) {
          var tempArr = Array();
          var subKey = "modifr_" + mfkey;
          var modifiersData = proModifiers[mfkey];
          var mdfName = modifiersData.pro_modifier_alias_name
            ? modifiersData.pro_modifier_alias_name
            : modifiersData.pro_modifier_name;
          var modfrMn =
            modifiersData.pro_modifier_id + "~~" + stripslashes(mdfName);

          var modifiersValData = proModifiers[mfkey].modifiers_values;
          if (Object.keys(modifiersValData).length > 0) {
            for (var mfVlkey in modifiersValData) {
              var ModfrValue = modifiersValData[mfVlkey];
              if (ModfrValue.pro_modifier_value_is_default === "Yes") {
                tempArr["mdfval_" + mfVlkey] =
                  ModfrValue.pro_modifier_value_id +
                  "~~" +
                  stripslashes(ModfrValue.pro_modifier_value_name) +
                  "~~1~~" +
                  ModfrValue.pro_modifier_value_price;
                overallMdfPrice += parseFloat(
                  ModfrValue.pro_modifier_value_price
                );
              }
            }
          }

          if (Object.keys(tempArr).length > 0) {
            var MdfArr = Array();
            MdfArr["modifiers"] = modfrMn;
            MdfArr["modifiervalues"] = tempArr;
            productModfr[indKey][subKey] = MdfArr;
          } else {
            productModfr[indKey][subKey] = Array();
          }
        }
      }
    }

    productModfrPrice[indKey] = overallMdfPrice;

    this.setState(
      {
        product_modifiers: productModfr,
        product_modifiers_price: productModfrPrice,
      },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  getProductTag_Old = (tags) => {
    if (tags !== null && tags !== "") {
      tags = tags.split(",");
      return (
        <div className="related-tags">
          <ul>
            {tags.map((tagvalue, index) => (
              <li key={index}>{tagvalue}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  getProductTag = (tags) => {
    if (tags !== null && tags !== "") {
      tags = tags.split(",");
      return (
        <div className="product-tags-list">
          <ul>
            {tags.map((tagvalue, index) => (
              <li key={index}>{tagvalue}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  productListOnlyHtml_Old(indFlg) {
    var productList = this.state.product_list;
    var imageSource = this.state.product_image_source;
    if (Object.keys(productList).length > 0) {
      var productsArr = [];
      var incr = 0;
      for (var key in productList) {
        /*var proSubcateName = productList[key].pro_subcate_name;*/
        var productsTemp = productList[key].products;
        for (var keyinr in productsTemp) {
          productsArr[incr] = productsTemp[keyinr];
          incr++;
        }
      }

      const proListHtml = productsArr.map((prodts, index) => (
        <div className="pkg-intuil-list pkg_gcol" key={index}>
          <div className="pkg_gitem">
            <div className="pkg_gimg">
              {callImage(
                imageSource,
                prodts.product_thumbnail,
                436,
                330,
                timThumpUrl
              )}

              <div className="pkg_ginfo">
                <div className="package-overlay-header">
                  <h3>{stripslashes(prodts.product_name)}</h3>
                  {parseFloat(prodts.product_min_pax) > 0 && (
                    <span>MIN PAX : {prodts.product_min_pax}</span>
                  )}
                  <p>{prodts.product_short_description} </p>
                </div>
                <div className="package-overlay-body">
                  ${prodts.product_price} <sup>(Per Box)</sup>
                </div>

                {this.getProductTag(prodts.pro_tags)}
              </div>
            </div>
            <div className="pkg_gbtsec text-center">
              <a
                href="javascript:void(0)"
                className="choose-single-btn"
                onClick={this.choosePackage.bind(
                  this,
                  indFlg,
                  prodts.product_id
                )}
                title="Choose Package"
              >
                Choose
              </a>
            </div>
          </div>
        </div>
      ));
      return proListHtml;
    } else {
      return (
        <div className="no-package-found">
          No packages found in the selected category and outlet.
        </div>
      );
    }
  }

  productListOnlyHtml(indFlg) {
    var productList = this.state.product_list;
    var imageSource = this.state.product_image_source;
    if (Object.keys(productList).length > 0) {
      var productsArr = [];
      var incr = 0;
      for (var key in productList) {
        /*var proSubcateName = productList[key].pro_subcate_name;*/
        var productsTemp = productList[key].products;
        for (var keyinr in productsTemp) {
          productsArr[incr] = productsTemp[keyinr];
          incr++;
        }
      }

      const proListHtml = productsArr.map((prodts, index) => {
        var description =
          prodts.product_short_description !== null &&
          prodts.product_short_description !== ""
            ? Parser(stripslashes(prodts.product_short_description))
            : "";

        return (
          <div className="innerproduct-col" key={index}>
            <div className="innerproduct-item">
              <div className="innerproduct-item-image">
                {callImage(
                  imageSource,
                  prodts.product_thumbnail,
                  436,
                  330,
                  timThumpUrl
                )}
              </div>
              <div className="innerproduct-button">
                <a
                  className="btn btn_pink btn_minwid smiple_product_lk"
                  href="javascript:void(0);"
                  onClick={this.choosePackage.bind(
                    this,
                    indFlg,
                    prodts.product_id
                  )}
                  title="Choose Package"
                >
                  Choose
                </a>
              </div>
              {this.getProductTag(prodts.pro_tags)}
            </div>
            <div className="innerproduct-item-text">
              <h4>
                {prodts.product_alias !== ""
                  ? stripslashes(prodts.product_alias)
                  : prodts.product_name}
              </h4>
              {description !== "" ? <p>{stripslashes(description)}</p> : ""}
              {prodts.product_disable_cart === "No" && (
                <div
                  className={
                    description !== ""
                      ? "minpax-proprice"
                      : "minpax-proprice dotted-line-top"
                  }
                >
                  {parseFloat(prodts.product_min_pax) > 0 && (
                    <span className="min_pax_spn">
                      MIN PAX : {prodts.product_min_pax}
                    </span>
                  )}
                  {/*(parseFloat(prodts.product_price)>0) && <span className="price_nor">${prodts.product_price}<sup>(Per Pax)</sup></span>*/}
                  {parseFloat(prodts.product_price) > 0 && (
                    <span className="price_nor">
                      ${prodts.product_price} <dl>(Per Pax)</dl>
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      });

      return proListHtml;
    } else {
      return (
        <div className="no-package-found">
          No packages found in the selected category and outlet.
        </div>
      );
    }
  }

  choosePackage(indFlg, pkgId) {
    var selectedPakageIds = this.state.selected_pakageIds;
    var kyIndx = "Index-" + indFlg;
    selectedPakageIds[kyIndx] = pkgId;
    this.setState({ selected_pakageIds: selectedPakageIds });
    smoothScroll(500, 470);
    this.getProductDetailById(indFlg, pkgId);
  }

  changePackage(indFlg, event) {
    var pkgId = event.target.value;
    var selectedPakageIds = this.state.selected_pakageIds;
    var kyIndx = "Index-" + indFlg;
    selectedPakageIds[kyIndx] = pkgId;
    $("#proDetMain-" + indFlg)
      .find(".mdf_common_error")
      .hide();
    $("#proDetMain-" + indFlg)
      .find(".pkgaddon_checklist")
      .find(".pkgerror_txt")
      .hide();
    $("#proDetMain-" + indFlg)
      .find(".addon_poptop")
      .find("small")
      .removeClass("apply-red");
    this.setState({ selected_pakageIds: selectedPakageIds });

    this.getProductDetailById(indFlg, pkgId);
  }

  resetPackage(indFlg) {
    var selectedPakageIds = this.state.selected_pakageIds;
    var kyIndx = "Index-" + indFlg;
    selectedPakageIds[kyIndx] = "";
    this.setState({ selected_pakageIds: selectedPakageIds });
  }

  resetAllpackData() {
    var selectedPakageIds = this.state.selected_pakageIds;
    if (Object.keys(selectedPakageIds).length > 0) {
      for (var key in selectedPakageIds) {
        selectedPakageIds[key] = "";
      }
      this.setState({ selected_pakageIds: selectedPakageIds });
    }
  }

  handleSpecialInstruction(indFlg, event) {
    var stateProFldDet = this.state.productfeilsdetail;
    var proFieldArr = Array();
    var indKey = "Index_" + indFlg;
    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet)
        ? Array()
        : stateProFldDet[indKey];
    }
    var remarks = event.target.value;
    proFieldArr["productSpecialNotes"] = remarks;
    stateProFldDet[indKey] = proFieldArr;
    this.setState({ productfeilsdetail: stateProFldDet });
  }

  getTopBarDetails() {
    var eventType = cookie.load("catering_eventType");
    var showLocationName = "";
    var showLocationAddr = "";
    if (eventType === "hall") {
      showLocationName = cookie.load("cateringHallName");
      showLocationAddr = cookie.load("cateringHalladdress");
    } else {
      showLocationName = cookie.load("cateringOutletName");
      showLocationAddr = cookie.load("cateringOutletAddress");
    }

    const breakTmCount = this.state.breaktime_count;
    return (
      <div className="number-break break-time-removed">
        <a
          className="back_bx"
          href="javascript:void(0)"
          onClick={this.goBackFun.bind(this, 1, 2)}
        >
          Back
        </a>

        {this.state.breaktime_enable === "Yes" &&
        Object.keys(this.state.breaktime_list).length > 0 ? (
          <div className="break-time" style={{ display: "none" }}>
            <h3 className="title4">Number of Break Time</h3>
            <div className="qty_bx catqty_bx">
              <span
                className="qty_minus"
                onClick={this.goBackFun.bind(this, 2, 2)}
              >
                -
              </span>
              <input type="text" value={breakTmCount} readOnly />
              <span
                className="qty_plus"
                onClick={this.goBackFun.bind(this, 2, 2)}
              >
                +
              </span>
            </div>
          </div>
        ) : (
          <div className="break-time" style={{ display: "none" }}>
            <h3 className="title4">Break Time</h3>
            <div className="">
              {" "}
              <p> You didn't have any break time.</p>
              <span className="change-location display_block">
                <a
                  href="javascript:void(0)"
                  onClick={this.goBackFun.bind(this, 4, 2)}
                >
                  Add Break Time
                </a>
              </span>
              {/*<a href="javascript:void(0)" onClick={this.goBackFun.bind(this,3,2)} className="btn btn-sm btn_yellow" style={{padding: '7px 12px'}} title="Add New">Add New</a>*/}
            </div>
          </div>
        )}

        <div className="making-catering">
          <h3 className="title4">
            {eventType === "hall" ? "Catering" : "Catering"}
          </h3>
          <p>
            {eventType === "hall"
              ? "You are having your catering order at"
              : "You are making a catering order from"}
            <span className="making-place making-place-padding">
              {showLocationName}
            </span>{" "}
            <span className="display_block">{showLocationAddr}</span>{" "}
            <span className="change-location display_block">
              <a
                href="javascript:void(0)"
                onClick={this.goBackFun.bind(this, 4, 2)}
              >
                Change Location
              </a>
            </span>
          </p>
        </div>
        <div className="event-time">
          <h3 className="title4">Event date &amp; Time</h3>
          <h3 className="event-datetime-string">
            {dateConvFun(cookie.load("cateringDate"), 2)}{" "}
            {timeToConv12(cookie.load("cateringTime"))}
          </h3>
          <span className="change-location display_block">
            <a
              href="javascript:void(0)"
              onClick={this.goBackFun.bind(this, 4, 2)}
            >
              Change Event date &amp; Time
            </a>
          </span>
        </div>

        <div className="white-popup mfp-hide popup_sec" id="gotoback_popup_id">
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Wait a second !</div>
                <div className="alert_body">
                  <img src={CantDlyImg} />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="javascript:void(0);"
                      className="button popup-modal-dismiss"
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0);"
                      className="button"
                      onClick={this.yesProcd.bind(this)}
                    >
                      Yes Proceed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  goBackFun(stpflg, stpTxt) {
    var totalItems = cookie.load("cartTotalItems");
    if (parseInt(totalItems) > 0) {
      $.magnificPopup.open({
        items: {
          src: "#gotoback_popup_id",
        },
        type: "inline",
      });
    } else {
      this.props.setActiveTab(stpTxt);
    }
  }

  yesProcd() {
    this.cartDestory();
    this.props.setActiveTab(2);
  }

  cartDestory() {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          showCustomAlert("success", "Nice! Your cart is empty");
          $.magnificPopup.close();
          var cartDetails = Array();
          showCartItemCount(cartDetails);
          /*browserHistory.push("/#/");*/
        } else {
          $.magnificPopup.close();
          showCustomAlert("error", "Sorry! Products can`t update your cart");
        }
      })
      .catch(function(error) {
        console.log(error);
        showCustomAlert("error", "something went wrong");
        $.magnificPopup.close();
      });
  }

  getBreakTime_BarHtml() {
    var breakTimeEnable = this.state.breaktime_enable;
    var breakTimeList = this.state.breaktime_list;
    var breakTimeActive = 0;

    if (breakTimeEnable === "Yes" && Object.keys(breakTimeList).length > 0) {
      const BarHtml = breakTimeList.map((brtimeLst, index) => (
        <li
          className={
            index === breakTimeActive ? "brk_time_li active" : "brk_time_li"
          }
          id={"Brk-" + index}
          key={index}
          onClick={this.handleTabBtn.bind(this, index)}
        >
          <a href="javascript:void(0)">
            {" "}
            Break {index + 1} - {timeToConv12(brtimeLst)}
          </a>
        </li>
      ));
      return BarHtml;
    } else {
      var BarHtl = "";
      return BarHtl;
    }
  }

  handleTabBtn(indexTxt) {
    $(".brk_time_li").removeClass("active");
    $("#Brk-" + indexTxt).addClass("active");
    $(".tab-pane-sub").removeClass("active");
    $("#tab-pks-" + indexTxt).addClass("active");
    $(".tab-pane-sub").hide();
    $("#tab-pks-" + indexTxt).show();
  }

  getProductListHtml() {
    var breakTimeEnable = this.state.breaktime_enable;
    var breakTimeList = this.state.breaktime_list;
    var breakTimeActive = 0;
    if (breakTimeEnable === "Yes" && Object.keys(breakTimeList).length > 0) {
      const BdyMainHtml = breakTimeList.map((brtimeLst, index) => (
        <div
          id={"tab-pks-" + index}
          data-brktimestr={brtimeLst}
          className={
            index === breakTimeActive ? "tab-pane-sub active" : "tab-pane-sub"
          }
          key={index}
          style={{ display: index === breakTimeActive ? "block" : "none" }}
        >
          {/* <h4 className="tab_mobtrigger">Break { index+1 }  -  {timeToConv12(brtimeLst)}</h4> */}
          <div className="tab_mobrow1 pkselect_tabin">
            <div className="pkselected_filtersec">
              {this.innarBarPkgDiv(index)}
            </div>

            {this.getProductListAndDetails(index)}
          </div>

          <div
            className="white-popup mfp-hide popup_sec mdf-popup-customcls mdf_custom_alert"
            id={"movetonext_popupid_" + index}
            style={{ maxWidth: 500 }}
          >
            <div className="custom_alert1">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header" style={{ textAlign: "center" }}>
                    Wait a second !
                  </div>
                  <div className="alert_body">
                    <img src={CantDlyImg} />
                    <p>
                      <b>
                        Break {index + 1} - {timeToConv12(brtimeLst)}
                      </b>
                    </p>
                    <p>Do you want add one more package this break time ?</p>
                    <div className="alt_btns">
                      <a
                        href="javascript:void(0);"
                        className="button"
                        onClick={this.cartShowfunc.bind(this, index)}
                      >
                        No
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="button popup-modal-dismiss"
                      >
                        Yes
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
      return BdyMainHtml;
    } else {
      return (
        <div id="tab-pks-no" className="tab-pane-sub">
          <div className="tab_mobrow1 pkselect_tabin">
            <div className="pkselected_filtersec">
              {this.innarBarPkgDiv("noIndex")}
            </div>

            {this.getProductListAndDetails("noIndex")}
          </div>
        </div>
      );
    }
  }

  gotoCategoryLst() {
    this.props.setActiveTab(1);
  }

  catgryListBox() {
    var catgryArr =
      cookie.load("catering_eventType") === "hall"
        ? this.props.hallcategory
        : this.props.ownvenuecategory;
    var categoryIdTxt = this.state.category_id;
    var catgryHtml = "";
    if (Object.keys(catgryArr).length > 0) {
      catgryArr.map(
        (catLt, index) =>
          (catgryHtml +=
            "<option value='" +
            catLt.pro_cate_id +
            "' data-catid='" +
            catLt.pro_cate_primary_id +
            "'>" +
            stripslashes(catLt.pro_cate_name) +
            "</option>")
      );
    }
    var catgryDropDown = catgryHtml !== "" ? Parser(catgryHtml) : catgryHtml;

    return (
      <div className="custom_select">
        <select
          className="form-control"
          value={categoryIdTxt}
          onChange={this.handleCatChange.bind(this)}
          name="catgry-listbx"
          id="catgry-listbx"
        >
          {catgryDropDown}
        </select>
      </div>
    );
  }

  handleCatChange(event) {
    var catIdtvl = event.target.value;
    var nameVal = $("#catgry-listbx")
      .find("option:selected")
      .text();
    var prmryVal = $("#catgry-listbx")
      .find("option:selected")
      .attr("data-catid");
    var catFlArr = Array();
    catFlArr["category_id"] = catIdtvl;
    catFlArr["categoryPryId"] = prmryVal;
    catFlArr["category_name"] = nameVal;
    this.props.changeCatgryDet(catFlArr);
  }

  innarBarPkgDiv(indFlg) {
    var kyIndx = "Index-" + indFlg;
    var selectedPakageIds = this.state.selected_pakageIds;
    var selectedPgkId = !(kyIndx in selectedPakageIds)
      ? ""
      : selectedPakageIds[kyIndx];

    var brkVal = "";
    var noIndxCls = "no-brktime-list";
    if (indFlg !== "noIndex") {
      noIndxCls = "";
      var breakTimeList = this.state.breaktime_list;
      brkVal = !(indFlg in breakTimeList) ? "" : breakTimeList[indFlg];
    }

    return (
      <div className={"pkg-detail-top-row " + noIndxCls}>
        {selectedPgkId === "" && (
          <div className="pkselected_catgrytext">
            <div
              className="title4 text-uppercase"
              onClick={this.gotoCategoryLst.bind(this)}
              style={{ cursor: "pointer" }}
            >
              {" "}
              {this.state.selected_category_name}{" "}
            </div>
            <div className="text-uppercase">
              {" "}
              {indFlg !== "noIndex"
                ? "Select Your Packages for each break time"
                : "Select Your Packages"}
            </div>
          </div>
        )}

        {selectedPgkId !== "" ? (
          <div className="pkselected_package back-list-also">
            <a
              href="javascript:void(0)"
              onClick={this.goBacktoPackageList.bind(this, indFlg)}
              className="back-to-pkglist"
              title="Back"
            >
              &lt;&lt; Back To List
            </a>

            <label
              onClick={this.gotoCategoryLst.bind(this)}
              className="pck_selected_ctry"
              style={{ cursor: "pointer" }}
            >
              {this.state.selected_category_name}
            </label>
            {/*<div className="form-group">
						<div className="custom_select">
								<select className="form-control" name="pkg-listbx" onChange={this.changePackage.bind(this,indFlg)} value={selectedPgkId} >
								{this.state.proListDropDown}
								</select>
						</div>
						</div>*/}
          </div>
        ) : (
          <div className="pkselected_package">
            <div className="title4 text-uppercase">Available Packages</div>
            <div className="form-group">{this.catgryListBox()}</div>
          </div>
        )}

        {/* selectedPgkId !== "" && (
          <div className="pkselected_package">
            <div className="title4 text-uppercase">Select your package</div>
            <div className="form-group">
              <div className="custom_select">
                <select
                  className="form-control"
                  name="pkg-listbx"
                  onChange={this.changePackage.bind(this, indFlg)}
                  value={selectedPgkId}
                >
                  {this.state.proListDropDown}
                </select>
              </div>
            </div>
          </div>
        ) */}

        {/*(indFlg !== 'noIndex') ? <div className="pkselected_break text-right">
						<label>Selected Break Time</label>
						<div className="form-group">
							<div className="input_area input_lefticons custom_select">
							  <div className="custom-select-inner">
								<span className="ileft_ico"><img src={ClockImg} /></span>
								<select name="end_breaktime_inp" className="form-control datetimepicker filled breaktime_endcls" value={brkVal} onChange={this.handleBrkTime.bind(this,indFlg)} id={"end-breaktime-"+indFlg}>
									{this.state.break_timeHtml}
								</select>
							  </div>	
							</div>
						</div>
					</div> : <div className="pkselected_break text-right"></div>*/}
      </div>
    );
  }

  handleBrkTime(indFlg, event) {
    var brktimeStr = $("#tab-pks-" + indFlg).attr("data-brktimestr");
    var brktimeEnd = event.target.value;

    var breakTimeList = this.state.breaktime_list;
    if (breakTimeList.indexOf(brktimeEnd) === -1) {
      breakTimeList[indFlg] = brktimeEnd;
      this.setState({ breaktime_list: breakTimeList });
      this.props.sateValChangefun("breaktime_list", breakTimeList);
      cookie.save("breaktimeList", breakTimeList);
    } else {
      $("#tab-pks-" + indFlg)
        .find(".breaktime_endcls")
        .css("border-color", "red");
      setTimeout(function() {
        $("#tab-pks-" + indFlg)
          .find(".breaktime_endcls")
          .removeAttr("style");
      }, 800);
    }

    /* var regex = new RegExp(':', 'g'), brkStr = brktimeStr+':00', brkEnd = brktimeEnd+':00';
		if(parseInt(brkStr.replace(regex, ''), 10) > parseInt(brkEnd.replace(regex, ''), 10)){
		  $('#tab-pks-'+indFlg).find('.breaktime_endcls').css('border-color','red');
		} else {
		  $('#tab-pks-'+indFlg).find('.breaktime_endcls').removeAttr("style");	
		} */
  }

  productListBox() {
    var productList = this.state.product_list;
    var proListDropDown = "";
    if (Object.keys(productList).length > 0) {
      var proListHtml = "";
      var productsArr = [];
      var incr = 0;
      for (var key in productList) {
        var productsTemp = productList[key].products;
        for (var keyinr in productsTemp) {
          productsArr[incr] = productsTemp[keyinr];
          incr++;
        }
      }

      productsArr.map(
        (loadData) =>
          (proListHtml +=
            "<option value='" +
              loadData.product_id +
              "' >" +
              loadData.product_alias !=
            ""
              ? stripslashes(loadData.product_alias)
              : stripslashes(loadData.product_name) +
                this.showMdfPrice(
                  loadData.product_price,
                  loadData.product_disable_cart
                ) +
                "</option>")
      );
      proListDropDown = Parser(proListHtml);
    }

    this.setState({ proListDropDown: proListDropDown });
  }

  showMdfPrice(priceVl, disableCart) {
    var returnTxt =
      parseFloat(priceVl) > 0 && disableCart === "No"
        ? " &nbsp;(" + showPriceValue(priceVl) + " Per Pax)"
        : "";
    return returnTxt;
  }

  goBacktoPackageList(indFlg) {
    showLoader("add-tocart-cls", "class");
    smoothScroll(500, 170);
    this.resetPackage(indFlg);
  }

  getProductListAndDetails(indFlg) {
    var kyIndx = "Index-" + indFlg;
    var selectedPakageIds = this.state.selected_pakageIds;
    var selectedPgkId = !(kyIndx in selectedPakageIds)
      ? ""
      : selectedPakageIds[kyIndx];

    if (selectedPgkId != "") {
      var indKey = "Index_" + indFlg;
      var stateProInfo = this.state.productsdetail;
      var proInfoArr = Array();
      if (Object.keys(stateProInfo).length > 0) {
        var temArr = !(indKey in stateProInfo) ? Array() : stateProInfo[indKey];
        if (Object.keys(temArr).length > 0) {
          proInfoArr =
            Object.keys(temArr["resultset"]).length > 0
              ? temArr["resultset"][0]
              : Array();
        }
      }

      if (Object.keys(proInfoArr).length > 0) {
        if (proInfoArr.product_disable_cart === "Yes") {
          return (
            <div>
              <div id={"proDetMain-" + indFlg} className="package_info_maindiv">
                <div className="bx_out package_descbx">
                  <div className="bx_in">
                    <h3 className="package_info_title text-uppercase">
                      <span>{proInfoArr.product_name}</span>
                    </h3>

                    <h5 className="package_info_subtitle">
                      <span>“{proInfoArr.product_short_description}”</span>
                    </h5>

                    <div className="package_info_desc">
                      {Parser(
                        stripslashes(proInfoArr.product_long_description)
                      )}
                    </div>

                    <div className="terms_conditions">
                      <h4>Terms & Conditions</h4>
                      <ul>
                        <li>
                          **Orders to be placed & confirmed before 72hours of
                          actual event**
                        </li>
                        <li>** GST 7% & Service charges 10% applicable**</li>
                        <li>
                          **The set is applicable for a Minimum of 20 Guests and
                          above**
                        </li>
                        <li>
                          **Free flow of juices at an additional$2.50/Guest**
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chk-payment-btn text-center">
                <div className="chk-payment-btn-row back-to-listbtn">
                  <a
                    href="javascript:void(0)"
                    onClick={this.goBacktoPackageList.bind(this, indFlg)}
                    className="button"
                    title="Back"
                  >
                    Back To List
                  </a>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              {this.getProductDetailHtml(indFlg, selectedPgkId)}

              <div className="chk-payment-btn text-center">
                {/* <a href="#" className="btn btn_download" title="Download Quotation">Download Quotation</a> */}
                <div className="chk-payment-btn-row add-tocart-cls">
                  <a
                    href="javascript:void(0)"
                    onClick={this.goBacktoPackageList.bind(this, indFlg)}
                    className="button"
                    title="Back"
                  >
                    Back
                  </a>

                  <a
                    href="javascript:void(0)"
                    onClick={this.addtoCartPackage.bind(
                      this,
                      indFlg,
                      selectedPgkId
                    )}
                    className="button"
                    title="Add To Cart"
                  >
                    Add To Cart
                  </a>
                </div>

                <div className="cart-success-msg alert alert_success">
                  Great choice! Item added to your cart.
                </div>
                <div className="cart-error-msg alert alert_danger">
                  Sorry!. Product Detaile was not valid.
                </div>
              </div>
            </div>
          );
        }
      }
    } else {
      return (
        <div className="pkg_gridsec">
          <div className="row-replace catering-product-rowlist">
            {this.productListOnlyHtml(indFlg)}
          </div>
        </div>
      );
    }
  }

  getProductDetailHtml(indFlg, selectedPgkId) {
    var proFieldArr = Array();
    var proDetailArr = Array();
    var commonurlArr = Array();
    var stateProDet = this.state.productsdetail;
    var stateProFldDet = this.state.productfeilsdetail;
    var indKey = "Index_" + indFlg;

    if (Object.keys(stateProDet).length > 0) {
      var temArr = !(indKey in stateProDet) ? Array() : stateProDet[indKey];
      if (Object.keys(temArr).length > 0) {
        proDetailArr =
          Object.keys(temArr["resultset"]).length > 0
            ? temArr["resultset"][0]
            : Array();
        commonurlArr = temArr["commonurl"];
      }
    }

    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet)
        ? Array()
        : stateProFldDet[indKey];
    }

    if (Object.keys(proDetailArr).length > 0) {
      var selectpaxcount = !("selectpaxcount" in proFieldArr)
        ? proDetailArr.product_min_pax
        : proFieldArr["selectpaxcount"];
      var productSubTotal = !("productSubTotal" in proFieldArr)
        ? 0.0
        : proFieldArr["productSubTotal"];
      var productAddonsAmount = !("productAddonsAmount" in proFieldArr)
        ? 0.0
        : proFieldArr["productAddonsAmount"];
      var productSetupAmount = !("productSetupAmount" in proFieldArr)
        ? 0.0
        : proFieldArr["productSetupAmount"];
      var productGrandTotal = !("productGrandTotal" in proFieldArr)
        ? 0.0
        : proFieldArr["productGrandTotal"];
      var productSpecialNotes = !("productSpecialNotes" in proFieldArr)
        ? ""
        : proFieldArr["productSpecialNotes"];
      var productPrice = !("productPrice" in proFieldArr)
        ? 0
        : proFieldArr["productPrice"];

      return (
        <div
          id={"proDetMain-" + indFlg}
          data-packageminlimit={proDetailArr.product_min_select}
          data-packagemaxlimit={proDetailArr.product_max_select}
        >
          <div className="pkg_listsec bx_out">
            <div className="bx_in">
              <div className="pkglist_item pkglist_itemtable ">
                <div className="pkglist_img">
                  {callImage(
                    commonurlArr.image_source,
                    proDetailArr.product_thumbnail,
                    422,
                    316,
                    timThumpUrl
                  )}
                </div>
                <div className="pkglist_info">
                  <div className="pkglist_infott">
                    <h3>
                      {proDetailArr.product_alias !== ""
                        ? stripslashes(proDetailArr.product_alias)
                        : stripslashes(proDetailArr.product_name)}{" "}
                    </h3>
                  </div>
                  <p>
                    {proDetailArr.product_long_description != ""
                      ? proDetailArr.product_long_description
                      : proDetailArr.product_short_description}
                  </p>
                </div>
                <div className="pkglist_pricebx text-center">
                  {parseFloat(productPrice) > 0 && (
                    <div>
                      <span className="display_block">Price Per Pax</span>
                      <b className="display_block">
                        {showPriceValue(productPrice)}
                      </b>
                    </div>
                  )}
                  <div className="qty_bx catqty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.updateProductPax.bind(
                        this,
                        indFlg,
                        "minus",
                        selectpaxcount,
                        proDetailArr.product_min_pax
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      name="min_pax_count"
                      value={selectpaxcount}
                      readOnly
                    />
                    <span
                      className="qty_plus"
                      onClick={this.updateProductPax.bind(
                        this,
                        indFlg,
                        "plus",
                        selectpaxcount,
                        proDetailArr.product_min_pax
                      )}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {Object.keys(proDetailArr.modifiers).length > 0 ? (
            <div className="bx_out package_checkbx">
              <div className="bx_in">
                <div
                  className="mdf_common_error"
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontWeight: "bold",
                    display: "none",
                  }}
                ></div>
                <div className="row-replace package_checkrow product_modifiers_listtop">
                  <div className="product_modifiers_listinner">
                    {this.productModifiers(indFlg, proDetailArr.modifiers)}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {Object.keys(proDetailArr.addons_setup).length > 0 ? (
            <div className="bx_out package_addonbx">
              <div className="bx_in">
                <h3 className="package_formtt">
                  <span>Addons</span>
                  <small>(Choose your addons )</small>
                </h3>
                <ul className="package_formchecklist pkgaddon_checklist clearfix">
                  {this.productAddonsSetup(indFlg, proDetailArr.addons_setup)}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}

          {(Object.keys(proDetailArr.delivery).length > 0 ||
            Object.keys(proDetailArr.buffet).length > 0) &&
          cookie.load("catering_eventType") !== "hall" ? (
            <div
              id={"pkg_setupbx_" + indFlg}
              className="bx_out package_setupbx"
            >
              <div className="bx_in">
                <h3 className="package_formtt">
                  <span>Setup</span>
                  <small>(Choose your buffect setup)</small>
                </h3>
                <div className="pkgsetup_list" id={"setup_" + indFlg}>
                  {this.productDeliverySetupHtml(indFlg, proDetailArr.delivery)}{" "}
                  {this.productBuffetSetupHtml(indFlg, proDetailArr.buffet)}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="bx_out package_descbx">
            <div className="bx_in">
              <h3 className="package_formtt package_formtt1">
                <span>Add Special Instruction</span>
              </h3>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Type your Message here"
                  value={productSpecialNotes}
                  onChange={this.handleSpecialInstruction.bind(this, indFlg)}
                ></textarea>
              </div>

              {this.state.productDisclaimer}
            </div>
          </div>

          <div className="bx_out package_descbx" style={{ padding: "0px" }}>
            <div className="bx_in ctr-price-item">
              <div className="row-replace pkg_total_box">
                <div className="cc-addon-subtotal">
                  <div className="pkg_total_row">
                    <div className="cc-addon-subtotal-lhs">
                      <b>Subtotal</b>
                    </div>
                    <div className="cc-addon-subtotal-rhs">
                      : <span>{showPriceValue(productSubTotal)}</span>
                    </div>
                  </div>
                  {parseFloat(productAddonsAmount) > 0 ? (
                    <div className="pkg_total_row">
                      <div className="cc-addon-subtotal-lhs">
                        <b>Add-ons</b>
                      </div>
                      <div className="cc-addon-subtotal-rhs">
                        : <span>{showPriceValue(productAddonsAmount)}</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {parseFloat(productSetupAmount) > 0 ? (
                    <div className="pkg_total_row">
                      <div className="cc-addon-subtotal-lhs">
                        <b>Setup</b>
                      </div>
                      <div className="cc-addon-subtotal-rhs">
                        : <span>{showPriceValue(productSetupAmount)}</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="pkg_total_row grand_total">
                    <div className="cc-addon-subtotal-lhs">
                      <b>Total</b>
                    </div>
                    <div className="cc-addon-subtotal-rhs">
                      : <span>{showPriceValue(productGrandTotal)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="pkg_listsec bx_out"> Product Details is empty.</div>
        </div>
      );
    }
  }

  proMdfmainData(modifiersData) {
    var mdfName = modifiersData.pro_modifier_alias_name
      ? modifiersData.pro_modifier_alias_name
      : modifiersData.pro_modifier_name;
    return modifiersData.pro_modifier_id + "~~" + stripslashes(mdfName);
  }

  productModifiers(indFlg, proModifiers) {
    const Modifiershtml = proModifiers.map((Modifiers, index1) => (
      <div
        className="col-md-cls modifiers_main_list"
        id={"mdf-" + indFlg + "-" + index1}
        data-mdfmin={Modifiers.pro_modifier_min_select}
        data-mdfmax={Modifiers.pro_modifier_max_select}
        data-mdfmain={this.proMdfmainData(Modifiers)}
        key={index1}
      >
        <div className="package_checkitem" style={{ height: "100%" }}>
          <h3 className="package_formtt">
            <span>
              {Modifiers.pro_modifier_alias_name
                ? stripslashes(Modifiers.pro_modifier_alias_name)
                : stripslashes(Modifiers.pro_modifier_name)}
            </span>
            {/*(parseInt(Modifiers.pro_modifier_min_select) > 0)?<small>(Choice of {Modifiers.pro_modifier_min_select+" - "+Modifiers.pro_modifier_max_select} of the followings)</small>:""*/}
            {parseInt(Modifiers.pro_modifier_min_select) === 0 &&
            parseInt(Modifiers.pro_modifier_max_select) === 0 ? (
              ""
            ) : (
              <small>
                (Select{" "}
                {Modifiers.pro_modifier_min_select +
                  " - Max " +
                  Modifiers.pro_modifier_max_select}{" "}
                selections)
              </small>
            )}
          </h3>
          <ul className="package_formchecklist">
            {this.productModifierValues(
              indFlg,
              index1,
              Modifiers.modifiers_values
            )}
          </ul>
          <div className="pkgerror_txt" style={{ display: "none" }}>
            Please select 1 more item!
          </div>
        </div>
      </div>
    ));
    return Modifiershtml;
  }

  productModifierValues(indFlg, index1, modifiersValues) {
    if (Object.keys(modifiersValues).length > 0) {
      const Modifiershtml = modifiersValues.map((ModfrValue, index2) => (
        <li
          key={index2}
          className="modfr_valuecls"
          id={"mdfvalue-" + indFlg + "-" + index1 + "-" + index2}
          data-mdfsub={
            ModfrValue.pro_modifier_value_id +
            "~~" +
            stripslashes(ModfrValue.pro_modifier_value_name) +
            "~~1~~" +
            ModfrValue.pro_modifier_value_price
          }
        >
          <div className="custom_checkbox">
            <input
              type="checkbox"
              className="modifiers_valuecheck"
              value={ModfrValue.pro_modifier_value_id}
              onChange={this.checkModfrValue.bind(this, indFlg, index1, index2)}
              checked={this.CheckModfrValues(indFlg, index1, index2)}
            />
            <span>
              {jsUcfirstFun(stripslashes(ModfrValue.pro_modifier_value_name))}
              {parseFloat(ModfrValue.pro_modifier_value_price) > 0
                ? " ( $ " + ModfrValue.pro_modifier_value_price + " )"
                : ""}
              {ModfrValue.pro_modifier_value_short_description !== "" &&
              ModfrValue.pro_modifier_value_short_description !== null ? (
                <b>( {ModfrValue.pro_modifier_value_short_description} )</b>
              ) : (
                ""
              )}
            </span>
          </div>
        </li>
      ));
      return Modifiershtml;
    } else {
      return <div className="m_norecord"> </div>;
    }
  }

  handlerTestFun() {}

  productAddonsSetup(indFlg, addonsSetup) {
    const addonshtml = addonsSetup.map((proAddons, index3) => (
      <li
        key={index3}
        id={"addonsLi-" + indFlg + "-" + index3}
        onChange={this.openAddonsPopup.bind(this, indFlg, index3, proAddons)}
        className={this.CheckAddonsValues(indFlg, index3, "class")}
        data-addonmin={proAddons.cat_addon_min_select}
        data-addonmax={proAddons.cat_addon_max_select}
        data-addontype={proAddons.cat_addon_selection}
      >
        <div className="custom_checkbox custom-addon-checkbox">
          <input
            type="checkbox"
            onChange={this.handlerTestFun.bind(this)}
            className="addonCheck"
            checked={this.CheckAddonsValues(indFlg, index3, "chkbox")}
          />
          <span> {proAddons.cat_addon_title} </span>
          <div className="pkgerror_txt" style={{ display: "none" }}>
            Please select 1 more item!
          </div>
        </div>
        <div
          id={"addonsDet-" + indFlg + "-" + index3}
          className="white-popup mfp-hide popup_sec addon_popup"
        >
          <div className="pouup_in">
            <div className="addon_poptop">
              <div className="addon_poptop_header">
                <h3>
                  {proAddons.cat_addon_title}

                  {parseInt(proAddons.cat_addon_min_select) === 0 &&
                  parseInt(proAddons.cat_addon_max_select) === 0 ? (
                    ""
                  ) : (
                    <small>
                      (Select{" "}
                      {proAddons.cat_addon_min_select +
                        " - Max " +
                        proAddons.cat_addon_max_select}{" "}
                      selections)
                    </small>
                  )}
                </h3>
              </div>

              <div className="addon_listsec">
                {proAddons.cat_addon_selection === "single"
                  ? this.singleAddonPopupList(indFlg, index3, proAddons)
                  : this.multipleAddonPopupList(indFlg, index3, proAddons)}
              </div>
            </div>
            <div className="addon_popbot">
              <div className="row-replace">
                <div className="cal-md-cls">
                  <a
                    href="javascript:void(0)"
                    className="button common-mfd-closefun"
                    title="Go Back"
                  >
                    Go Back
                  </a>
                </div>
                <div className="col-md-cls text-right">
                  <a
                    href="javascript:void(0)"
                    className="button common-mfd-closefun"
                    title="Continue"
                  >
                    Continue
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
    return addonshtml;
  }

  CheckAddonsValues(indFlg, index3, type) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    var classTxt = "";
    var chkBox = false;
    if (Object.keys(selecteAddonSub).length > 0) {
      classTxt = "active";
      chkBox = true;
    }

    return type === "class" ? classTxt : chkBox;
  }

  CheckModfrValues(indFlg, index1, index2) {
    var productModifier = this.state.product_modifiers;
    var selecteMdfMain = Array();
    var selecteMdfSub = Array();
    var mdfrValueArr = Array();
    if (Object.keys(productModifier).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteMdfMain = !(indKey in productModifier)
        ? Array()
        : productModifier[indKey];
      if (Object.keys(selecteMdfMain).length > 0) {
        var subKey = "modifr_" + index1;
        selecteMdfSub = !(subKey in selecteMdfMain)
          ? Array()
          : selecteMdfMain[subKey];

        mdfrValueArr =
          Object.keys(selecteMdfSub).length > 0
            ? selecteMdfSub["modifiervalues"]
            : Array();
      }
    }

    var mdfrValue = "";
    var mdValKy = "mdfval_" + index2;
    if (Object.keys(mdfrValueArr).length > 0) {
      mdfrValue = !(mdValKy in mdfrValueArr) ? "" : mdfrValueArr[mdValKy];
    }

    var chkBox = false;
    if (mdfrValue !== "") {
      chkBox = true;
    }

    return chkBox;
  }

  openAddonsPopup(indFlg, index3, DelryValue) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;
    $("#" + liIdtxt).removeClass("active");
    if (Object.keys(selecteAddonSub).length > 0) {
      $("#" + liIdtxt).addClass("active");
      $("#" + liIdtxt)
        .find(".addonCheck")
        .prop("checked", true);
    } else {
      $("#" + liIdtxt)
        .find(".addonCheck")
        .prop("checked", false);

      if (DelryValue.cat_addon_selection === "single") {
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".addon_list_single")
          .prop("checked", false);
      } else if (DelryValue.cat_addon_selection === "multiple") {
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".addon_qty_input")
          .val("0");
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".plue_muns_div")
          .hide();
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".qty_count_sp, .delete-act-icon")
          .hide();
        $("#addonsDet-" + indFlg + "-" + index3)
          .find(".add_tocart_div")
          .show();

        /*
				$('#addonsDet-'+indFlg+'-'+index3).find('.plue_muns_div').each(function(qtyIn){
					var addonminpax = $(this).attr('data-addonminpax');
					    addonminpax = (addonminpax !== '') ? parseInt(addonminpax) : 0;
				    $(this).find('.addon_qty_input').val(addonminpax);
						
				});
				*/
      }
    }

    var idText = "#addonsDet-" + indFlg + "-" + index3;
    $.magnificPopup.open({
      items: {
        src: idText,
      },
      type: "inline",
    });
  }

  multipleAddonPopupList(indFlg, index3, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;

    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) => (
        <div
          className="addon_listrw addon_list_main"
          id={"addon_main-" + indFlg + "-" + index3 + "-" + index6}
          key={index6}
        >
          <div className="addon_linfo">
            <h4>{AddonsValue.cat_addonvalues_title}</h4>
            {AddonsValue.cat_addonvalues_description !== "" ? (
              <p>{AddonsValue.cat_addonvalues_description}</p>
            ) : (
              ""
            )}
          </div>
          <div className="addon_lright text-right add_tocart_div">
            <span
              className="cross_multi qty_count_sp"
              style={{ display: "none" }}
            >
              {parseInt(AddonsValue.cat_addonvalues_min_pax) > 0
                ? "x" + AddonsValue.cat_addonvalues_min_pax
                : "x1"}
            </span>
            <div className="act-remove-topdiv">
              <span className="addon_price">
                {showPriceValue(AddonsValue.cat_addonvalues_price)}
              </span>
              <a
                href="javascript:void(0)"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "remove",
                  AddonsValue.cat_addonvalues_min_pax
                )}
                className="delete-act-icon"
                title="Remove"
                style={{ display: "none" }}
              >
                <i className="fa fa-trash-o" data-unicode="f014"></i>
              </a>
            </div>
            <a
              href="javascript:void(0)"
              onClick={this.addonSubAddtoCart.bind(
                this,
                indFlg,
                index3,
                index6
              )}
              className="button"
              title="Add to cart"
            >
              Add to cart
            </a>
          </div>
          <div
            data-addonbind={
              proAddons.product_catering_addons_setup_id +
              "~~" +
              proAddons.cat_addon_title +
              "~~" +
              proAddons.cat_addon_selection +
              "~~" +
              AddonsValue.cat_addonvalues_id +
              "~~" +
              AddonsValue.cat_addonvalues_title +
              "~~" +
              AddonsValue.cat_addonvalues_description +
              "~~" +
              AddonsValue.cat_addonvalues_price
            }
            className="addon_lright text-right plue_muns_div"
            data-addonminpax={AddonsValue.cat_addonvalues_min_pax}
            style={{ display: "none" }}
          >
            <span
              className="cross_multi qty_count_sp"
              style={{ display: "none" }}
            >
              {parseInt(AddonsValue.cat_addonvalues_min_pax) > 0
                ? "x" + AddonsValue.cat_addonvalues_min_pax
                : "x1"}
            </span>

            <span className="addon_price">
              {showPriceValue(AddonsValue.cat_addonvalues_price)}
            </span>

            <div className="qty_bx catqty_bx">
              <span
                className="qty_minus"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "minus",
                  AddonsValue.cat_addonvalues_min_pax
                )}
              >
                --
              </span>
              <input
                type="text"
                className="addon_qty_input"
                value={this.getQtyValue(
                  indFlg,
                  index3,
                  index6,
                  AddonsValue.cat_addonvalues_min_pax
                )}
                readOnly
              />
              <span
                className="qty_plus"
                onClick={this.addonPlusMinusCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6,
                  "plus",
                  AddonsValue.cat_addonvalues_min_pax
                )}
              >
                +
              </span>
            </div>
            <a
              href="javascript:void(0)"
              onClick={this.addonDoneCart.bind(this, indFlg, index3, index6)}
              className="button"
              title="Add to cart"
            >
              Done
            </a>
          </div>
        </div>
      ));
      return AddonsValuehtml;
    }
  }

  singleAddonPopupList(indFlg, index3, proAddons) {
    var proAddonsValues = proAddons.addons_setup_values;

    if (Object.keys(proAddonsValues).length > 0) {
      const AddonsValuehtml = proAddonsValues.map((AddonsValue, index6) => (
        <div
          className="addon_listrw addon_list_main"
          id={"addon_main-" + indFlg + "-" + index3 + "-" + index6}
          key={index6}
        >
          <div className="addon_linfo">
            <div className="custom_checkbox">
              <input
                type="checkbox"
                className="addon_list_single"
                onClick={this.addonSingleCart.bind(
                  this,
                  indFlg,
                  index3,
                  index6
                )}
              />
              <span>
                <h4>{AddonsValue.cat_addonvalues_title}</h4>
                {AddonsValue.cat_addonvalues_description !== "" ? (
                  <p>{AddonsValue.cat_addonvalues_description}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>
          <div
            data-addonbind={
              proAddons.product_catering_addons_setup_id +
              "~~" +
              proAddons.cat_addon_title +
              "~~" +
              proAddons.cat_addon_selection +
              "~~" +
              AddonsValue.cat_addonvalues_id +
              "~~" +
              AddonsValue.cat_addonvalues_title +
              "~~" +
              AddonsValue.cat_addonvalues_description +
              "~~" +
              AddonsValue.cat_addonvalues_price
            }
            className="addon_lright text-right add_tocart_div"
          >
            <span
              className="cross_multi qty_count_sp"
              style={{ display: "none" }}
            >
              x1
            </span>
            <span className="addon_price">
              {showPriceValue(AddonsValue.cat_addonvalues_price)}
            </span>
          </div>
        </div>
      ));
      return AddonsValuehtml;
    }
  }

  getQtyValue(indFlg, index3, index6, minPax) {
    var productAddons = this.state.product_addons;
    var selecteAddonsMain = Array();
    var selecteAddonSub = Array();
    /*var qtyValue = (minPax !== '') ? parseInt(minPax) : 0;*/
    var qtyValue = 0;
    if (Object.keys(productAddons).length > 0) {
      var indKey = "Index_" + indFlg;
      selecteAddonsMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAddonsMain).length > 0) {
        var subKey = "addon_" + index3;
        selecteAddonSub = !(subKey in selecteAddonsMain)
          ? Array()
          : selecteAddonsMain[subKey];
      }
    }

    if (Object.keys(selecteAddonSub).length > 0) {
      var ExpVarbl = selecteAddonSub[index6];
      var mytemparr = ExpVarbl.split("~~");
      qtyValue = mytemparr[7];
    }

    return qtyValue;
  }

  addonSubAddtoCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    $("#" + mainDivId)
      .find(".add_tocart_div")
      .hide();
    $("#" + mainDivId)
      .find(".plue_muns_div")
      .show();
  }

  addonDoneCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var indKey = "Index_" + indFlg;
    var subKey = "addon_" + index3;

    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;

    $("#" + liIdtxt)
      .find(".pkgerror_txt")
      .hide();
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_poptop_header")
      .find("small")
      .removeClass("apply-red");
    var addonMin = $("#" + liIdtxt).attr("data-addonmin");
    var addonMax = $("#" + liIdtxt).attr("data-addonmax");
    var minmaxVld = "Yes";
    if (parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
      minmaxVld = "No";
    }

    var tempArr = Array();
    var allValEmpty = "yes";
    var itmCount = 0;
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_qty_input")
      .each(function(newInt) {
        var alltmFl = $(this)
          .closest(".plue_muns_div")
          .attr("data-addonbind");
        var qtyInt = $(this).val();
        var temarFl = alltmFl + "~~" + qtyInt;
        tempArr[newInt] = temarFl;
        if (parseInt(qtyInt) > 0) {
          allValEmpty = "No";
          itmCount++;
        }
      });

    if (minmaxVld === "Yes") {
      if (parseInt(addonMax) < parseInt(itmCount) && parseInt(itmCount) > 0) {
        var errorTxt = "Maximum of selected item is " + addonMax + " only.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }

      if (parseInt(addonMin) > parseInt(itmCount) && parseInt(itmCount) > 0) {
        var errorTxt = "Please select minimum " + addonMin + " item.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }
    }

    var productAddons = this.state.product_addons;
    if (allValEmpty == "No") {
      productAddons[indKey][subKey] = tempArr;
    } else {
      productAddons[indKey][subKey] = Array();
    }

    this.setState(
      { product_addons: productAddons },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );

    var qty_input = $("#" + mainDivId)
      .find(".addon_qty_input")
      .val();

    $("#" + mainDivId)
      .find(".plue_muns_div")
      .hide();
    if (parseInt(qty_input) > 0) {
      $("#" + mainDivId)
        .find(".add_tocart_div")
        .find(".qty_count_sp, .delete-act-icon")
        .show();
    } else {
      $("#" + mainDivId)
        .find(".add_tocart_div")
        .find(".qty_count_sp, .delete-act-icon")
        .hide();
    }
    $("#" + mainDivId)
      .find(".add_tocart_div")
      .show();
  }

  addonSingleCart(indFlg, index3, index6) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var indKey = "Index_" + indFlg;
    var subKey = "addon_" + index3;
    var liIdtxt = "addonsLi-" + indFlg + "-" + index3;

    $("#" + liIdtxt)
      .find(".pkgerror_txt")
      .hide();
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_poptop_header")
      .find("small")
      .removeClass("apply-red");
    var addonMin = $("#" + liIdtxt).attr("data-addonmin");
    var addonMax = $("#" + liIdtxt).attr("data-addonmax");
    var minmaxVld = "Yes";
    if (parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
      minmaxVld = "No";
    }

    var tempArr = Array();
    var allValEmpty = "yes";
    var incvl = 0;
    $("#" + mainDivId)
      .closest(".addon_popup")
      .find(".addon_list_single")
      .each(function(newInt) {
        if ($(this).is(":checked")) {
          var alltmFl = $(this)
            .closest(".addon_list_main")
            .find(".add_tocart_div")
            .attr("data-addonbind");
          var temarFl = alltmFl + "~~1";
          tempArr[incvl] = temarFl;
          allValEmpty = "No";
          incvl++;
        }
      });

    if (minmaxVld === "Yes") {
      if (parseInt(addonMax) < parseInt(incvl) && parseInt(incvl) > 0) {
        var errorTxt = "Maximum of selected item is " + addonMax + " only.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }

      if (parseInt(addonMin) > parseInt(incvl) && parseInt(incvl) > 0) {
        var errorTxt = "Please select minimum " + addonMin + " item.";
        $("#" + mainDivId)
          .closest(".addon_popup")
          .find(".addon_poptop_header")
          .find("small")
          .addClass("apply-red");
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .html(errorTxt);
        $("#" + liIdtxt)
          .find(".pkgerror_txt")
          .show();
      }
    }

    var productAddons = this.state.product_addons;
    if (allValEmpty == "No") {
      productAddons[indKey][subKey] = tempArr;
    } else {
      productAddons[indKey][subKey] = Array();
    }

    this.setState(
      { product_addons: productAddons },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  addonPlusMinusCart(indFlg, index3, index6, type, minPax) {
    var mainDivId = "addon_main-" + indFlg + "-" + index3 + "-" + index6;
    var qty_input = $("#" + mainDivId)
      .find(".addon_qty_input")
      .val();
    var minPaxVal = minPax !== "" ? parseInt(minPax) : 0;
    if (type === "remove") {
      $("#" + mainDivId)
        .find(".addon_qty_input")
        .val(0);
      this.addonDoneCart(indFlg, index3, index6);
    } else if (type === "minus") {
      qty_input = parseInt(qty_input);
      if (qty_input <= minPaxVal) {
        $("#" + mainDivId)
          .find(".addon_qty_input")
          .val(0);
        this.addonDoneCart(indFlg, index3, index6);
      } else if (qty_input > 0) {
        qty_input = qty_input - 1;
        $("#" + mainDivId)
          .find(".addon_qty_input")
          .val(qty_input);
      }
    } else {
      qty_input =
        parseInt(qty_input) < minPaxVal ? minPaxVal : parseInt(qty_input) + 1;
      $("#" + mainDivId)
        .find(".addon_qty_input")
        .val(qty_input);
    }
    var count_sp_text = "X" + qty_input;
    $("#" + mainDivId)
      .find(".add_tocart_div")
      .find(".qty_count_sp")
      .html(count_sp_text);
  }

  productDeliverySetupHtml(indFlg, DeliverySetup) {
    if (Object.keys(DeliverySetup).length > 0) {
      return (
        <div className="pkgsetup_listrw delivery_main_div">
          <div className="package_controltt">
            <div
              className="delivery_setup_error"
              style={{ color: "red", fontWeight: "bold", display: "none" }}
            >
              Please select your delivery setup
            </div>
            <div className="custom_radio">
              <input
                type="radio"
                defaultChecked
                className="setup_cls delivery_input"
                onClick={this.handleClickSetup.bind(this, indFlg, "delivery")}
                name="setup_input"
                value="delivery"
              />
              <span>Delivery</span>
            </div>
          </div>
          <ul className="package_formchecklist delivery_listing_div">
            {this.productDeliverySetup(indFlg, DeliverySetup)}
          </ul>
        </div>
      );
    }
  }

  productDeliverySetup(indFlg, DeliverySetup) {
    const Deliveryhtml = DeliverySetup.map((DelryValue, index4) => (
      <li
        key={index4}
        className="setup_li"
        data-setupdet={
          DelryValue.catering_delivery_setup_id +
          "~~" +
          DelryValue.catering_delivery_title +
          "~~" +
          DelryValue.catering_delivery_description +
          "~~" +
          DelryValue.catering_delivery_price +
          "~~1~~Delivery"
        }
      >
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={DelryValue.catering_delivery_price}
            onChange={this.handleUpdateSetup.bind(
              this,
              indFlg,
              index4,
              "delivery"
            )}
            checked={this.CheckDeliveryBuff(
              indFlg,
              index4,
              DelryValue.catering_delivery_setup_id,
              "delivery"
            )}
          />
          <span>
            {DelryValue.catering_delivery_title +
              " ( " +
              showPriceValue(DelryValue.catering_delivery_price) +
              " ) : " +
              DelryValue.catering_delivery_description}
          </span>
        </div>
      </li>
    ));
    return Deliveryhtml;
  }

  CheckDeliveryBuff(indFlg, index4, idtxt, type) {
    var sateProductSetup = this.state.product_setup;
    var selecteDBMain = Array();
    var selecteDBfSub = Array();
    var indKey = "Index_" + indFlg;
    var subKey = type;
    var chkBox = false;

    if (Object.keys(sateProductSetup).length > 0) {
      selecteDBMain = !(indKey in sateProductSetup)
        ? Array()
        : sateProductSetup[indKey];
      if (Object.keys(selecteDBMain).length > 0) {
        selecteDBfSub = !(subKey in selecteDBMain)
          ? Array()
          : selecteDBMain[subKey];
        for (var key in selecteDBfSub) {
          var ExpVarbl = selecteDBfSub[key];
          var mytemparr = ExpVarbl.split("~~");
          if (mytemparr[0] == idtxt) {
            chkBox = true;
          }
        }
      }
    }

    return chkBox;
  }

  productBuffetSetupHtml(indFlg, BuffetSetup) {
    if (Object.keys(BuffetSetup).length > 0) {
      return (
        <div className="pkgsetup_listrw buffet_main_div">
          <div className="package_controltt">
            <div
              className="buffet_setup_error"
              style={{ color: "red", fontWeight: "bold", display: "none" }}
            >
              Please select your buffet setup
            </div>
            <div className="custom_radio">
              <input
                type="radio"
                className="setup_cls buffet_input"
                onClick={this.handleClickSetup.bind(this, indFlg, "buffet")}
                name="setup_input"
                value="buffet"
              />
              <span>Buffets Setup</span>
            </div>
          </div>
          <ul
            className="package_formchecklist buffet_listing_div"
            style={{ display: "none" }}
          >
            {this.productBuffetSetup(indFlg, BuffetSetup)}
          </ul>
        </div>
      );
    }
  }

  productBuffetSetup(indFlg, BuffetSetup) {
    const Buffethtml = BuffetSetup.map((BuffetValue, index5) => (
      <li
        key={index5}
        className="setup_li"
        data-setupdet={
          BuffetValue.catering_buffet_setup_id +
          "~~" +
          BuffetValue.catering_buffet_title +
          "~~" +
          BuffetValue.catering_buffet_description +
          "~~" +
          BuffetValue.catering_buffet_price +
          "~~1~~Buffet"
        }
      >
        <div className="custom_checkbox">
          <input
            type="checkbox"
            className="setup_listing_value"
            value={BuffetValue.catering_buffet_price}
            checked={this.CheckDeliveryBuff(
              indFlg,
              index5,
              BuffetValue.catering_buffet_setup_id,
              "buffet"
            )}
            onChange={this.handleUpdateSetup.bind(
              this,
              indFlg,
              index5,
              "buffet"
            )}
          />
          <span>
            {BuffetValue.catering_buffet_title +
              " ( " +
              showPriceValue(BuffetValue.catering_buffet_price) +
              " ) : " +
              BuffetValue.catering_buffet_description}
          </span>
        </div>
      </li>
    ));
    return Buffethtml;
  }

  handleClickSetup(indFlg, type) {
    var indKey = "Index_" + indFlg;
    var subKey1 = "delivery";
    var subKey2 = "buffet";
    var deliveryArr = Array();
    var buffetArr = Array();
    var sateProductSetup = this.state.product_setup;
    $("#setup_" + indFlg)
      .find(".delivery_setup_error")
      .hide();
    $("#setup_" + indFlg)
      .find(".buffet_setup_error")
      .hide();

    if (type == "delivery") {
      $("#setup_" + indFlg)
        .find(".buffet_listing_div")
        .hide();
      $("#setup_" + indFlg)
        .find(".delivery_listing_div")
        .show();
      sateProductSetup[indKey][subKey2] = buffetArr;

      $("#setup_" + indFlg)
        .find(".buffet_listing_div")
        .find(".setup_listing_value")
        .each(function(dlvy) {
          $(this).prop("checked", false);
        });
    } else {
      $("#setup_" + indFlg)
        .find(".delivery_listing_div")
        .hide();
      $("#setup_" + indFlg)
        .find(".buffet_listing_div")
        .show();
      sateProductSetup[indKey][subKey1] = deliveryArr;

      $("#setup_" + indFlg)
        .find(".delivery_listing_div")
        .find(".setup_listing_value")
        .each(function(dlvy) {
          $(this).prop("checked", false);
        });
    }

    this.setState(
      { product_setup: sateProductSetup },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  handleUpdateSetup(indFlg, indexInr, type) {
    var deliveryArr = Array();
    var buffetArr = Array();
    $("#setup_" + indFlg)
      .find(".delivery_setup_error")
      .hide();
    $("#setup_" + indFlg)
      .find(".buffet_setup_error")
      .hide();
    if (type === "delivery") {
      var incvl = 0;
      $("#setup_" + indFlg)
        .find(".delivery_listing_div")
        .find(".setup_listing_value")
        .each(function(dlvy) {
          if ($(this).is(":checked")) {
            deliveryArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
    } else if (type === "buffet") {
      var incvl = 0;
      $("#setup_" + indFlg)
        .find(".buffet_listing_div")
        .find(".setup_listing_value")
        .each(function(dlvy) {
          if ($(this).is(":checked")) {
            buffetArr[incvl] = $(this)
              .closest(".setup_li")
              .attr("data-setupdet");
            incvl++;
          }
        });
    }

    var indKey = "Index_" + indFlg;
    var subKey1 = "delivery";
    var subKey2 = "buffet";
    var sateProductSetup = this.state.product_setup;
    sateProductSetup[indKey][subKey1] = deliveryArr;
    sateProductSetup[indKey][subKey2] = buffetArr;

    this.setState(
      { product_setup: sateProductSetup },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  updateProductPax(indFlg, type, selectpaxcount, minpaxcount) {
    /*minpaxcount = (parseInt(minpaxcount) > 0) ? parseInt(minpaxcount) : 1;
		selectpaxcount = parseInt(selectpaxcount);
		if(type === 'minus') {
			if(minpaxcount < selectpaxcount && selectpaxcount > 1) {
				selectpaxcount = selectpaxcount - 1;
			}
		} else {
			selectpaxcount = selectpaxcount + 1;
		}*/

    $("#proDetMain-" + indFlg)
      .find(".mdf_common_error")
      .html("");
    $("#proDetMain-" + indFlg)
      .find(".mdf_common_error")
      .hide();

    minpaxcount = parseInt(minpaxcount) > 0 ? parseInt(minpaxcount) : 5;
    selectpaxcount = parseInt(selectpaxcount);
    if (type === "minus") {
      var tempVl = selectpaxcount > 5 ? selectpaxcount - 5 : 0;
      if (minpaxcount <= tempVl && tempVl > 1) {
        selectpaxcount = selectpaxcount - 5;
      }
    } else {
      selectpaxcount = selectpaxcount + 5;
    }

    var indKey = "Index_" + indFlg;
    var proFieldArr = Array();
    var stateProFldDet = this.state.productfeilsdetail;
    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet)
        ? Array()
        : stateProFldDet[indKey];
    }
    proFieldArr["selectpaxcount"] = selectpaxcount;
    stateProFldDet[indKey] = proFieldArr;
    this.setState(
      { productfeilsdetail: stateProFldDet },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );
  }

  checkModfrValue(indFlg, index1, index2) {
    var proMin = $("#proDetMain-" + indFlg).attr("data-packageminlimit");
    var proMax = $("#proDetMain-" + indFlg).attr("data-packagemaxlimit");
    var modMin = $("#mdf-" + indFlg + "-" + index1).attr("data-mdfmin");
    var modMax = $("#mdf-" + indFlg + "-" + index1).attr("data-mdfmax");
    var checked_count = 0;
    var total_count = 0;
    var overallProPrice = 0;

    $("#mdf-" + indFlg + "-" + index1)
      .find(".modifiers_valuecheck")
      .each(function() {
        if ($(this).is(":checked")) {
          checked_count += 1;
        }
      });

    $("#proDetMain-" + indFlg)
      .find(".modifiers_valuecheck")
      .each(function() {
        if ($(this).is(":checked")) {
          total_count += 1;
        }
      });

    $("#mdf-" + indFlg + "-" + index1)
      .find(".package_checkitem")
      .removeClass("pkgerror_out");
    $("#mdf-" + indFlg + "-" + index1)
      .find(".pkgerror_txt")
      .hide();
    $("#proDetMain-" + indFlg)
      .find(".mdf_common_error")
      .hide();
    if (parseInt(modMin) > parseInt(checked_count)) {
      var errorTxt = "Please select minimum " + modMin + " item.";
      $("#mdf-" + indFlg + "-" + index1)
        .find(".pkgerror_txt")
        .html(errorTxt);
      $("#mdf-" + indFlg + "-" + index1)
        .find(".package_checkitem")
        .addClass("pkgerror_out");
      $("#mdf-" + indFlg + "-" + index1)
        .find(".pkgerror_txt")
        .show();
      /*$("#mdfvalue-"+indFlg+"-"+index1+"-"+index2).find('.modifiers_valuecheck').prop('checked', true);*/
    } else if (parseInt(modMax) < parseInt(checked_count)) {
      var errorTxt = "Sorry!. maximum of selected item is " + modMax + " only.";
      $("#mdf-" + indFlg + "-" + index1)
        .find(".pkgerror_txt")
        .html(errorTxt);
      $("#mdf-" + indFlg + "-" + index1)
        .find(".package_checkitem")
        .addClass("pkgerror_out");
      $("#mdf-" + indFlg + "-" + index1)
        .find(".pkgerror_txt")
        .show();
      $("#mdfvalue-" + indFlg + "-" + index1 + "-" + index2)
        .find(".modifiers_valuecheck")
        .prop("checked", false);
    } else if (parseInt(proMax) < parseInt(total_count)) {
      var errorTxt = "Sorry!. maximum of selected item is " + proMax + " only.";
      $("#proDetMain-" + indFlg)
        .find(".mdf_common_error")
        .html(errorTxt);
      $("#proDetMain-" + indFlg)
        .find(".mdf_common_error")
        .show();
      $("#mdfvalue-" + indFlg + "-" + index1 + "-" + index2)
        .find(".modifiers_valuecheck")
        .prop("checked", false);
    }

    var indKey = "Index_" + indFlg;
    var subKey = "modifr_" + index1;

    var productModfrPrice = this.state.product_modifiers_price;
    $("#proDetMain-" + indFlg)
      .find(".modifiers_valuecheck")
      .each(function() {
        if ($(this).is(":checked")) {
          var modfrValueData = $(this)
            .closest(".modfr_valuecls")
            .attr("data-mdfsub");
          var mdfvluArr = modfrValueData.split("~~");
          overallProPrice += parseFloat(mdfvluArr[3]);
        }
      });
    productModfrPrice[indKey] = overallProPrice;

    var tempArr = Array();
    var allValEmpty = "yes";
    var modfrMn = $("#mdf-" + indFlg + "-" + index1).attr("data-mdfmain");
    $("#mdf-" + indFlg + "-" + index1)
      .find(".modifiers_valuecheck")
      .each(function(mdint) {
        if ($(this).is(":checked")) {
          tempArr["mdfval_" + mdint] = $(this)
            .closest(".modfr_valuecls")
            .attr("data-mdfsub");
          allValEmpty = "No";
        }
      });

    var productModfr = this.state.product_modifiers;
    if (allValEmpty == "No") {
      var MdfArr = Array();
      MdfArr["modifiers"] = modfrMn;
      MdfArr["modifiervalues"] = tempArr;
      productModfr[indKey][subKey] = MdfArr;
    } else {
      productModfr[indKey][subKey] = Array();
    }

    this.setState(
      {
        product_modifiers: productModfr,
        product_modifiers_price: productModfrPrice,
      },
      function() {
        this.calculatedOverAllAmount(indFlg);
      }.bind(this)
    );

    this.hideModfrValueError(indFlg, index1);
  }

  hideModfrValueError(indFlg, index1) {
    setTimeout(function() {
      $("#mdf-" + indFlg + "-" + index1)
        .find(".package_checkitem")
        .removeClass("pkgerror_out");
      $("#mdf-" + indFlg + "-" + index1)
        .find(".pkgerror_txt")
        .hide();
      $("#proDetMain-" + indFlg)
        .find(".mdf_common_error")
        .hide();
    }, 6000);
  }

  calculatedOverAllAmount(indFlg) {
    var proFieldArr = Array();
    var proDetailArr = Array();
    var AddonsArr = Array();
    var SetupArr = Array();
    var proMdfPrice = 0;
    var stateProDet = this.state.productsdetail;
    var stateProMdfPrice = this.state.product_modifiers_price;
    var stateProFldDet = this.state.productfeilsdetail;
    var productAddons = this.state.product_addons;
    var productSetup = this.state.product_setup;
    var indKey = "Index_" + indFlg;
    var imageSource = "";
    var productImage = "";

    if (Object.keys(stateProDet).length > 0) {
      var temArr = !(indKey in stateProDet) ? Array() : stateProDet[indKey];
      if (Object.keys(temArr).length > 0) {
        proDetailArr =
          Object.keys(temArr["resultset"]).length > 0
            ? temArr["resultset"][0]
            : Array();
        imageSource =
          Object.keys(temArr["commonurl"]).length > 0
            ? temArr["commonurl"].image_source
            : "";
      }
    }

    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet)
        ? Array()
        : stateProFldDet[indKey];
    }

    if (Object.keys(productAddons).length > 0) {
      AddonsArr = !(indKey in productAddons) ? Array() : productAddons[indKey];
    }

    if (Object.keys(productSetup).length > 0) {
      SetupArr = !(indKey in productSetup) ? Array() : productSetup[indKey];
    }

    if (Object.keys(stateProMdfPrice).length > 0) {
      proMdfPrice = !(indKey in stateProMdfPrice)
        ? 0
        : stateProMdfPrice[indKey];
    }

    var minpaxcount = 0;
    var minPaxAmount = 0;
    var selectpaxcount = 0;
    var productPrice = 0;
    var productSubTotal = 0;
    var productAddonsAmount = 0;
    var productSetupAmount = 0;
    var productGrandTotal = 0;

    if (Object.keys(proDetailArr).length > 0) {
      productImage =
        proDetailArr.product_thumbnail === ""
          ? productNoImg
          : imageSource + "/" + proDetailArr.product_thumbnail;

      minpaxcount = proDetailArr.product_min_pax;
      minPaxAmount = proDetailArr.product_min_amount;

      selectpaxcount = !("selectpaxcount" in proFieldArr)
        ? proDetailArr.product_min_pax
        : proFieldArr["selectpaxcount"];

      productPrice =
        parseFloat(proDetailArr.product_price) + parseFloat(proMdfPrice);

      productSubTotal = parseFloat(selectpaxcount) * parseFloat(productPrice);

      for (var key in AddonsArr) {
        var AddonsValue = AddonsArr[key];
        for (var key2 in AddonsValue) {
          var ExpVarbl = AddonsValue[key2];
          var mytemparr = ExpVarbl.split("~~");
          if (parseInt(mytemparr[7]) > 0) {
            productAddonsAmount +=
              parseInt(mytemparr[7]) * parseFloat(mytemparr[6]);
          }
        }
      }

      for (var key in SetupArr) {
        var SetupValue = SetupArr[key];
        if (Object.keys(SetupValue).length > 0) {
          for (var key2 in SetupValue) {
            var ExpVarbl = SetupValue[key2];
            var mytemparr = ExpVarbl.split("~~");
            if (parseInt(mytemparr[4]) > 0) {
              productSetupAmount +=
                parseInt(mytemparr[4]) * parseFloat(mytemparr[3]);
            }
          }
        }
      }

      productGrandTotal =
        parseFloat(productSubTotal) +
        parseFloat(productAddonsAmount) +
        parseFloat(productSetupAmount);

      proFieldArr["product_id"] = proDetailArr.product_id;
      proFieldArr["product_name"] =
        proDetailArr.product_alias != ""
          ? proDetailArr.product_alias
          : stripslashes(proDetailArr.product_name);
      proFieldArr["product_sku"] = stripslashes(proDetailArr.product_sku);
      proFieldArr["product_slug"] = proDetailArr.product_slug;
      proFieldArr["product_disable_cart"] = proDetailArr.product_disable_cart;
      proFieldArr["productImage"] = productImage;
      proFieldArr["minpaxcount"] = minpaxcount;
      proFieldArr["minPaxAmount"] = minPaxAmount;
      proFieldArr["selectpaxcount"] = selectpaxcount;
      proFieldArr["productPrice"] = productPrice;
      proFieldArr["productSubTotal"] = productSubTotal;
      proFieldArr["productAddonsAmount"] = productAddonsAmount;
      proFieldArr["productSetupAmount"] = productSetupAmount;
      proFieldArr["productGrandTotal"] = productGrandTotal;

      stateProFldDet[indKey] = proFieldArr;
      this.setState({ productfeilsdetail: stateProFldDet });
    }
  }

  addtoCartPackage(indFlg, selectedPgkId) {
    var indKey = "Index_" + indFlg;
    var errorFlg = "No";

    /* if(indFlg !== 'noIndex') {
			var brktimeStr = $('#tab-pks-'+indFlg).attr('data-brktimestr');
			var brktimeEnd = $('#tab-pks-'+indFlg).find('.breaktime_endcls').val();
			
			var regex = new RegExp(':', 'g'), brkStr = brktimeStr+':00', brkEnd = brktimeEnd+':00';
			if(parseInt(brkStr.replace(regex, ''), 10) >= parseInt(brkEnd.replace(regex, ''), 10)){
			  errorFlg = 'Yes';
			  $('#tab-pks-'+indFlg).find('.breaktime_endcls').css('border-color','red');
			  window.scrollTo(0, 300);
			  return false;
			}
		} */

    $("#proDetMain-" + indFlg)
      .find(".modifiers_main_list")
      .each(function() {
        var checked_count = 0;
        var modDivId = $(this).attr("id");
        var modMin = $(this).attr("data-mdfmin");
        var modMax = $(this).attr("data-mdfmax");
        $(this)
          .find(".modifiers_valuecheck")
          .each(function() {
            if ($(this).is(":checked")) {
              checked_count += 1;
            }
          });

        if (parseInt(modMin) > parseInt(checked_count)) {
          errorFlg = "Yes";
          var errorTxt = "Please select minimum " + modMin + " item.";
          $(this)
            .find(".pkgerror_txt")
            .html(errorTxt);
          $(this)
            .find(".package_checkitem")
            .addClass("pkgerror_out");
          $(this)
            .find(".pkgerror_txt")
            .show();
          $("html, body").animate(
            {
              scrollTop: $("#" + modDivId).offset().top,
            },
            1000
          );
          return false;
        }

        if (parseInt(modMax) < parseInt(checked_count)) {
          errorFlg = "Yes";
          var errorTxt =
            "Sorry!. maximum of selected item is " + modMax + " only.";
          $(this)
            .find(".pkgerror_txt")
            .html(errorTxt);
          $(this)
            .find(".package_checkitem")
            .addClass("pkgerror_out");
          $(this)
            .find(".pkgerror_txt")
            .show();
          $("html, body").animate(
            {
              scrollTop: $("#" + modDivId).offset().top,
            },
            1000
          );
          return false;
        }
      });

    var proMin = $("#proDetMain-" + indFlg).attr("data-packageminlimit");
    var proMax = $("#proDetMain-" + indFlg).attr("data-packagemaxlimit");
    var total_count = 0;

    $("#proDetMain-" + indFlg)
      .find(".modifiers_valuecheck")
      .each(function() {
        if ($(this).is(":checked")) {
          total_count += 1;
        }
      });

    if (parseInt(proMax) < parseInt(total_count)) {
      errorFlg = "Yes";
      var errorTxt = "Sorry!. maximum of selected item is " + proMax + " only.";
      showCustomCenterAlert("error", errorTxt);
      $("#proDetMain-" + indFlg)
        .find(".mdf_common_error")
        .html(errorTxt);
      $("#proDetMain-" + indFlg)
        .find(".mdf_common_error")
        .show();
      window.scrollTo(0, 500);
      return false;
    }

    if (parseInt(proMin) > parseInt(total_count)) {
      errorFlg = "Yes";
      var errorTxt = "Please select minimum " + proMax + " item.";
      showCustomCenterAlert("error", errorTxt);
      $("#proDetMain-" + indFlg)
        .find(".mdf_common_error")
        .html(errorTxt);
      $("#proDetMain-" + indFlg)
        .find(".mdf_common_error")
        .show();
      window.scrollTo(0, 500);
      return false;
    }

    var addonHaveError = "No";
    var productAddonsChk = this.state.product_addons;
    var selecteAdfMainChk = Array();
    if (Object.keys(productAddonsChk).length > 0) {
      selecteAdfMainChk = !(indKey in productAddonsChk)
        ? Array()
        : productAddonsChk[indKey];
    }
    $("#proDetMain-" + indFlg)
      .find(".pkgaddon_checklist")
      .find("li")
      .each(function() {
        $(this)
          .find(".pkgerror_txt")
          .hide();
        var addontype = $(this).attr("data-addontype");
        var addonMin = $(this).attr("data-addonmin");
        var addonMax = $(this).attr("data-addonmax");
        var minmaxVld = "Yes";
        if (parseInt(addonMin) === 0 && parseInt(addonMax) === 0) {
          minmaxVld = "No";
        }

        if (minmaxVld === "Yes") {
          var getIndx = $(this).attr("id");
          var idTxtArr = getIndx.split("-");
          var mainDivIdTxt = "addonsDet-" + idTxtArr[1] + "-" + idTxtArr[2];
          var intvCnt = 0;

          if (addontype === "single") {
            $("#" + mainDivIdTxt)
              .find(".addon_list_single")
              .each(function(newInt) {
                if ($(this).is(":checked")) {
                  intvCnt++;
                }
              });
          } else if (addontype === "multiple") {
            if (Object.keys(selecteAdfMainChk).length > 0) {
              var subKey = "addon_" + idTxtArr[2];
              var AddonsArr = !(subKey in selecteAdfMainChk)
                ? Array()
                : selecteAdfMainChk[subKey];
              if (Object.keys(AddonsArr).length > 0) {
                for (var key1 in AddonsArr) {
                  var ExpVarbl = AddonsArr[key1];
                  var mytemparr = ExpVarbl.split("~~");
                  if (parseInt(mytemparr[7]) > 0) {
                    intvCnt++;
                  }
                }
              }
            }
          }

          if (parseInt(addonMax) < parseInt(intvCnt) && parseInt(intvCnt) > 0) {
            errorFlg = "Yes";
            addonHaveError = "Yes";
            var errorTxt = "Maximum of selected item is " + addonMax + " only.";
            $(this)
              .find(".pkgerror_txt")
              .html(errorTxt);
            $(this)
              .find(".pkgerror_txt")
              .show();
            $("html, body").animate(
              {
                scrollTop: $("#" + getIndx).offset().top - 200,
              },
              1000
            );
            return false;
          }

          if (parseInt(addonMin) > parseInt(intvCnt) && parseInt(intvCnt) > 0) {
            errorFlg = "Yes";
            addonHaveError = "Yes";
            var errorTxt = "Please select minimum " + addonMin + " item.";
            $(this)
              .find(".pkgerror_txt")
              .html(errorTxt);
            $(this)
              .find(".pkgerror_txt")
              .show();
            $("html, body").animate(
              {
                scrollTop: $("#" + getIndx).offset().top - 200,
              },
              1000
            );
            return false;
          }
        }
      });
    if (addonHaveError === "Yes") {
      return false;
    }

    if (
      $("#setup_" + indFlg)
        .find(".delivery_input")
        .is(":checked")
    ) {
      var incvl = 0;
      $("#setup_" + indFlg)
        .find(".delivery_listing_div")
        .find(".setup_listing_value")
        .each(function(dlvy) {
          if ($(this).is(":checked")) {
            incvl++;
          }
        });

      if (incvl === 0) {
        errorFlg = "Yes";
        $("#setup_" + indFlg)
          .find(".delivery_setup_error")
          .show();
        $("html, body").animate(
          {
            scrollTop: $("#pkg_setupbx_" + indFlg).offset().top,
          },
          1000
        );
        return false;
      }
    }

    if (
      $("#setup_" + indFlg)
        .find(".buffet_input")
        .is(":checked")
    ) {
      var incvl = 0;
      $("#setup_" + indFlg)
        .find(".buffet_listing_div")
        .find(".setup_listing_value")
        .each(function(dlvy) {
          if ($(this).is(":checked")) {
            incvl++;
          }
        });

      if (incvl === 0) {
        errorFlg = "Yes";
        $("#setup_" + indFlg)
          .find(".buffet_setup_error")
          .show();
        $("html, body").animate(
          {
            scrollTop: $("#pkg_setupbx_" + indFlg).offset().top,
          },
          1000
        );
        return false;
      }
    }

    var proFieldArr = Array();
    var product_id = "";
    var stateProFldDet = this.state.productfeilsdetail;
    if (Object.keys(stateProFldDet).length > 0) {
      proFieldArr = !(indKey in stateProFldDet)
        ? Array()
        : stateProFldDet[indKey];
      product_id = !("product_id" in proFieldArr)
        ? ""
        : proFieldArr["product_id"];
      if (product_id === "") {
        errorFlg = "Yes";
        $("#tab-pks-" + indFlg)
          .find(".alert_danger")
          .show();
        $("#tab-pks-" + indFlg)
          .find(".alert_danger")
          .delay(1500)
          .fadeOut();
        return false;
      }

      var minPaxAmt = !("minPaxAmount" in proFieldArr)
        ? 0
        : proFieldArr["minPaxAmount"];
      var productSubTotal = !("productSubTotal" in proFieldArr)
        ? 0
        : proFieldArr["productSubTotal"];

      if (parseFloat(minPaxAmt) > parseFloat(productSubTotal)) {
        var errorTxt =
          "Package amount should be minimum $" +
          minPaxAmt +
          ", so please increase your Pax";
        showCustomCenterAlert("error", errorTxt);
        $("#proDetMain-" + indFlg)
          .find(".mdf_common_error")
          .html(errorTxt);
        $("#proDetMain-" + indFlg)
          .find(".mdf_common_error")
          .show();
        $("html, body").animate(
          {
            scrollTop: $("#proDetMain-" + indFlg).offset().top,
          },
          1000
        );
        return false;
      }
    }

    var postModifiers = [];
    var productModifier = this.state.product_modifiers;
    if (Object.keys(productModifier).length > 0) {
      var selecteMdfMain = !(indKey in productModifier)
        ? Array()
        : productModifier[indKey];
      if (Object.keys(selecteMdfMain).length > 0) {
        for (var key in selecteMdfMain) {
          if (Object.keys(selecteMdfMain[key]).length > 0) {
            var Modifiers = selecteMdfMain[key].modifiers;
            var mdfArr = Modifiers.split("~~");

            var ModifiersValArr = [];
            var ModifiersValues = selecteMdfMain[key].modifiervalues;
            for (var key1 in ModifiersValues) {
              var ExpVarbl = ModifiersValues[key1];
              var mytemparr = ExpVarbl.split("~~");
              ModifiersValArr.push({
                modifier_value_name: mytemparr[1],
                modifier_value_id: mytemparr[0],
                modifier_value_price: mytemparr[3],
                modifier_value_qty: mytemparr[2],
              });
            }

            postModifiers.push({
              modifier_name: mdfArr[1],
              modifier_id: mdfArr[0],
              modifiers_values: ModifiersValArr,
            });
          }
        }
      }
    }

    var postAddons = [];
    var productAddons = this.state.product_addons;
    if (Object.keys(productAddons).length > 0) {
      var selecteAdfMain = !(indKey in productAddons)
        ? Array()
        : productAddons[indKey];
      if (Object.keys(selecteAdfMain).length > 0) {
        for (var key in selecteAdfMain) {
          var Addons = selecteAdfMain[key];
          var AddonsValArr = [];
          var addon_setup_id = "";
          var addon_setup_title = "";
          var addon_setup_selection = "";
          for (var key1 in Addons) {
            var ExpVarbl = Addons[key1];
            var mytemparr = ExpVarbl.split("~~");

            addon_setup_id = mytemparr[0];
            addon_setup_title = mytemparr[1];
            addon_setup_selection = mytemparr[2];

            if (parseInt(mytemparr[7]) > 0) {
              AddonsValArr.push({
                addon_setup_val_id: mytemparr[3],
                addon_setup_val_title: mytemparr[4],
                addon_setup_val_desc: mytemparr[5],
                addon_setup_val_price: mytemparr[6],
                addon_setup_val_qty: mytemparr[7],
              });
            }
          }

          if (Object.keys(AddonsValArr).length > 0) {
            postAddons.push({
              addon_setup_id: addon_setup_id,
              addon_setup_title: addon_setup_title,
              addon_setup_selection: addon_setup_selection,
              addons_setup_values: AddonsValArr,
            });
          }
        }
      }
    }

    var postSetup = [];
    var productSetup = this.state.product_setup;
    if (Object.keys(productSetup).length > 0) {
      var selecteStMain = !(indKey in productSetup)
        ? Array()
        : productSetup[indKey];
      if (Object.keys(selecteStMain).length > 0) {
        var deliveryList = selecteStMain.delivery;
        if (Object.keys(deliveryList).length > 0) {
          for (var key in deliveryList) {
            var ExpVarbl = deliveryList[key];
            var mytemparr = ExpVarbl.split("~~");

            postSetup.push({
              setupid: mytemparr[0],
              setupname: mytemparr[1],
              setupdescription: mytemparr[2],
              setupprice: mytemparr[3],
              setupqty: mytemparr[4],
              setuptype: mytemparr[5],
            });
          }
        }

        var buffetList = selecteStMain.buffet;
        if (Object.keys(buffetList).length > 0) {
          for (var key in buffetList) {
            var ExpVarbl = buffetList[key];
            var mytemparr = ExpVarbl.split("~~");

            postSetup.push({
              setupid: mytemparr[0],
              setupname: mytemparr[1],
              setupdescription: mytemparr[2],
              setupprice: mytemparr[3],
              setupqty: mytemparr[4],
              setuptype: mytemparr[5],
            });
          }
        }
      }
    }

    if (errorFlg === "No" && Object.keys(proFieldArr).length > 0) {
      showLoader("add-tocart-cls", "class");

      var brktimeSts = "No";
      var brktimeCnt = "0";
      var brktimeFlg = indFlg;
      var brktimeStr = "";
      var brktimeEnd = "";
      var brktimeLst = "";

      if (indFlg !== "noIndex") {
        brktimeSts = "Yes";
        brktimeCnt = this.state.breaktime_count;
        brktimeStr = $("#tab-pks-" + indFlg).attr("data-brktimestr");
        brktimeEnd = $("#tab-pks-" + indFlg)
          .find(".breaktime_endcls")
          .val();
        brktimeLst = JSON.stringify(this.state.breaktime_list);
      }

      var outletId = this.state.catering_outlet_id;

      var postHall = [];
      if (cookie.load("catering_eventType") === "hall") {
        postHall.push({
          catering_venue_type: "catering_hall",
          catering_hall_id: cookie.load("cateringHallId"),
          hall_charges: cookie.load("cateringHallCharge"),
        });
      }

      var productSpecialNotes = !("productSpecialNotes" in proFieldArr)
        ? ""
        : proFieldArr["productSpecialNotes"];

      var postObject = {};
      postObject = {
        app_id: appId,
        availability_id: cateringId,
        product_id: proFieldArr["product_id"],
        product_min_qty: proFieldArr["minpaxcount"],
        product_qty: proFieldArr["selectpaxcount"],
        product_name: proFieldArr["product_name"],
        product_sku: proFieldArr["product_sku"],
        product_slug: proFieldArr["product_slug"],
        product_unit_price: proFieldArr["productPrice"],
        product_total_price: proFieldArr["productSubTotal"],
        product_image: proFieldArr["productImage"],
        product_special_notes: productSpecialNotes,
        modifiers: JSON.stringify(postModifiers),
        addons_setup: JSON.stringify(postAddons),
        cateringhall: JSON.stringify(postHall),
        setup: JSON.stringify(postSetup),
        outlet_id: outletId,
        breaktime_enable: brktimeSts,
        breaktime_count: brktimeCnt,
        breaktime_list: brktimeLst,
        breaktime_indexflag: brktimeFlg,
        breaktime_started: brktimeStr,
        breaktime_ended: brktimeEnd,
      };

      if (typeof cookie.load("UserId") === "undefined") {
        postObject["reference_id"] = getReferenceID();
      } else {
        postObject["customer_id"] = cookie.load("UserId");
      }

      axios
        .post(apiUrlV2 + "cateringcart/insert", qs.stringify(postObject))
        .then((res) => {
          if (res.data.status === "ok") {
            var cartDetails = !("cart_details" in res.data.contents)
              ? Array()
              : res.data.contents.cart_details;
            showCartItemCount(cartDetails);
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*this.resetPackage(indFlg);*/
            this.moveToNextPackage(indFlg, res.data.contents);
            this.props.sateValChangefun("add_new_item", "Yes");
            $("#tab-pks-" + indFlg)
              .find(".alert_success")
              .show();
            $("#tab-pks-" + indFlg)
              .find(".alert_success")
              .delay(1800)
              .fadeOut();
          } else {
            $("#tab-pks-" + indFlg)
              .find(".alert_danger")
              .show();
            $("#tab-pks-" + indFlg)
              .find(".alert_danger")
              .delay(1500)
              .fadeOut();
          }
          hideLoader("add-tocart-cls", "class");
        })
        .catch(function(error) {
          console.log(error);
          $("#tab-pks-" + indFlg)
            .find(".alert_danger")
            .show();
          $("#tab-pks-" + indFlg)
            .find(".alert_danger")
            .delay(1500)
            .fadeOut();
        });
    } else {
      $("#tab-pks-" + indFlg)
        .find(".alert_danger")
        .show();
      $("#tab-pks-" + indFlg)
        .find(".alert_danger")
        .delay(1500)
        .fadeOut();
      return false;
    }
  }

  moveToNextPackage(indFlg, cartData) {
    if (indFlg !== "noIndex") {
      this.setState({ cartData: cartData });
      var idTxt = "#movetonext_popupid_" + indFlg;
      $.magnificPopup.open({
        items: {
          src: idTxt,
        },
        type: "inline",
      });
    } else {
      smoothScroll(500, 600);
      this.setState(
        { cart_flag: "yes", cartData: cartData },
        function() {
          this.opencartPopup();
        }.bind(this)
      );
    }
  }

  cartShowfunc(indFlg) {
    var indexTxt = parseInt(indFlg) + 1;
    var breakTimeList = this.state.breaktime_list;
    indexTxt = !(indexTxt in breakTimeList) ? indFlg : indexTxt;

    smoothScroll(500, 600);
    $.magnificPopup.close();
    this.handleTabBtn(indexTxt);

    this.setState(
      { cart_flag: "yes" },
      function() {
        this.opencartPopup();
      }.bind(this)
    );
  }

  opencartPopup() {
    $("#CartListMdl").modal({ backdrop: "static", keyboard: false });
  }

  setPropActiveTabNew(tagvalue) {
    this.props.setActiveTab(parseInt(tagvalue));
  }

  setPropActiveTab(tagvalue) {
    var tabflag = this.props.categoryState.active_tabflag;
    if (parseInt(tabflag) !== parseInt(tagvalue)) {
      this.props.setActiveTab(parseInt(tagvalue));
    } else {
      this.props.sateValChangefun("active_tabflag", 0);
    }
  }

  /* ViewProducts */

  render() {
    return (
      <div>
        {/*<h4 className={"tab_mobtrigger tab_mobtrigger_cls "+this.props.showsActiveHdr(3)} onClick={this.setPropActiveTab.bind(this,3)} >UPDATE YOUR PACKAGE<i></i></h4>*/}
        <div
          className="tab_mobrow1"
          style={{ display: this.props.showsActiveDiv(3) }}
        >
          {this.getTopBarDetails()}

          <div className="pkselect_tabsec">
            <ul className="nav nav-tabs">{this.getBreakTime_BarHtml()}</ul>

            <div className="tab-content">{this.getProductListHtml()}</div>
          </div>

          <div id="dvLoading" className="dvLoaderCls"></div>
        </div>

        <Cartpopup
          proState={this.state}
          setPropActiveTabFun={this.props.setActiveTab}
        />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var productslist = Array();
  var image_source = "";
  if (Object.keys(state.cateringprodcutlist).length > 0) {
    if (state.cateringprodcutlist[0].status === "success") {
      productslist = state.cateringprodcutlist[0].result_set;
      image_source = state.cateringprodcutlist[0].image_source;
    }
  }

  var productsDet = Array();
  var imageCommon = "";
  if (Object.keys(state.cateringproductdetail).length > 0) {
    if (state.cateringproductdetail[0].status === "success") {
      productsDet = state.cateringproductdetail[0].result_set;
      imageCommon = state.cateringproductdetail[0].common;
    }
  }

  var categoryRst = Array();
  if (Object.keys(state.categorylist).length > 0) {
    if (state.categorylist[0].status === "ok") {
      categoryRst = state.categorylist[0].result_set;
    }
  }

  var owncategoryRst = Array();
  if (Object.keys(state.ownvenuecategory).length > 0) {
    if (state.ownvenuecategory[0].status === "ok") {
      owncategoryRst = state.ownvenuecategory[0].result_set;
    }
  }

  var hallcategoryRst = Array();
  if (Object.keys(state.hallcategory).length > 0) {
    if (state.hallcategory[0].status === "ok") {
      hallcategoryRst = state.hallcategory[0].result_set;
    }
  }

  return {
    staticblack: blacksArr,
    catrgprodcutlistArr: productslist,
    catrgprodcutimageurl: image_source,
    catrgprodcutdetailArr: productsDet,
    catrgprodcutcommon: imageCommon,
    categorylist: categoryRst,
    ownvenuecategory: owncategoryRst,
    hallcategory: hallcategoryRst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCateringProductList: (proSlug) => {
      dispatch({ type: GET_CATERING_PRODCUTLIST, proSlug });
    },
    getCateringProductDetail: (proSlug) => {
      dispatch({ type: GET_CATERING_PRODCUTDETAIL, proSlug });
    },
    getCateringHallsn: (proSlug) => {
      dispatch({ type: GET_HALL_CATEGORY, proSlug });
    },
    getCateringVenues: (proSlug) => {
      dispatch({ type: GET_OWNVENUE_CATEGORY, proSlug });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Packagedetails);
