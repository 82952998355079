/* eslint-disable */
import React, { Component } from "react";
import {
  stripslashes,
  addressFormat,
  showAlert,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
} from "../Helpers/SettingHelper";
import { hashHistory } from "react-router";
import { createBrowserHistory as history } from "history";
import { baseUrl, appId, apiUrl, cateringId } from "../Helpers/Config";
import cookie from "react-cookies";

import axios from "axios";
import moment from "moment";

import Parser from "html-react-parser";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

var qs = require("qs");
var dateFormat = require("dateformat");

import innerBanner from "../../common/images/page-banner.jpg";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    $(".dvLoadrCls").fadeOut(500);
  }

  componentWillReceiveProps(nextProps) {
    $(".dvLoadrCls").fadeOut(500);
  }

  render() {
    return (
      <div>
        <Header />
        <div className=" common-top-div pagesList-main-div">
          <div className="Pages">
            {/* Header start */}

            {/* Header End */}
          </div>
          <div className="common-inner-blckdiv">
            <div className="page-banner">
              <img src={innerBanner} />
              <div className="inner-banner-content">
                <h2>Kakis Club</h2>
              </div>
            </div>
          </div>

          <div className="container common-top-div pagesList-main-div">
            <div className="cms-page">
              <div className="container-one cms-content">Coming Soon...</div>
            </div>
          </div>
        </div>
        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

export default Events;
