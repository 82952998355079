/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Parser from "html-react-parser";

import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";

import { GET_PRODUCT_LIST, GET_PRODUCT_DETAIL } from "../../actions";

import Megaphoneicon from "../../common/images/speaker.png";

import joinusImg from "../../common/images/join-us-img.png";
import joinusIcon from "../../common/images/join-us-icon.png";
import noimage from "../../common/images/no-img-product.png";
import smartPhone from "../../common/images/smart-phone.png";
import proVouchar from "../../common/images/pro-vouchar.png";
import cashVouchar from "../../common/images/cash-vouchar.png";
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productdetail: [],
      productdetailcommon: [],
      productdetailstatus: [],
      voucherProductDetail: [],
      voucher_product_qty: "",
      voucher_name: "",
      voucher_mobile: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
      error_voucher_mobile: "",
      voucherIndexFlag: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    let selectedMenucatid = nextProps.selectedMenucatid;

    let sub_selectedNavigation = nextProps.productState.selectedsubNavigation;

    // console.log(nextProps.selectedNavigation,this.props.selectedNavigation,nextProps.sub_selectedNavigation ,'nextProps.selectedNavigation,this.props.selectedNavigation, sub_selectedNavigation');

    //if(nextProps.productState.selectedsubNavigation !== this.props.productState.selectedsubNavigation){

    if (nextProps.selectedNavigation !== this.props.selectedNavigation) {
      if (selectedNavigation !== "") {
        showLoader("productlist-main-div", "class");
        this.props.getProductList(
          selectedMenucatid,
          sub_selectedNavigation,
          ""
        );

        $(".addcart_done_maindiv").hide();
        $(".smiple_product_lk").show();
      }
      this.setState({selectedNavigation: selectedNavigation,selectedslugType: slugType});
    }

    if (nextProps.productdetail !== this.props.productdetail) {
      if (nextProps.productdetailstatus === "success") {
        this.setState({
          productdetail: nextProps.productdetail,
          productdetailcommon: nextProps.productdetailcommon,
          productdetailstatus: nextProps.productdetailstatus,
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function() {
          $.magnificPopup.open({
            items: {
              src: ".pdetail_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({
          productdetail: [],
          productdetailcommon: [],
          productdetailstatus: "",
        });
      }
    }
  }

  getProductSplPrice(productDetail) {
    // var productSpecialPrice = productDetail.product_price_old !== ""
    //     ? parseFloat(productDetail.product_price_old)
    //     : 0;

    if (productDetail.product_specialprice_applicable === "yes") {
      return (
        <>
          <div className="product-spl-price">
            <h3>
              <sup>S$</sup>
              {productDetail.product_price}
            </h3>
          </div>
          <div className="product-price">
            <h3>
              <sup>S$</sup>
              {Parser(productDetail.product_special_price)}
            </h3>
          </div>
        </>
      );
    } else {
      return (
        <div className="product-price">
          <h3>
            <sup>S$</sup>
            {productDetail.product_price}
          </h3>
        </div>
      );
    }
  }

  productsubcatlist() {
    var subcatlistArr = this.props.productSubcatlist;

    if (Object.keys(subcatlistArr).length > 0) {
      //showLoader('productlist-main-div','class');

      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (
          categories.product_list.length > 0 &&
          categories.product_list[0] != null
        ) {
          return (
            <div
              className="innerproduct"
              key={categoryIndex}
              id={"pro_cat_" + categories.pro_subcate_id}
            >
              <div className="container-one">
                <h3 id={categories.pro_subcate_slug}>
                  {stripslashes(categories.pro_subcate_name)}
                </h3>
                <div className="innerproducts-list-maindiv">
                                  {this.productList(categories.product_list, categories.product_list.length,categories.pro_subcate_slug)}

                </div>
              </div>
            </div>
          );
        }
      });
      hideLoader("productlist-main-div", "class");
      $(".dvLoaderCls").fadeOut(500);
      return productDetails;
    } else {
      $(".dvLoaderCls").fadeOut(500);
      return "";
    }
  }

   viewProDetail(productDetail, pro_subcate_slug, event) {
    event.preventDefault();
  
  if(productDetail.product_stock > 0 ){
    this.props.history.push(
      "/products/" +
        this.state.selectedNavigation +
        "/" +
        pro_subcate_slug +
        "/" +
        productDetail.product_slug
    );
   }

  }

  chkProStockCls(proSlug, Stock) {
    var returnText =
      "product-list-main-div  innerproduct-col no-stock-product " + proSlug;
    if (Stock > 0) {
      returnText = "product-list-main-div innerproduct-col " + proSlug;
    }

    return returnText;
  }

  /* Products List */
  productList(list, productcount, pro_subcate_slug) {
    var imageSource = this.props.productCommon.product_image_source;
    var slugType = this.props.selectedSlugType;
    var naviSlug = this.props.selectedNavigation;
    const listProduct = list[0].map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;

      return (
        <div
          className={this.chkProStockCls(
            productDetail.product_slug,
            productDetail.product_stock
          )}
          id={prodivId}
          key={index}
        >
          <div className="innerproduct-item products-image-div">
            <div className="innerproduct-item-image ">
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : productDetail.product_type !== "5" ? (
                <img src={noimage} />
              ) : productDetail.product_voucher === "f" ? (
                <img src={proVouchar} />
              ) : (
                <img src={cashVouchar} />
              )}
            </div>

            {/*<div className="product-tags-list">
					
					
					
					{(Object.keys(productDetail.product_tag).length > 0)?<ul>
							{ 
							  (productDetail.product_tag).map(
							  (producttag,index1) => {
							  return (<li key={index1}>{producttag.pro_tag_name}</li>) 
							  } )
							}
					</ul>:''}
					</div>*/}
          </div>

          <div className="innerproduct-item-text product-info-div">
            <h4>
              {productDetail.product_alias !== ""
                ? stripslashes(productDetail.product_alias)
                : stripslashes(productDetail.product_name)}
            </h4>

            {Object.keys(productDetail.product_tag).length > 0 ? (
              <>
                <div className="special-sec">
                  {productDetail.product_tag.map((producttag, index1) => {
                    return (
                      <span className="special" key={"tag" + index1}>
                        {producttag.pro_tag_name}
                      </span>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}

            {/*<div className="bakery-review">
						<span className="fa fa-star wppc-checked"></span>
						<span className="fa fa-star wppc-checked"></span>
						<span className="fa fa-star wppc-checked"></span>
						<span className="fa fa-star"></span>
						<span className="fa fa-star"></span>
						</div>*/}
            {description !== "" ? (
              <p>{stripslashes(description)}</p>
            ) : (
              <p className="rmv-border-bottom"></p>
            )}
            <div className="products-ordernow-action">
              {this.getProductSplPrice(productDetail)}
              {/*(productDetail.product_stock > 0 || productDetail.product_stock === null)?((productDetail.product_type === '1')?<a className="btn btn_pink btn_minwid smiple_product_lk" href="javascript:void(0);" onClick={this.addToCartSimple.bind(this,productDetail,'initial')}>Add to Cart</a>:<Link to={"/products/"+slugType+"/"+naviSlug+"/"+productDetail.product_slug} title="Product Details" className="btn btn_pink btn_minwid compo_product_lk">Order Now</Link>):<a className="btn btn_pink btn_minwid disabled" href='javascript:;'>Sold Out</a>*/}

              {(productDetail.product_stock > 0 || productDetail.product_stock === null)?((productDetail.product_type === '1' || productDetail.product_type === '5' )?<a className="button smiple_product_lk" href="javascript:void(0);" onClick={this.addToCartSimple.bind(this,productDetail,'initial')}>Add to Cart</a>:<Link title="Product Details" className="button compo_product_lk"
              onClick={this.viewProDetail.bind(
                this,
                productDetail,
                pro_subcate_slug
              )}
            >Order Now</Link>):<a className="btn btn_pink btn_minwid disabled" href='javascript:;'>Sold Out</a>}

              <div className="innerproduct-button">
                <div className="addcart_row addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        productDetail.product_primary_id,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        productDetail.product_primary_id,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                  <button
                    className="btn btn_black"
                    onClick={this.addToCartSimple.bind(
                      this,
                      productDetail,
                      "done"
                    )}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return listProduct;
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    if (productDetail.product_type === "5") {
      var IndexFlg = productDetail.product_primary_id;
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });

      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });
    } else {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        return false;
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          specialprice_applicable: specialprice_applicable,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
          }

          return false;
        });
      }
    }
  }

  addToCartVoucherMe() {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/
    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;
    var postObject = {};
    postObject = {
      app_id: appId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_type: 5,
      availability_id: availabilityId,
      availability_name: availabilityName,
      isAddonProduct: isAddonProduct,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      voucher_for: "Me",
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();
      if (res.data.status === "ok") {
        this.props.sateValChange("cartflg", "yes");
        removePromoCkValue();
        showCustomAlert("success", "Great choice! Item added to your cart.");
        /*showCartLst();*/
        this.handleShowAlertFun(
          "success",
          "Great choice! Item added to your cart."
        );
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This is not a valid email.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.state.productimagePath +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        postImagePath = "";
      }

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_image: postImagePath,
        product_type: 5,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        voucher_for: "Gift",
        voucher_gift_name: this.state.voucher_name,
        voucher_gift_mobile: this.state.voucher_mobile,
        voucher_gift_email: this.state.voucher_email,
        voucher_gift_message: this.state.voucher_message,
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };

      Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
        (res) => {
          $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
          hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucher_name: "",
              voucher_mobile: "",
              voucher_email: "",
              voucher_message: "",
            });
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucherIndexFlag: "",
            });
          }

          return false;
        }
      );
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {

    /*console.log('two');*/

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function() {
        $(this)
          .parents(".focus-out")
          .addClass("focused");
      });
      $(".input-focus").blur(function() {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this)
            .parents(".focus-out")
            .removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }
  }

  getPdetail(proSlug) {
    $("#dvLoading").fadeIn();
    var orderOutletId = cookie.load("orderOutletId");
    var orderDateTime = cookie.load("orderDateTime");
    if (orderOutletId === "" || orderOutletId === undefined) {
      window.$.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });

      return false;
    } else if (orderDateTime === "" || orderDateTime === undefined) {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
      return false;
    } else {
    }

    this.props.getProductDetail(proSlug);

    orderOutletId = orderOutletId === undefined ? "" : orderOutletId;
    //this.props.getAddonProList(orderOutletId);
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    this.setState({ ["error_" + item]: "" });
  };

  addClassInput() {
    $(".input-focus").focus(function() {
      $(this)
        .parents(".focus-out")
        .addClass("focused");
    });
    $(".input-focus").blur(function() {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this)
          .parents(".focus-out")
          .removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
  }

  render() {
    var couponSetting = {
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      dots: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <>
        {/* Offer section
			<section className="product-offer-section">
                <Slider {...couponSetting}>
                  <div className="pro-offer-item">
                    <div className="pro-offer-box-active">
                      
                      <div className="pro-offer-icon">
                        <img src={Megaphoneicon} className="pro-offer-img" />
                      </div>
                      <div className="pro-offer-txt">
                        <h5>10% Off for Takeaway</h5>
                        <p>Enjoy 10% off for your 1st purchase</p>
                      </div>
                    </div>
                  </div>
                  <div className="pro-offer-item">
                    <div className="pro-offer-box-active">
                      
                      <div className="pro-offer-icon">
                        <img src={Megaphoneicon} className="pro-offer-img" />
                      </div>
                      <div className="pro-offer-txt">
                        <h5>10% Off for Takeaway</h5>
                        <p>Enjoy 10% off for your 1st purchase</p>
                      </div>
                    </div>
                  </div>
                  <div className="pro-offer-item">
                    <div className="pro-offer-box-active">
                      
                      <div className="pro-offer-icon">
                        <img src={Megaphoneicon} className="pro-offer-img" />
                      </div>
                      <div className="pro-offer-txt">
                        <h5>10% Off for Takeaway</h5>
                        <p>Enjoy 10% off for your 1st purchase</p>
                      </div>
                    </div>
                  </div>
				  <div className="pro-offer-item">
                    <div className="pro-offer-box-active">
                      
                      <div className="pro-offer-icon">
                        <img src={Megaphoneicon} className="pro-offer-img" />
                      </div>
                      <div className="pro-offer-txt">
                        <h5>10% Off for Takeaway</h5>
                        <p>Enjoy 10% off for your 1st purchase</p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </section>*/}
        <div id="dvLoading" className="dvLoaderCls"></div>
        <div className="productlist-main-div">
          <div className="innerproduct_row">
            {/*<div className="tag_list">
						<ul>
							<li><a href="">Show All</a></li>
							<li className="active"><a href="">Special</a></li>
						</ul>
					</div>*/}
            {this.productsubcatlist()}
          </div>

          <div
            id="vouchergift-popup"
            className="white-popup mfp-hide popup_sec vouchergift-popup"
          >
            <div className="voucher-popup-inner pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3>IM PURCHASING THIS</h3>
              </div>
              <div className="full-login-new-body">
                <div className="popup-footer voucher-popup-body product_voucher_body">
                  <div className="mainacc_menucontent">
                    <div className="main_tabsec">
                      <div className="order-tab-section">
                        <div className="mainacc_mobrow">
                          <div className="tab_sec main_tabsec_inner product_tag_sec_main">
                            <div className="myacc_filter">
                              <div className="tab_sec filter_tabsec">
                                <ul className="nav nav-tabs text-center product_voucher">
                                  <li className="active">
                                    <a
                                      data-toggle="tab"
                                      href="#tab-id-inn5"
                                      aria-expanded="true"
                                    >
                                      <span>For Gift</span>
                                    </a>
                                  </li>
                                  <li className="">
                                    <a
                                      data-toggle="tab"
                                      href="#tab-id-inn6"
                                      aria-expanded="false"
                                    >
                                      <span>For Me</span>
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content">
                                  <div
                                    id="tab-id-inn5"
                                    className="tab-pane fade active in"
                                  >
                                    <h4 className="tab_mobtrigger inner_tab_border active">
                                      <i></i>
                                    </h4>
                                    <div className="tab_mobrow filter_tabin">
                                      <div className="order-delivery">
                                        <div className="ord-body">
                                          <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                                            <ul className="myacc_order_details">
                                              <div className="voucher-popup-inner product-voucher-popup">
                                                <h2>
                                                  GIFT RECEIVER'S INFORMATION
                                                </h2>
                                                <div className="form-group">
                                                  <div className="focus-out">
                                                    <label>Name</label>
                                                    <input
                                                      type="text"
                                                      id="guest-name"
                                                      name="guest-name"
                                                      value={
                                                        this.state.voucher_name
                                                      }
                                                      onClick={this.addClassInput.bind(
                                                        this
                                                      )}
                                                      onChange={this.handleChangeTxt.bind(
                                                        this,
                                                        "voucher_name"
                                                      )}
                                                      className="form-control input-focus"
                                                    />
                                                    {
                                                      this.state
                                                        .error_voucher_name
                                                    }
                                                  </div>
                                                </div>
                                                <div className="form-group">
                                                  <div className="focus-out">
                                                    <label>
                                                      Enter Mobile Number
                                                    </label>
                                                    <input
                                                      type="tel"
                                                      id="guest-number"
                                                      name="guest-number"
                                                      value={
                                                        this.state
                                                          .voucher_mobile
                                                      }
                                                      onClick={this.addClassInput.bind(
                                                        this
                                                      )}
                                                      onChange={this.handleChangeTxt.bind(
                                                        this,
                                                        "voucher_mobile"
                                                      )}
                                                      className="form-control input-focus"
                                                    />
                                                    {
                                                      this.state
                                                        .error_voucher_mobile
                                                    }
                                                  </div>
                                                </div>
                                                <div className="form-group">
                                                  <div className="focus-out">
                                                    <label>Email Address</label>
                                                    <input
                                                      type="text"
                                                      id="guest-email"
                                                      name="guest-email"
                                                      value={
                                                        this.state.voucher_email
                                                      }
                                                      onClick={this.addClassInput.bind(
                                                        this
                                                      )}
                                                      onChange={this.handleChangeTxt.bind(
                                                        this,
                                                        "voucher_email"
                                                      )}
                                                      className="form-control input-focus"
                                                    />
                                                    {
                                                      this.state
                                                        .error_voucher_email
                                                    }
                                                  </div>
                                                </div>
                                                <div className="form-group">
                                                  <div className="focus-out">
                                                    <label>Message</label>
                                                    <textarea
                                                      type="text"
                                                      id="guest-message"
                                                      name="guest-message"
                                                      value={
                                                        this.state
                                                          .voucher_message
                                                      }
                                                      onClick={this.addClassInput.bind(
                                                        this
                                                      )}
                                                      onChange={this.handleChangeTxt.bind(
                                                        this,
                                                        "voucher_message"
                                                      )}
                                                      className="form-control input-focus"
                                                    />
                                                    {
                                                      this.state
                                                        .error_voucher_message
                                                    }
                                                  </div>
                                                </div>

                                                <div className="howtouse-gift-vouchar">
                                                  <img src={smartPhone} />
                                                  <h3>How to use</h3>
                                                  <p>
                                                    After purchasing, your
                                                    recipient will get email
                                                    notification to use the
                                                    above email address to sign
                                                    up. Vouchers will be
                                                    available within their
                                                    account.
                                                  </p>
                                                </div>
                                                <div className="form-group gift-group">
                                                  <button
                                                    type="button"
                                                    className="button"
                                                    onClick={this.addToCartVoucher.bind(
                                                      this
                                                    )}
                                                  >
                                                    Add To Cart
                                                  </button>
                                                </div>
                                              </div>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="tab-id-inn6"
                                    className="tab-pane fade"
                                  >
                                    <h4 className="tab_mobtrigger inner_tab_border">
                                      <i></i>
                                    </h4>
                                    <div className="tab_mobrow filter_tabin">
                                      <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                                        <ul className="myacc_order_details used-voucher">
                                          <div className="voucher-popup-inner product-voucher-popup">
                                            <div className="howtouse-gift-vouchar">
                                              <img src={smartPhone} />
                                              <h3>How to use</h3>
                                              <p>
                                                After purchasing, your recipient
                                                will get email notification to
                                                use the above email address to
                                                sign up. Vouchers will be
                                                available within their account.
                                              </p>
                                            </div>

                                            <button
                                              type="button"
                                              className="button"
                                              onClick={this.addToCartVoucherMe.bind(
                                                this
                                              )}
                                            >
                                              Add To Cart
                                            </button>
                                          </div>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* order type section - start */}
          <div className="home-ordernow-section">
            <div className="join-us-section">
              <div className="lft-sec-prt">
                <img src={joinusIcon} />
                <h4>Join us As</h4>
                <h3>Kakis Member</h3>
              </div>
              <div className="mid-sec-prt">
                <img src={joinusImg} />
              </div>
              <div className="rgt-sec-prt">
                <p>
                  Earn Rewards, Enjoy Deals, Earn Promos <br /> & Have Lots Of
                  Fun
                </p>
                {!cookie.load("UserId") && (
                  <a
                    href="#signup-popup"
                    className="open-popup-link"
                    title="Sign Up Now"
                  >
                    {" "}
                    <span>Sign Up Now</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          id="product-detial-popup"
          className="white-popup mfp-hide popup_sec pdetail_popup"
        >
          <Productdetailpopup
            {...this.props}
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChangepop={this.sateValChangepop}
          />
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategory_result;
        productCmn = tempArr[0].common;
      }
    }
  }

  var tempArr = Array();
  var menu_slug = "";
  var menu_cat_id = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";

  let sub_navigateRst = Array();
  let sub_navigateCmn = Array();
  let sub_selectedCatry = "";
  let sub_menu_slug = "";

  if (Object.keys(state.product).length >= 1) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 1) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        selectedCatry = tempArr[0].result_set[0].category_name;
        // menu_slug = tempArr[0].result_set[0].pro_cate_slug;
        // menu_cat_id = tempArr[0].result_set[0].menu_category_id;
        menu_type =
          tempArr[0].result_set[0].menu_type == "main"
            ? "category"
            : "subcategory";

        menu_slug = tempArr[1].menu_slug;
        menu_cat_id = tempArr[1].menu_cat_id;
      }

      if (tempArr[2].status === "ok") {
        sub_navigateRst = tempArr[2].result_set;
        sub_navigateCmn = tempArr[2].common;
        sub_selectedCatry = tempArr[2].result_set[0].subcategory_name;
        sub_menu_slug = tempArr[2].result_set[0].pro_subcate_slug;
      }
    }
  }

  var proDateilArr = Array();
  var proCommonArr = Array();
  var prodetailstatus = "";

  if (Object.keys(state.productdetail).length > 0) {
    if (
      state.productdetail[0].status === "ok" &&
      Object.keys(state.productdetail[0].result_set).length > 0
    ) {
      proDateilArr = state.productdetail[0].result_set;
      proCommonArr = state.productdetail[0].common;
      prodetailstatus = "success";
    } else {
      prodetailstatus = "failure";
    }
  }

  var addonProArr = Array();
  var addonCommonArr = Array();
  if (Object.keys(state.addonproduct).length > 0) {
    if (
      state.addonproduct[0].status === "ok" &&
      Object.keys(state.addonproduct[0].result_set).length > 0
    ) {
      addonProArr = state.addonproduct[0].result_set;
      addonCommonArr = state.addonproduct[0].common;
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedMenucatid: menu_cat_id,
    selectedSlugType: menu_type,
    sub_selectedNavigation: sub_menu_slug,

    productdetail: proDateilArr,
    productcommon: proCommonArr,
    productdetailstatus: prodetailstatus,
    addonproductlist: addonProArr,
    addonproductcommon: addonCommonArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
    getProductDetail: (proSlug) => {
      dispatch({ type: GET_PRODUCT_DETAIL, proSlug });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);

class Productdetailpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      navigateMenuTmp: [],
      selectedCategoryName: "",
      selectedSlugType: "",
      selectedSlugValue: "",
      selectedProSlug: "",
      catNavIndex: 0,
      productdetailstatus: "",
      productdetail: [],
      productcommon: [],
      selectedProId: "",
      remaining: 30,
      product_remarks: "",
      mdfinput_value: 1,
      mdfApi_call: "Yes",
      modParentProductId: "",
      modProductPrice: "",
      modProductTotalPrice: "",
      compoinput_value: 1,
      incrCompoPrice: 0,
      compoApi_call: "Yes",
      cartTriggerFlg: "No",
    };
  }

  componentWillReceiveProps(nextProps) {}

  /* copy product detail page URL for sharing */
  copyCurrentURL() {
    var dummy = document.createElement("textArea");
    var text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    var range, selection;
    if (navigator.userAgent.match(/ipad|iphone/i)) {
      range = document.createRange();
      range.selectNodeContents(dummy);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      dummy.setSelectionRange(0, 999999);
    } else {
      dummy.select();
    }

    document.execCommand("copy");
    document.body.removeChild(dummy);
    $("html, body").animate(
      {
        scrollTop: 100,
      },
      2000
    );
    $(".copied")
      .fadeIn()
      .delay(1000)
      .fadeOut();
  }

  goBack() {
    var slugType = this.state.selectedSlugType;
    var slugValue = this.state.selectedSlugValue;
    if (slugType === "cat-ftrpro" && slugValue === "slug-ftrpro") {
      this.props.history.push("/");
    } else {
      this.props.history.push("/products/" + slugType + "/" + slugValue);
    }
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>{stripslashes(item.pro_tag_name)}</li>
    ));
  }
  productDetailsMain() {
    var proDetStatus = this.props.productdetailstatus;
    var proDetails = this.props.productdetail;
    var addonProlist = this.props.addonproductlist;
    var product_gallery_image_source = this.props.productcommon
      .product_gallery_image_source;

    var proGallery = {
      customPaging: function(index) {
        if (proDetails[0].image_gallery.length > 0) {
          return (
            <a>
              {" "}
              <img
                src={
                  product_gallery_image_source +
                  "/" +
                  proDetails[0].image_gallery[index].pro_gallery_image
                }
                alt="thambnail"
              />
            </a>
          );
        }
      },
      dots: true,
      arrows: false,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    var current = this;

    if (Object.keys(proDetails).length > 0 && proDetStatus === "success") {
      var imageSource = this.props.productcommon.image_source;
      var gallerySource = this.props.productcommon.product_gallery_image_source;

      if (this.props.match.params.proSlug === proDetails[0].product_slug) {
        hideLoader("prodetailinner-main-div", "class");
      }

      setTimeout(function() {
        $("#proIndex-" + proDetails[0].product_primary_id).removeClass(
          "active"
        );
        hideLoader("comboPro-" + proDetails[0].product_slug, "Idtext");
        if (current.state.openProduct === 1) {
          current.setState({ openProduct: 0 });
          if ($("#ProductDetailMdl").is(":visible")) {
            $("html, body").animate(
              { scrollTop: $("#ProductDetailMdl").offset().top - 150 },
              100
            );
          }
        }
      }, 500);

      var desc = "";

      return proDetails.map((data, index) => {
        desc =
          data.product_long_description !== ""
            ? stripslashes(data.product_long_description)
            : data.product_long_description;

        var imageGallery =
          Object.keys(data.image_gallery).length > 0 ? data.image_gallery : [];
        var comboLgth = data.set_menu_component
          ? data.set_menu_component.length
          : 0;
        var ModifLgth = data.modifiers ? data.modifiers.length : 0;
        var isCombo = data.product_type === "2" && comboLgth > 0 ? "Yes" : "No";
        var isModifier =
          data.product_type === "4" && ModifLgth > 0 ? "Yes" : "No";
        var isSimple =
          data.product_type === "1" && comboLgth === 0 && ModifLgth === 0
            ? "Yes"
            : "No";
        return (
          <div key={index}>
            <div
              className="inn-product-popup"
              key={data.product_primary_id}
              id={"product_" + data.product_primary_id}
            >
              {/* product-detail-topmaindiv - start */}
              <div className="product-detail-topmaindiv">
                {/* prodet_bansec div - start */}
                <div className="prodet_bansec">
                  {/* product image div - start */}
                  {Object.keys(imageGallery).length > 0 ? (
                    <div className="inn-product-popup-bg prodet_banleft">
                      {/*<a href="javascript:;" onClick={this.goBack.bind(this)} className="back_arrow" > </a> */}
                      <Slider {...proGallery}>
                        {imageGallery.map((galleryImg, index) => {
                          return (
                            <div key={index}>
                              <img
                                src={
                                  gallerySource +
                                  "/" +
                                  galleryImg.pro_gallery_image
                                }
                                alt="gallery Img"
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  ) : (
                    <div className="inn-product-popup-bg prodet_banleft">
                      <a
                        href="javascript:;"
                        onClick={this.goBack.bind(this)}
                        className="back_arrow"
                      >
                        {" "}
                      </a>
                      <img
                        src={callImage(
                          imageSource,
                          "",
                          800,
                          800,
                          timThumpUrl,
                          "No"
                        )}
                      />
                    </div>
                  )}
                  {/* product image div - end */}

                  {/* prodet_bansec div - end */}

                  {/* productdetail-rightside-maindiv - start */}
                  <div className="prodet_baninfo">
                    {/* product info div - start */}
                    <div className="">
                      <div className="inn_product_hea">
                        <div className="inn_product_review">
                          <div className="inn_product_hea_left">
                            <h3>
                              {" "}
                              {data.product_alias !== ""
                                ? stripslashes(data.product_alias)
                                : stripslashes(data.product_name)}{" "}
                            </h3>
                          </div>
                        </div>

                        {data.product_tag.length > 0 && (
                          <div className="product-tags-list">
                            <ul>{this.showProductTags(data.product_tag)}</ul>
                          </div>
                        )}

                        {Parser(desc)}
                      </div>
                    </div>
                    {/* product info div - end */}

                    {/* combo or modifier top div - start */}
                    <div className="inn_product_row">
                      {isCombo === "Yes" ? this.comboProDetails(data) : ""}
                      {isModifier === "Yes"
                        ? this.modifierProDetails(data)
                        : ""}
                      {isSimple === "Yes" ? this.simpleProDetails(data) : ""}
                    </div>
                    {/* combo or modifier top div - end */}
                  </div>
                  {/* productdetail-rightside-maindiv - end */}
                </div>
                {/* product-detail-topmaindiv - end */}
              </div>
              {/* addon_rewive_product_topmaindiv - start */}
              <div className="addon_rewive_product_topmaindiv">
                {/* addon products main div - start */}
                {Object.keys(addonProlist).length > 0 && (
                  <div className="addon-prolist-topdiv">
                    <div className="addonproinner-topdiv">
                      <div className="addonpro-title-toptext">
                        <span className="text-uppercase">
                          <h3>Would you like to add?</h3>
                        </span>
                      </div>

                      <div className="addon-prolist-inner">
                        <ul className="addonproducts-list-ulmain">
                          {this.addonProductListing()}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {/* addon products main div - end */}
              </div>
              {/* addon_rewive_product_topmaindiv - end */}
            </div>
          </div>
        );
      });
    } else if (
      Object.keys(proDetails).length === 0 &&
      proDetStatus === "failure"
    ) {
      return (
        <div className="product-detail-empty">
          Sorry, Invalid Product Detail.{" "}
        </div>
      );
    } else {
      return "";
    }
  }
  /* show modifier product details */
  simpleProDetails(proDetailArr) {
    var modProductPrice = proDetailArr.product_price;
    var modProductTotalPrice = proDetailArr.product_price;
    return (
      <div>
        <div className="prd_chosen_sub_row">
          <p>
            <sup>S$</sup>
            <span id="id_price_final">{modProductTotalPrice}</span>
          </p>
          <div
            id={"proDtIndex-" + proDetailArr.product_primary_id}
            className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls"
          >
            <div
              className="addcart_row prd_chosen_sub_item_left cart_update_div addcart_done_maindiv"
              style={{ display: "none" }}
            >
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.proQtyAction.bind(
                    this,
                    proDetailArr.product_primary_id,
                    "decr"
                  )}
                >
                  -
                </span>
                <input
                  type="text"
                  value="1"
                  className="proqty_input"
                  readOnly="1"
                />
                <span
                  className="qty_plus"
                  onClick={this.proQtyAction.bind(
                    this,
                    proDetailArr.product_primary_id,
                    "incr"
                  )}
                >
                  +
                </span>
              </div>
            </div>

            <div
              className="prd_chosen_sub_item_right cart_update_div addcart_done_maindiv"
              style={{ display: "none" }}
            >
              <button
                onClick={this.addToCartSimple.bind(this, proDetailArr, "done")}
              >
                Done
              </button>
            </div>

            <div className="prd_chosen_sub_item_right prd_chosen_item_full cart_add_div smiple_product_lk">
              <button
                onClick={this.addToCartSimple.bind(
                  this,
                  proDetailArr,
                  "initial"
                )}
              >
                Add To Cart
              </button>
            </div>

            <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div>
          </div>
        </div>
      </div>
    );
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proDtIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proDtIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event = "") {
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      event.preventDefault();
      $("#proDtIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proDtIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proDtIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proDtIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          showCustomAlert("success", "Great choice! Item added to your cart.");
          this.setState({ cartTriggerFlg: "yes" });
          removePromoCkValue();
          this.handleShowAlertFun(
            "Success",
            "Great choice! Item added to your cart."
          );
          /*showCartLst();*/
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* show modifier product details */
  modifierProDetails(proDetailArr) {
    $(document)
      .find("#special_notes")
      .val("");
    var modProductPrice =
      this.state.modProductPrice !== ""
        ? this.state.modProductPrice
        : proDetailArr.product_price;
    var modProductTotalPrice =
      this.state.modProductTotalPrice !== ""
        ? this.state.modProductTotalPrice
        : proDetailArr.product_price;
    return (
      <div>
        <div className="prd_chosen_row mdf_common_rows">
          <div id="modErrorDiv" className="modfr-alert-danger single-danger">
            {" "}
            Product combination is wrong. Please check your combination{" "}
          </div>

          <div className="product_chosen_inner">
            <input
              type="hidden"
              id="modProductSlug"
              name="modProductSlug"
              value={proDetailArr.product_slug}
            />
            <input
              type="hidden"
              id="modProductId"
              name="modProductId"
              value={proDetailArr.product_id}
            />
            <input
              type="hidden"
              id="modProductPrice"
              name="modProductPrice"
              value={modProductPrice}
            />
            <input
              type="hidden"
              id="modParentProductId"
              name="modParentProductId"
              value={this.state.modParentProductId}
            />
            <input
              type="hidden"
              id="modProductTotalPrice"
              name="modProductTotalPrice"
              value={modProductTotalPrice}
            />

            <ul className="nav nav-tabs text-center">
              <li className="active">
                <a
                  data-toggle="tab"
                  href="#tab-id-proinn3"
                  aria-expanded="true"
                >
                  {" "}
                  <span>Update Your Product</span>{" "}
                </a>
              </li>
              <li className="">
                <a
                  data-toggle="tab"
                  href="#tab-id-proinn4"
                  aria-expanded="false"
                >
                  {" "}
                  <span>Product Descriptions</span>{" "}
                </a>
              </li>
            </ul>

            {/* tab-content start */}
            <div className="tab-content">
              <div id="tab-id-proinn3" className="tab-pane fade active in">
                <div className="product_chosen_col">
                  <div className="product_chosen_col_inner">
                    {this.modifierProOptions(proDetailArr)}
                  </div>
                </div>
              </div>
              <div id="tab-id-proinn4" className="tab-pane fade">
                <div className="product_chosen_col product_chosen_col_right">
                  <div className="text-box">
                    <textarea
                      placeholder="You can enter your special remark in the section..."
                      name="special_notes"
                      id="special_notes"
                      maxLength="30"
                      value={this.state.product_remarks}
                      onChange={this.updateRemarks.bind(this)}
                    ></textarea>
                    <em>{this.state.remaining} Characters remaining</em>
                  </div>
                </div>
              </div>
            </div>
            {/* tab-content end */}
          </div>
        </div>

        <div className="prd_chosen_sub_row">
          <p>
            <sup>S$</sup>
            <span id="id_price_final">{modProductTotalPrice}</span>
          </p>
          <div className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls">
            <div
              className="addcart_row prd_chosen_sub_item_left cart_update_div mdf_update_div"
              style={{ display: "none" }}
            >
              <p className="prt-sct">Select Quantity</p>
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.mfdrQtyAction.bind(this, "decr")}
                >
                  -
                </span>
                <input
                  type="text"
                  value={this.state.mdfinput_value}
                  className="modfir_proqty_input"
                  readOnly="1"
                />
                <span
                  className="qty_plus"
                  onClick={this.mfdrQtyAction.bind(this, "incr")}
                >
                  +
                </span>
              </div>
            </div>

            <div
              className="prd_chosen_sub_item_right cart_update_div mdf_update_div"
              style={{ display: "none" }}
            >
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "done"
                )}
              >
                Done
              </button>
            </div>

            <div className="prd_chosen_sub_item_right prd_chosen_item_full cart_add_div mdf_add_div">
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "initial"
                )}
              >
                Add To Cart
              </button>
            </div>

            <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div>
          </div>
        </div>
      </div>
    );
  }

  /* show modifier product option */
  modifierProOptions(mdfproducts) {
    var modifiersArr =
      mdfproducts.modifiers !== null && mdfproducts.modifiers !== ""
        ? mdfproducts.modifiers
        : Array();

    if (modifiersArr.length > 0) {
      const html = modifiersArr.map((item, index) => (
        <div
          className="product_chosen_item_left product_chosen_item_left_full"
          key={index}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item.pro_modifier_name)}</h6>
              <span></span>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_" + index}>
              {this.modifierValuesOpn(item)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  modifierValuesOpn(mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function(index, item) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          stripslashes(item.pro_modifier_value_name) +
          "~" +
          item.pro_modifier_value_id +
          "~" +
          item.pro_modifier_value_price;

        var incPrc =
          parseFloat(item.pro_modifier_value_price) > 0
            ? " ( + $" + item.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item.pro_modifier_value_id +
          "' data-mdfvl='" +
          datamdfVl +
          "'>" +
          item.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal = this.state["modifier~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="modifier_main"
          value={mdfSelectVl}
          className="modifierList"
          onChange={this.handleChange.bind(this, mainMdfId)}
          id={"modif" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  handleChange(mdfVl, event) {
    $("#modErrorDiv").hide();
    this.setState({
      mdfApi_call: "Yes",
      ["modifier~~" + mdfVl]: event.target.value,
    });
  }

  mfdrQtyAction(actionFlg) {
    var proqtyInput = $(".modfir_proqty_input").val();
    var modProductPrice = $("#modProductPrice").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    var productTotalPrice =
      parseFloat(modProductPrice) * parseFloat(proqtyInput);
    productTotalPrice = parseFloat(productTotalPrice).toFixed(2);

    /*$('.modfir_proqty_input').val(proqtyInput);*/
    this.setState({
      mdfApi_call: "No",
      mdfinput_value: proqtyInput,
      modProductTotalPrice: productTotalPrice,
    });
  }

  updateRemarks(event) {
    this.setState({ mdfApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  addToCartModifier(mdfProdDetail, actionFlg) {
    if (actionFlg === "initial") {
      $(".mdf_add_div").hide();
      $(".mdf_update_div").show();
      return false;
    } else {
      var modCount = $(".modifierList").length;
      $("#modErrorDiv").hide();
      var modifier = [];
      if (modCount > 0) {
        var errorChk = 0;
        $(".modifierList").each(function() {
          var modVal = $(this).val();
          var modSelectVal = $(this)
            .find("option:selected")
            .attr("data-mdfvl");
          if (modVal != "" && modSelectVal != "") {
            var modifier_sets = modSelectVal.split("~");
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id: modifier_sets[3],
              modifier_value_qty: "1",
              modifier_value_name: modifier_sets[2],
              modifier_value_price: modifier_sets[4],
            });
            modifier.push({
              modifier_id: modifier_sets[0],
              modifier_name: modifier_sets[1],
              modifiers_values: modifierVal,
            });
          } else if (modSelectVal == "" || modVal == "") {
            errorChk = 1;
          }
        });

        if (errorChk == 1) {
          $(".mdfcart_error_msg").html("Sorry!. Product Detail was not valid.");
          $(".mdfcart_error_msg").show();
          $(".mdfcart_error_msg")
            .delay(6000)
            .fadeOut();
          return false;
        } else if (errorChk == 0) {
          showLoader("modfir_addcart_cls", "class");

          var availabilityId = cookie.load("defaultAvilablityId");
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");
          /*var availabilityId = deliveryId;*/
          var availabilityName =
            availabilityId === deliveryId ? "Delivery" : "Pickup";

          var productRemarks = this.state.product_remarks;
          var prodcutQty = this.state.mdfinput_value;
          var modParentId = this.state.modParentProductId;
          var modProductId = $("#modProductId").val();

          var postObject = {};
          postObject = {
            app_id: appId,
            product_id: modProductId,
            product_modifier_parent_id: modParentId,
            availability_id: availabilityId,
            availability_name: availabilityName,
            product_qty: prodcutQty,
            product_type: 4,
            modifiers: JSON.stringify(modifier),
            menu_set_component: "",
            product_remarks: productRemarks,
            reference_id: customerId === "" ? getReferenceID() : "",
            customer_id: customerId,
          };

          Axios.post(
            apiUrlV2 + "cart/simpleCartInsert",
            qs.stringify(postObject)
          ).then((res) => {
            hideLoader("modfir_addcart_cls", "class");
            if (res.data.status === "ok") {
              $(".mdf_update_div").hide();
              $(".mdf_add_div").show();
              showCustomAlert(
                "success",
                "Great choice! Item added to your cart."
              );
              $(".mdfcart_success_msg").html(
                "Successfully! Item added to your cart."
              );
              $(".mdfcart_success_msg").show();
              $(".mdfcart_success_msg")
                .delay(6000)
                .fadeOut();
              this.setState({ cartTriggerFlg: "yes" });
              /*showCartLst();*/
              removePromoCkValue();
              this.handleShowAlertFun(
                "Success",
                "Great choice! Item added to your cart."
              );
              return false;
            } else if (res.data.status === "error") {
              var errMsgtxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Sorry!. Product Detail was not valid.";
              $(".mdfcart_error_msg").html(errMsgtxt);
              $(".mdfcart_error_msg").show();
              $(".mdfcart_error_msg")
                .delay(6000)
                .fadeOut();
            }
          });
        }
      } else {
        $(".mdfcart_error_msg").html("Sorry!. Product Detail was not valid.");
        $(".mdfcart_error_msg").show();
        $(".mdfcart_error_msg")
          .delay(6000)
          .fadeOut();
        return false;
      }
    }
  }

  /* show combo product Details */
  comboProDetails(proDetailArr) {
    var comboArr =
      proDetailArr.set_menu_component !== null &&
      proDetailArr.set_menu_component !== ""
        ? proDetailArr.set_menu_component
        : Array();
    if (Object.keys(comboArr).length > 0) {
      var minMaxSelect = parseInt(proDetailArr.product_apply_minmax_select);
      var compoTotalPrice =
        proDetailArr.product_specialprice_applicable === "yes"
          ? proDetailArr.product_special_price
          : proDetailArr.product_price;
      return (
        <div>
          <div className="prd_chosen_row compo_top_div">
            <div className="product_chosen_inner">
              <input
                type="hidden"
                id="set_menu_component_type"
                name="set_menu_component_type"
                value={minMaxSelect}
              />
              <input
                type="hidden"
                id="product_unitprice"
                name="product_unitprice"
                value={compoTotalPrice}
              />
              <input
                type="hidden"
                id="incr_compo_price"
                name="incr_compo_price"
                value={this.state.incrCompoPrice}
              />

              <ul className="nav nav-tabs text-center">
                <li className="active">
                  <a
                    data-toggle="tab"
                    href="#tab-id-proinn1"
                    aria-expanded="true"
                  >
                    {" "}
                    <span>Update Your Product</span>{" "}
                  </a>
                </li>
                <li className="">
                  <a
                    data-toggle="tab"
                    href="#tab-id-proinn2"
                    aria-expanded="false"
                  >
                    {" "}
                    <span>Product Descriptions</span>{" "}
                  </a>
                </li>
              </ul>

              {/* tab-content start */}
              <div className="tab-content">
                <div id="tab-id-proinn1" className="tab-pane fade active in">
                  <div className="product_chosen_col common_compo_div">
                    {minMaxSelect === 0 && this.singleComboDet(comboArr)}
                    {minMaxSelect === 1 && this.multipleComboDet(comboArr)}
                  </div>
                </div>
                <div id="tab-id-proinn2" className="tab-pane fade">
                  <div className="product_chosen_col product_chosen_col_right">
                    <div className="text-box">
                      <textarea
                        placeholder="You can enter your special remark in the section..."
                        name="special_notes"
                        id="special_notes"
                        maxLength="30"
                        value={this.state.product_remarks}
                        onChange={this.updateRemarksCompo.bind(this)}
                      ></textarea>
                      <em>{this.state.remaining} Characters remaining</em>
                    </div>
                  </div>
                </div>
              </div>
              {/* tab-content end */}
            </div>
          </div>

          <div className="prd_chosen_sub_row">
            <p>
              <sup>S$</sup>
              <span id="compoprice_final">{compoTotalPrice}</span>
            </p>
            <div className="prd_chosen_sub_col popup_addcart_cls compo_addcart_cls">
              <div
                className="addcart_row prd_chosen_sub_item_left cart_update_div compo_update_div"
                style={{ display: "none" }}
              >
                <p className="prt-sct">Select Quantity</p>
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.compoQtyAction.bind(this, "decr")}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={this.state.compoinput_value}
                    className="compo_proqty_input"
                    readOnly="1"
                  />
                  <span
                    className="qty_plus"
                    onClick={this.compoQtyAction.bind(this, "incr")}
                  >
                    +
                  </span>
                </div>
              </div>

              <div
                className="prd_chosen_sub_item_right cart_update_div compo_update_div"
                style={{ display: "none" }}
              >
                <button
                  onClick={this.addToCartCombo.bind(this, proDetailArr, "done")}
                >
                  Done
                </button>
              </div>

              <div className="prd_chosen_sub_item_right prd_chosen_item_full cart_add_div compo_add_div">
                <button
                  onClick={this.addToCartCombo.bind(
                    this,
                    proDetailArr,
                    "initial"
                  )}
                >
                  Add To Cart
                </button>
              </div>

              <div className="cart-success-msg alert alert_success compocart_success_msg"></div>
              <div className="cart-error-msg alert alert_danger compocart_error_msg"></div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  singleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div compo_acc_active maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~0"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          key={"compo" + index1}
        >
          <div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>

          <div className="compo_acc_innerdiv">
            <div className="form-group custom-select-bxcls">
              {this.showSingleComboOptions(item)}
            </div>

            <div
              className="mdfr_list_divlcs"
              data-mismatchpro=""
              data-invcomboprice=""
            >
              <div
                className="mdfr_list_divlcs_error"
                style={{ display: "none", color: "red" }}
              >
                Please choose valid modifiers
              </div>
              {this.showSingleComboMdfr(item)}
            </div>
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  /* show single combo product option - radio btn */
  showSingleComboOptions(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);
      var compSelectBoxHtml = "";
      var mdfMainComboProId = "";
      var ismdfrProCount = 0;
      var comboPropriceAply = "";
      var comboProPrice = 0;
      var indvlComponentNm = "";
      var showPricetxt = "";
      var compomainselval = "";

      var statMdfVal = this.state["compo~~" + menuCmpId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultSelectVl;

      compSelectBoxHtml = compoListArr.map((item1, index2) => {
        mdfMainComboProId = item1[0].product_id;
        ismdfrProCount =
          item1[0].modifiers !== null && item1[0].modifiers !== ""
            ? Object.keys(item1[0].modifiers).length
            : 0;
        comboProPrice = item1[0].product_price;
        comboPropriceAply =
          parseFloat(comboProPrice) > 0 &&
          parseInt(menuComponentApplyPrice) === 1 &&
          parseInt(ismdfrProCount) === 0
            ? comboProPrice
            : 0;
        indvlComponentNm =
          item1[0].product_alias !== ""
            ? stripslashes(item1[0].product_alias)
            : stripslashes(item1[0].product_name);

        showPricetxt =
          parseFloat(comboPropriceAply) > 0
            ? " ( + $" + parseFloat(comboPropriceAply).toFixed(2) + " )"
            : "";

        compomainselval =
          item1[0].product_id +
          "~" +
          indvlComponentNm +
          "~" +
          item1[0].product_sku +
          "~" +
          comboProPrice;

        return (
          <div className="custom_radio" key={"cmbrido-" + index2}>
            <input
              type="radio"
              name={"combointprochk_" + menuCmpId}
              value={item1[0].product_id}
              className="combointprochk_cls"
              data-compomainselval={compomainselval}
              data-mdfcombopro={mdfMainComboProId}
              data-combopropriceaply={comboPropriceAply}
              data-ismdfrprochk={ismdfrProCount}
              checked={this.CheckDefltComboPro(
                item1[0].product_id,
                mdfSelectVl
              )}
              onChange={this.handleChangeCompoMain.bind(this, menuCmpId)}
            />
            <span>{indvlComponentNm + showPricetxt}</span>
          </div>
        );
      });

      return (
        <div
          className="components_selct components_selctbox_cls"
          id={"cmpp" + menuCmpId}
        >
          {compSelectBoxHtml}
        </div>
      );
    } else {
      return "";
    }
  }

  CheckDefltComboPro(proId, dfltPro) {
    var chkRadioBtn = proId === dfltPro ? true : false;
    return chkRadioBtn;
  }

  /* show single combo product option */
  showSingleComboOptions_sltBx(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);
      var compSelectBoxHtml = "";
      var mdfMainComboProId = "";
      var ismdfrProCount = 0;
      var comboPropriceAply = "";
      var comboProPrice = 0;
      var indvlComponentNm = "";
      var showPricetxt = "";
      var compomainselval = "";

      $.each(compoListArr, function(index2, item1) {
        mdfMainComboProId = item1[0].product_id;
        ismdfrProCount =
          item1[0].modifiers !== null && item1[0].modifiers !== ""
            ? Object.keys(item1[0].modifiers).length
            : 0;
        comboProPrice = item1[0].product_price;
        comboPropriceAply =
          parseFloat(comboProPrice) > 0 &&
          parseInt(menuComponentApplyPrice) === 1 &&
          parseInt(ismdfrProCount) === 0
            ? comboProPrice
            : 0;
        indvlComponentNm =
          item1[0].product_alias !== ""
            ? stripslashes(item1[0].product_alias)
            : stripslashes(item1[0].product_name);

        showPricetxt =
          parseFloat(comboPropriceAply) > 0
            ? " ( + $" + parseFloat(comboPropriceAply).toFixed(2) + " )"
            : "";

        compomainselval =
          item1[0].product_id +
          "~" +
          indvlComponentNm +
          "~" +
          item1[0].product_sku +
          "~" +
          comboProPrice;

        compSelectBoxHtml +=
          "<option value='" +
          item1[0].product_id +
          "' data-compomainselval='" +
          compomainselval +
          "' data-mdfcombopro='" +
          mdfMainComboProId +
          "' data-combopropriceaply='" +
          comboPropriceAply +
          "' data-ismdfrprochk='" +
          ismdfrProCount +
          "' >" +
          indvlComponentNm +
          showPricetxt +
          "</option>";
      });

      var compSelectDropDown = Parser(compSelectBoxHtml);
      var statMdfVal = this.state["compo~~" + menuCmpId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultSelectVl;

      return (
        <select
          name="compomain_select"
          value={mdfSelectVl}
          className="components_selct components_selctbox_cls"
          onChange={this.handleChangeCompoMain.bind(this, menuCmpId)}
          id={"cmpp" + menuCmpId}
        >
          {compSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  showSingleComboMdfr(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);

      const html = compoListArr.map((item1, index2) => (
        <div className="mdfr_list_divlcs_inv" key={"cmdf-" + index2}>
          {item1[0].modifiers !== null &&
            item1[0].modifiers !== "" &&
            this.singleComboMdfrOption(
              menuCmpId,
              item1[0].product_id,
              item1[0].modifiers
            )}
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrOption(menuCmpIdTxt, productIdTxt, cmpProMdfr) {
    if (Object.keys(cmpProMdfr).length > 0) {
      const html = cmpProMdfr.map((item2, index3) => (
        <div
          className={
            "compoMdfr_item_left individual_combo_mdf compo_mdf_" + productIdTxt
          }
          key={"cmd-" + index3}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item2.pro_modifier_name)}</h6>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_cmd" + index3}>
              {this.singleComboMdfrValuesOpn(menuCmpIdTxt, item2)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrValuesOpn(menuCmpIdTxt, mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function(index4, item3) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          item3.pro_modifier_value_id +
          "~" +
          stripslashes(item3.pro_modifier_value_name) +
          "~" +
          item3.pro_modifier_value_price;

        var incPrc =
          parseFloat(item3.pro_modifier_value_price) > 0
            ? " ( + $" + item3.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item3.pro_modifier_value_id +
          "' data-selectmdfval='" +
          datamdfVl +
          "' data-mdfrpricevaluetxt='" +
          item3.pro_modifier_value_price +
          "' >" +
          item3.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item3.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item3.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal = this.state[
        "compoInner~~" + menuCmpIdTxt + "~~" + mainMdfId
      ];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="compoinner_select"
          value={mdfSelectVl}
          className="components_mdf_selct"
          onChange={this.handleChangeCompoInner.bind(
            this,
            menuCmpIdTxt,
            mainMdfId
          )}
          id={"modifvl" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  multipleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div minmax_maincombo_div compo_acc_active maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~1"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          data-minselectcombo={item.menu_component_min_select}
          data-maxselectcombo={item.menu_component_max_select}
          data-modifierapply={item.menu_component_modifier_apply}
          key={"compo" + index1}
        >
          <div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>

          <div className="compo_acc_innerdiv">
            {item.menu_component_modifier_apply === "1" ? (
              <div className="compo_mdfselect_maindiv">
                <div className="form-group custom-radio-btncls">
                  {this.showSingleComboOptions(item)}
                </div>

                <div
                  className="mdfr_list_divlcs"
                  data-mismatchpro=""
                  data-invcomboprice=""
                >
                  <div
                    className="mdfr_list_divlcs_error"
                    style={{ display: "none", color: "red" }}
                  >
                    Please choose valid modifiers
                  </div>
                  {this.showSingleComboMdfr(item)}
                </div>
              </div>
            ) : (
              <div className="compo_minmax_maindiv">
                <div className="max-min-bar">
                  {"You've chosen"} <span className="minSelectCls">0</span>{" "}
                  (Min. {item.menu_component_min_select} & Max.{" "}
                  {item.menu_component_max_select}){" "}
                </div>

                <div className="error_combo_div">
                  {" "}
                  Please select the min number of items.{" "}
                </div>

                {this.showMultipleComboOptions(item)}
              </div>
            )}
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  showComboMultiSelect(mutilSlct) {
    return mutilSlct === 1 ? "none" : "";
  }

  /* show multiple combo product option */
  showMultipleComboOptions(splProducts) {
    var multiSelectApply =
      splProducts.menu_component_multipleselection_apply !== ""
        ? parseInt(splProducts.menu_component_multipleselection_apply)
        : 0;
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;

      const compoMtplHtml = compoListArr.map((item1, index1) => (
        <div
          className={
            "chosen_adn mdfr_list_divlcs individual_combo_pro indvcombo-" +
            menuCmpId +
            "-" +
            item1[0].product_primary_id
          }
          data-mismatchpro=""
          data-invcomboprice="0"
          data-comboprice={item1[0].product_price}
          data-productdata={this.getCmpProData(item1)}
          key={index1}
        >
          <div className="bb-txt2 margin-15 chosen_adn_left">
            <span>
              {item1[0].product_alias !== ""
                ? stripslashes(item1[0].product_alias)
                : stripslashes(item1[0].product_name)}
            </span>{" "}
            <span className="combo_pro_price">
              {parseFloat(item1[0].product_price) > 0
                ? " ( +" + item1[0].product_price + " )"
                : ""}
            </span>
          </div>
          <div
            className="radio pull-right combo-inc-parent chosen_adn_right"
            style={{ display: this.showComboMultiSelect(multiSelectApply) }}
          >
            <div className="qty_bx">
              <span
                className="qty_minus combo_inc"
                onClick={this.decComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                -
              </span>

              <label
                type="text"
                disabled
                data-qtyval="0"
                className="combo-input-label combo-input combolst_qty_value"
              >
                0
              </label>

              <span
                className="qty_plus combo_dec"
                onClick={this.incComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                +
              </span>
            </div>
          </div>

          {multiSelectApply === 1 && (
            <div className="checkboxcls pull-right">
              <input
                className="css-checkboxcls"
                type="checkbox"
                onChange={this.comboMultiSelectUpdate.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
                value={item1[0].product_price}
                name={"comboMultiStVal_" + menuCmpId}
                id={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
              />{" "}
              <label
                htmlFor={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
                className="css-label-chkbox"
              ></label>
            </div>
          )}
        </div>
      ));

      return <div>{compoMtplHtml}</div>;
    } else {
      return "";
    }
  }

  getCmpProData(proData) {
    var proName =
      proData[0].product_alias !== ""
        ? stripslashes(proData[0].product_alias)
        : stripslashes(proData[0].product_name);
    var pro_datetxt =
      proData[0].product_id +
      "~" +
      proName +
      "~" +
      proData[0].product_sku +
      "~" +
      proData[0].product_price;
    return pro_datetxt;
  }

  comboMultiSelectUpdate(menuCmpId, productPryId) {
    if (
      $("#comboMultiStVal_" + menuCmpId + "_" + productPryId).prop("checked") ==
      true
    ) {
      this.incComboQty(menuCmpId, productPryId, "checkboxact");
    } else {
      this.decComboQty(menuCmpId, productPryId);
    }
  }

  incComboQty(menuCmpId, proId, actionFrm) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";
    var invCompoQty = this.getInvCompoQty($_this);

    if (
      actionFrm === "checkboxact" &&
      parseInt(invCompoQty) >= parseInt(maxselectcombo)
    ) {
      $("#comboMultiStVal_" + menuCmpId + "_" + proId).prop("checked", false);
      return false;
    }

    var chk_val = 0;
    if (!isNaN(intValInc) && parseInt(invCompoQty) < parseInt(maxselectcombo)) {
      intValInc = parseInt(intValInc + 1);
      chk_val = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);

    var comboProPrice = $_this.attr("data-invcomboprice");
    var invCompoQty = this.getInvCompoQty($_this);
    if (
      (invCompoQty > parseInt(minselectcombo) ||
        parseInt(combopriceapply) == 1) &&
      chk_val == 1
    ) {
      $_this.find(".combo_pro_price").show();
      var invComboproPrice = $_this.attr("data-invcomboprice");
      var comboprice = $_this.attr("data-comboprice");
      comboProPrice = parseFloat(invComboproPrice) + parseFloat(comboprice);
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }
    $_this.attr("data-invcomboprice", comboProPrice);
    this.updateProductPricefun();
  }

  decComboQty(menuCmpId, proId) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";

    var minusChkVal = 0;
    if (!isNaN(intValInc) && parseInt(intValInc) >= 1) {
      var intValInc = parseInt(intValInc - 1);
      minusChkVal = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);
    var invCompoQty = this.getInvCompoQty($_this);
    var comboProPrice = $_this.attr("data-invcomboprice");
    var defComboprice = $_this.attr("data-comboprice");
    if (
      (invCompoQty >= parseInt(minselectcombo) && minusChkVal == 1) ||
      (parseInt(combopriceapply) == 1 && minusChkVal == 1)
    ) {
      if (
        parseInt(combopriceapply) == 1 &&
        minusChkVal == 1 &&
        parseFloat(defComboprice) == 0 &&
        parseFloat(comboProPrice) == 0
      ) {
        var temp_price = 0;
        $_this.attr("data-invcomboprice", temp_price);
        $_this.find(".combo_pro_price").hide();
      } else if (
        parseFloat(comboProPrice) >= parseFloat(defComboprice) &&
        parseFloat(comboProPrice) > 0
      ) {
        var temp_price = parseFloat(comboProPrice) - parseFloat(defComboprice);
        $_this.attr("data-invcomboprice", temp_price);
        if (parseFloat(temp_price) == 0) {
          $_this.find(".combo_pro_price").hide();
        }
      } else {
        var rtn_val = 0;
        $_this
          .closest(".main_combo_div")
          .find(".individual_combo_pro")
          .each(function() {
            var thisInvPrc = $(this).attr("data-invcomboprice");
            if (parseFloat(thisInvPrc) > 0 && rtn_val == 0) {
              rtn_val = 1;
              var comboproprice = thisInvPrc;
              var def_combo_price = $(this).attr("data-comboprice");
              var tempPrice =
                parseFloat(comboproprice) - parseFloat(def_combo_price);
              $(this).attr("data-invcomboprice", tempPrice);
              if (parseFloat(tempPrice) == 0) {
                $(this)
                  .find(".combo_pro_price")
                  .hide();
              }
            }
          });
      }
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }

    this.updateProductPricefun();
  }

  getInvCompoQty($_this) {
    $_this
      .closest(".main_combo_div")
      .find(".error_combo_div")
      .hide();
    var combolst_qty = 0;
    $_this
      .closest(".main_combo_div")
      .find(".combolst_qty_value")
      .each(function() {
        combolst_qty += parseInt($(this).attr("data-qtyval"));
      });
    return combolst_qty;
  }

  updateRemarksCompo(event) {
    this.setState({ compoApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  updateProductPricefun() {
    var minmaxMainCnt = $(".compo_minmax_maindiv").length;
    if (minmaxMainCnt > 0) {
      $(".compo_minmax_maindiv").each(function(indx) {
        var invQtyCnt = 0;
        $(this)
          .find(".individual_combo_pro")
          .each(function(indx2) {
            var qtyval = $(this)
              .find(".combolst_qty_value")
              .attr("data-qtyval");
            invQtyCnt = parseInt(invQtyCnt) + parseInt(qtyval);
          });
        $(this)
          .find(".minSelectCls")
          .html(invQtyCnt);
      });
    }

    var combo_pro_price = 0;
    $(".mdfr_list_divlcs").each(function() {
      var invcomboPriceVl = $(this).attr("data-invcomboprice");
      invcomboPriceVl = invcomboPriceVl !== "" ? invcomboPriceVl : 0;
      combo_pro_price += parseFloat(invcomboPriceVl);
    });

    var qty_txt =
      $(".compo_proqty_input").val() != ""
        ? parseInt($(".compo_proqty_input").val())
        : 0;
    var pro_price_val =
      $("#product_unitprice").val() != ""
        ? parseFloat($("#product_unitprice").val())
        : 0;

    var exc_price = parseFloat(pro_price_val) + parseFloat(combo_pro_price);
    exc_price = parseInt(qty_txt) * parseFloat(exc_price);

    $("#incr_compo_price").val(exc_price);
    $("#compoprice_final").html(parseFloat(exc_price).toFixed(2));
  }

  compoQtyAction(actionFlg) {
    var proqtyInput = $(".compo_proqty_input").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    this.setState({ compoApi_call: "No", compoinput_value: proqtyInput });
  }

  handleChangeCompoMain(compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compo~~" + compoVl]: event.target.value,
    });
  }

  handleChangeCompoInner(menuCmpIdTxt, compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compoInner~~" + menuCmpIdTxt + "~~" + compoVl]: event.target.value,
    });
  }

  getComboproData($_this, CompoType) {
    var comboproSet = Array(),
      comboproMdf = Array(),
      aplypriceZero = 0;

    if (CompoType === 1) {
      $_this.find(".individual_combo_pro").each(function() {
        var componentsProDet = $(this).attr("data-productdata");
        var compoProDet = componentsProDet.split("~");
        var combolstQtyValue = $(this)
          .find(".combolst_qty_value")
          .attr("data-qtyval");
        var comboProInvPrice = $(this).attr("data-invcomboprice");
        comboproSet.push({
          product_id: compoProDet[0],
          product_name: compoProDet[1],
          product_sku: compoProDet[2],
          product_price: comboProInvPrice,
          product_qty: combolstQtyValue,
          modifiers: comboproMdf,
        });
      });
    } else {
      $_this.find(".components_selct").each(function() {
        /*var mdfcombopro_id = $(this).find(':selected').attr('data-mdfcombopro');*/
        var mdfcombopro_id = $(this)
          .find(":checked")
          .attr("data-mdfcombopro");
        var combopriceapplychk = $(this)
          .closest(".main_combo_div")
          .attr("data-combopriceapply");
        /*var aplyprice_temp = $(this).find(':selected').attr('data-combopropriceaply');*/
        var aplyprice_temp = $(this)
          .find(":checked")
          .attr("data-combopropriceaply");
        aplyprice_temp = aplyprice_temp !== undefined ? aplyprice_temp : 0;
        var comboMdfSet = Array();
        $(this)
          .closest(".main_combo_div")
          .find(".compo_mdf_" + mdfcombopro_id)
          .each(function() {
            var combopro_mdf_txt = $(this)
              .find(".components_mdf_selct")
              .val();
            var comboMdfValueSet = Array();
            if (combopro_mdf_txt !== "") {
              var modifierCombosets_txt = $(this)
                .find(".components_mdf_selct")
                .find("option:selected")
                .attr("data-selectmdfval");
              var mdfSetDet =
                modifierCombosets_txt !== "" &&
                modifierCombosets_txt !== undefined
                  ? modifierCombosets_txt.split("~")
                  : Array();

              if (Object.keys(mdfSetDet).length > 0) {
                comboMdfValueSet.push({
                  modifier_value_name: mdfSetDet[3],
                  modifier_value_id: mdfSetDet[2],
                  modifier_value_price: mdfSetDet[4],
                  modifier_value_qty: 1,
                });
                comboMdfSet.push({
                  modifier_name: mdfSetDet[1],
                  modifier_id: mdfSetDet[0],
                  modifiers_values: comboMdfValueSet,
                });
              }
            }
          });

        /*var componentsProDet = $(this).find('option:selected').attr("data-compomainselval");*/
        var componentsProDet = $(this)
          .find(":checked")
          .attr("data-compomainselval");
        var compoProDet =
          componentsProDet !== undefined
            ? componentsProDet.split("~")
            : Array();
        if (Object.keys(compoProDet).length > 0) {
          var combolstQtyValue = 1;
          var comboProInvPrice =
            parseFloat(combopriceapplychk) > 0
              ? parseFloat(aplyprice_temp)
              : parseFloat(aplypriceZero);
          comboproSet.push({
            product_id: compoProDet[0],
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboMdfSet,
          });
        }
      });
    }

    return comboproSet;
  }

  checkMinValfun($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo =
      $_this.attr("data-minselectcombo") != ""
        ? $_this.attr("data-minselectcombo")
        : "0";
    $_this.find(".combolst_qty_value").each(function() {
      var qtyval = $(this).attr("data-qtyval");
      combo_qtycount += parseInt(qtyval);
    });
    if (parseInt(min_selectcombo) > parseInt(combo_qtycount)) {
      min_val_error = 1;
    }
    return min_val_error;
  }

  addToCartCombo(compoProdDetail, actionFlg) {
    var InvalidMdfrCompo = "No";
    $(".main_combo_div").each(function() {
      if (
        $(this)
          .find(".mdfr_list_divlcs")
          .attr("data-mismatchpro") == "1"
      ) {
        InvalidMdfrCompo = "Yes";
      }
    });

    if (InvalidMdfrCompo == "No") {
      if (actionFlg === "initial") {
        $(".compo_add_div").hide();
        $(".compo_update_div").show();
        return false;
      } else {
        var $_react_this = this;
        var menuSet = Array();
        var productDetailsMain = Array();
        var modifier = Array();
        var CompoType = $("#set_menu_component_type").val();
        CompoType = parseInt(CompoType);
        var compo_errors = "0";

        if (CompoType === 1) {
          $(".main_combo_div").each(function() {
            var modifierapply = $(this).attr("data-modifierapply");
            if (modifierapply === "1") {
              var combodata_txt = $(this).attr("data-combodata");
              var menu_component = combodata_txt.split("~");
              var productDetails = $_react_this.getComboproData($(this), 0);
              productDetailsMain.push({
                menu_component_id: menu_component[0],
                menu_component_name: menu_component[1],
                product_details: productDetails,
                min_max_flag: menu_component[2],
              });
            } else {
              var check_min_val = $_react_this.checkMinValfun($(this));
              if (check_min_val == 0) {
                var combodata_txt = $(this).attr("data-combodata");
                menu_component = combodata_txt.split("~");
                var productDetails = $_react_this.getComboproData(
                  $(this),
                  CompoType
                );
                productDetailsMain.push({
                  menu_component_id: menu_component[0],
                  menu_component_name: menu_component[1],
                  product_details: productDetails,
                  min_max_flag: menu_component[2],
                });
              } else {
                compo_errors = "1";
                $(this)
                  .find(".error_combo_div")
                  .show();
                return false;
              }
            }
          });
        } else {
          $(".main_combo_div").each(function() {
            var combodata_txt = $(this).attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData(
              $(this),
              CompoType
            );
            productDetailsMain.push({
              menu_component_id: menu_component[0],
              menu_component_name: menu_component[1],
              product_details: productDetails,
              min_max_flag: menu_component[2],
            });
          });
        }
        menuSet = productDetailsMain;

        var specialprice_applicable =
          compoProdDetail.product_specialprice_applicable;
        var product_actual_price =
          compoProdDetail.product_specialprice_applicable == "yes"
            ? compoProdDetail.product_special_price
            : compoProdDetail.product_price;
        var unitprice_inputval =
          $("#product_unitprice").val() != ""
            ? $("#product_unitprice").val()
            : 0;
        if (
          specialprice_applicable === "yes" &&
          parseFloat(product_actual_price) !== parseFloat(unitprice_inputval)
        ) {
          compo_errors = "1";
        }

        if (compo_errors == "0" && Object.keys(menuSet).length > 0) {
          showLoader("compo_addcart_cls", "class");
          var prCommon = this.props.productcommon;

          var productId = compoProdDetail.product_id;
          var productName =
            compoProdDetail.product_alias !== ""
              ? stripslashes(compoProdDetail.product_alias)
              : stripslashes(compoProdDetail.product_name);
          var productImage =
            compoProdDetail.product_thumbnail !== ""
              ? prCommon.image_source + "/" + compoProdDetail.product_thumbnail
              : "";
          var productSku = compoProdDetail.product_sku;
          var productSlug = compoProdDetail.product_slug;

          var productRemarks = this.state.product_remarks;
          var prodcutQty = this.state.compoinput_value;
          var incrCompoPrice = $("#incr_compo_price").val();

          var totalCompoPrice =
            incrCompoPrice !== "" ? parseFloat(incrCompoPrice) : 0;
          var unitProductPrice =
            parseFloat(totalCompoPrice) / parseFloat(prodcutQty);
          unitProductPrice = unitProductPrice.toFixed(2);

          var availabilityId = cookie.load("defaultAvilablityId");
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");

          if (
            parseFloat(totalCompoPrice) > 0 &&
            parseFloat(unitProductPrice) > 0
          ) {
            var postObject = {};
            postObject = {
              app_id: appId,
              product_id: productId,
              product_qty: prodcutQty,
              availability_id: availabilityId,
              product_name: productName,
              product_total_price: totalCompoPrice,
              product_unit_price: unitProductPrice,
              product_remarks: productRemarks,
              product_image: productImage,
              product_sku: productSku,
              product_slug: productSlug,
              modifiers: JSON.stringify(modifier),
              menu_set_component: JSON.stringify(menuSet),
              individual: "yes",
              customer_id: customerId,
              reference_id: customerId === "" ? getReferenceID() : "",
              product_edit_enable: "No",
              specialprice_applicable: "yes",
            };

            Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
              (res) => {
                hideLoader("compo_addcart_cls", "class");
                if (res.data.status === "ok") {
                  $(".compo_update_div").hide();
                  $(".compo_add_div").show();

                  showCustomAlert(
                    "success",
                    "Great choice! Item added to your cart."
                  );
                  $(".compocart_success_msg").html(
                    "Successfully! Item added to your cart."
                  );
                  $(".compocart_success_msg").show();
                  $(".compocart_success_msg")
                    .delay(6000)
                    .fadeOut();
                  this.setState({ cartTriggerFlg: "yes" });
                  this.props.sateValChange("cartflg", "yes");
                  /*showCartLst();*/
                  removePromoCkValue();
                  this.handleShowAlertFun(
                    "Success",
                    "Great choice! Item added to your cart."
                  );
                  return false;
                } else if (res.data.status === "error") {
                  var errMsgtxt =
                    res.data.message !== ""
                      ? res.data.message
                      : "Product Detail was not valid.";
                  $(".compocart_error_msg").html(errMsgtxt);
                  $(".compocart_error_msg").show();
                  $(".compocart_error_msg")
                    .delay(6000)
                    .fadeOut();
                }
              }
            );
          } else {
            hideLoader("compo_addcart_cls", "class");
            $(".compocart_error_msg").html(
              "Sorry!. Product price was not valid."
            );
            $(".compocart_error_msg").show();
            $(".compocart_error_msg")
              .delay(6000)
              .fadeOut();
            return false;
          }
        } else {
          $(".compocart_error_msg").html(
            "Sorry!. Product Detail was not valid."
          );
          $(".compocart_error_msg").show();
          $(".compocart_error_msg")
            .delay(6000)
            .fadeOut();
          return false;
        }
      }
    } else {
      $(".compocart_error_msg").html("Sorry!. Invalid product combination.");
      $(".compocart_error_msg").show();
      $(".compocart_error_msg")
        .delay(6000)
        .fadeOut();
      return false;
    }
  }

  checkProductPrice() {
    var allModVal = "";
    var errorChk = 0;
    var productID = $("#modProductId").val();
    var inc_lp = 1;
    var TotalCnt = $(".modifierList").length;

    $(".modfir_addcart_cls").show();
    showLoader("modfir_addcart_cls", "class");

    $(".modifierList").each(function() {
      var modVal = $(this).val();
      var modSelectVal = $(this)
        .find("option:selected")
        .attr("data-mdfvl");
      if (modVal != "" && modSelectVal != "") {
        var modifier_sets = modSelectVal.split("~");
        allModVal +=
          inc_lp == TotalCnt ? modifier_sets[3] : modifier_sets[3] + ";";
      } else if (modSelectVal == "" || modVal == "") {
        errorChk = 1;
      }
      inc_lp++;
    });

    if (errorChk === 0 && allModVal != "") {
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          productID +
          "&modifier_value_id=" +
          allModVal
      ).then((res) => {
        var response = res.data;
        hideLoader("modfir_addcart_cls", "class");
        if (response.status === "ok") {
          var proQty = $(".modfir_proqty_input").val();
          var productPrice = response.result_set[0].product_price;
          var productTotalPrice =
            parseFloat(response.result_set[0].product_price) *
            parseFloat(proQty);
          productTotalPrice = parseFloat(productTotalPrice).toFixed(2);
          /*$("#modParentProductId").val(response.result_set[0].alias_product_primary_id);
					$("#modProductPrice").val(productPrice);
					$("#modProductTotalPrice").val(productTotalPrice);
					$('#id_price_final').html(productTotalPrice);*/
          this.setState({
            mdfApi_call: "No",
            modParentProductId: response.result_set[0].alias_product_primary_id,
            modProductPrice: productPrice,
            modProductTotalPrice: productTotalPrice,
          });
        } else {
          $(".modfir_addcart_cls").hide();
          $("#modErrorDiv").show();
          $("#modErrorDiv")
            .delay(6000)
            .fadeOut();
        }
      });
    }
  }

  setModifierValFun($_this) {
    /*var mdfcombopro = $_this.find(':selected').attr('data-mdfcombopro');*/
    var mdfcombopro = $_this.find(":checked").attr("data-mdfcombopro");
    $_this
      .closest(".main_combo_div")
      .find(".individual_combo_mdf")
      .hide();
    $_this
      .closest(".main_combo_div")
      .find(".compo_mdf_" + mdfcombopro)
      .show();
  }

  setOverallSubmdfrPrice($_this) {
    /*var mainmdfrid = $_this.find(':selected').attr('data-mdfcombopro');
	  var ismdfrprochk = $_this.find(':selected').attr('data-ismdfrprochk');*/

    var mainmdfrid = $_this.find(":checked").attr("data-mdfcombopro");
    mainmdfrid = mainmdfrid !== undefined ? mainmdfrid : "";
    var ismdfrprochk = $_this.find(":checked").attr("data-ismdfrprochk");
    ismdfrprochk = ismdfrprochk !== undefined ? ismdfrprochk : 0;

    var combopriceapplychk = $_this
      .closest(".main_combo_div")
      .attr("data-combopriceapply");
    var maincomboidtxt = $_this
      .closest(".main_combo_div")
      .attr("data-maincomboidtxt");
    var mdfrpricevaluetxt_val = 0,
      inv_comopo_mismatch_pro = "";

    if (parseFloat(ismdfrprochk) > 0) {
      if (this.state.compoApi_call === "Yes") {
        var rtrn_msg = this.checkModifierPricefun(
          $_this,
          maincomboidtxt,
          mainmdfrid
        );
      }
    } else {
      if (parseFloat(combopriceapplychk) > 0) {
        /*var aplyprice_temp = $_this.find(':selected').attr('data-combopropriceaply');*/
        var aplyprice_temp = $_this
          .find(":checked")
          .attr("data-combopropriceaply");
        aplyprice_temp = aplyprice_temp !== undefined ? aplyprice_temp : 0;
        mdfrpricevaluetxt_val = parseFloat(aplyprice_temp);
      }

      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-invcomboprice", mdfrpricevaluetxt_val);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-mismatchpro", inv_comopo_mismatch_pro);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs_error")
        .hide();
    }

    this.checkModifierErrorfun();
  }

  checkModifierPricefun($_this, maincomboidtxt, mdfcombopro_id) {
    var returntxt_msg = "";
    var sub_mdfr_ids = "";
    $(".maincombo-" + maincomboidtxt)
      .find(".compo_mdf_" + mdfcombopro_id)
      .each(function() {
        var modVal = $(this)
          .find(".components_mdf_selct")
          .val();
        if (modVal !== "") {
          var modifier_combosets_txt = $(this)
            .find(".components_mdf_selct")
            .find("option:selected")
            .attr("data-selectmdfval");
          var modifier_combosets =
            modifier_combosets_txt !== "" &&
            modifier_combosets_txt !== undefined
              ? modifier_combosets_txt.split("~")
              : new Array();
          if (modifier_combosets.length >= 2) {
            if (modifier_combosets[2]) {
              if (sub_mdfr_ids != "") {
                sub_mdfr_ids += ";";
              }
              sub_mdfr_ids = sub_mdfr_ids + modifier_combosets[2];
            }
          }
        }
      });

    if (sub_mdfr_ids !== "") {
      showLoader("compo_addcart_cls", "class");
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          mdfcombopro_id +
          "&modifier_value_id=" +
          sub_mdfr_ids
      ).then((res) => {
        var response = res.data;
        if (response.status === "ok") {
          var tempval = "";
          this.updateIndvModifrprice(
            $_this
              .closest(".main_combo_div")
              .find(".compo_mdf_" + mdfcombopro_id + ":first")
          );
        } else {
          var tempval = "1";
        }
        $_this
          .closest(".main_combo_div")
          .find(".mdfr_list_divlcs")
          .attr("data-mismatchpro", tempval);
        this.checkModifierErrorfun();
        hideLoader("compo_addcart_cls", "class");
      });
    }

    return returntxt_msg;
  }

  updateIndvModifrprice($_this) {
    var mdfrpricevaluetxt_val = 0;
    $_this
      .closest(".mdfr_list_divlcs_inv")
      .find(".components_mdf_selct")
      .each(function() {
        var mdfrpricevaluetxt = $(this)
          .find(":selected")
          .attr("data-mdfrpricevaluetxt");
        mdfrpricevaluetxt =
          mdfrpricevaluetxt !== "" && mdfrpricevaluetxt !== undefined
            ? parseFloat(mdfrpricevaluetxt)
            : 0;
        mdfrpricevaluetxt_val =
          parseFloat(mdfrpricevaluetxt_val) + parseFloat(mdfrpricevaluetxt);
      });
    $_this
      .closest(".mdfr_list_divlcs")
      .attr("data-invcomboprice", mdfrpricevaluetxt_val);
  }

  checkModifierErrorfun() {
    var over_allerror = "";
    $(".mdfr_list_divlcs").each(function() {
      if ($(this).attr("data-mismatchpro") == "1") {
        over_allerror = "1";
        $(this)
          .find(".mdfr_list_divlcs_error")
          .show();
      } else {
        $(this)
          .find(".mdfr_list_divlcs_error")
          .hide();
      }
    });

    /*$('#comopo_mismatch_pro').val(over_allerror);*/

    this.updateProductPricefun();

    if (over_allerror == "1") {
      $(".compo_addcart_cls").hide();
    } else {
      $(".compo_addcart_cls").show();
    }
  }

  componentDidUpdate() {
    var TotalCnt = $(".modifierList").length;
    var modProductSlug = $("#modProductSlug").val();
    if (
      TotalCnt > 0 &&
      this.state.mdfApi_call === "Yes" &&
      this.state.selectedProSlug === modProductSlug
    ) {
      this.checkProductPrice();
    }

    var $_reactThis = this;
    var individualComboCnt = $(".individual_combo_mdf").length;
    if (individualComboCnt > 0) {
      $(".main_combo_div").each(function() {
        $_reactThis.setModifierValFun($(this).find(".components_selct"));
      });

      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function() {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }

    var indlMinMxComboCnt = $(".individual_combo_pro").length;
    if (indlMinMxComboCnt > 0) {
      $(".main_combo_div").each(function() {
        var minselectcombo_txt =
          $(this).data("minselectcombo") != ""
            ? $(this).data("minselectcombo")
            : "0";
        var combopriceapply_txt =
          $(this).data("combopriceapply") != ""
            ? $(this).data("combopriceapply")
            : "0";
        if (
          parseInt(minselectcombo_txt) === 0 ||
          parseInt(combopriceapply_txt) === 1
        ) {
          $(this)
            .find(".combo_pro_price")
            .show();
        } else {
          $(this)
            .find(".combo_pro_price")
            .hide();
        }
      });
    }

    var minmaxMainCnt = $(".minmax_maincombo_div").length;
    var chkAplyModfInMinmax = 0;
    if (minmaxMainCnt > 0) {
      chkAplyModfInMinmax = $(".minmax_maincombo_div").find(
        ".components_selctbox_cls"
      ).length;
      this.updateProductPricefun();
    }

    var singleSelectCompo = $(".components_selctbox_cls").length;
    if (
      singleSelectCompo > 0 &&
      ((minmaxMainCnt === 0 &&
        indlMinMxComboCnt === 0 &&
        individualComboCnt === 0) ||
        chkAplyModfInMinmax > 0)
    ) {
      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function() {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };

  render() {
    return <div className="modal-detail-box">{this.productDetailsMain()}</div>;
  }
}
