/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";

import { stripslashes, hideLoader } from "../Helpers/SettingHelper";

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import Parser from "html-react-parser";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GET_REQUESTPAGEDATA} from '../../actions';
import { appId, apiUrl, apiUrlV2, timThumpUrl, webUrl } from "../Helpers/Config";


import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import innerBanner from "../../common/images/page-banner.jpg";
import Axios from 'axios';



class Ourvideos extends Component {

    constructor(props) {
        super(props);
	    this.state = {
	      staticblacks: [],
	      homejournyBlk: "",
	      partnerBlk: "",
	      partnerimgpath: "",
	      partnerimg: "",
	      homejournyimgBlk: [],
	      whatshappenings: []
	    };
    }

  componentDidMount() {
    $(".dvLoadrCls").show();
    	Axios.get(apiUrl + 'cms/whats_happening?our_videos=yes&app_id='+appId).then(res => {

				if (res.data.status === "ok") {
					
					 $('.dvLoadrCls').fadeOut(500);

					let whatshappenings = res.data.result_set;

					this.setState({ whatshappenings:whatshappenings })

				} else if (res.data.status === "error") {
						 $('.dvLoadrCls').fadeOut(500);
				}

				return false;

			});
    setTimeout(function () {
      $(".dvLoadrCls").hide();
    }, 2000);
  }


	  listwhatshappenings() {

		  let whatshappenings = this.state.whatshappenings;
		  let whatshappenings_source = this.state.whatshappenings_source;
		  

		  if(Object.keys(whatshappenings).length > 0) {
			const mainMenu = whatshappenings.map((loaddata, index) => {
				
					return (<div className="container-two" key={'wh'+(index+1)}>
							<div className="events-section">
									{loaddata.gallery_image.map((loaddatagall, gallindex) => {
									return(
										
										<div>
										<p>
										&nbsp;</p>
										<p >
										<strong>{loaddatagall.gallery_title}</strong></p>
										<p >
										&nbsp;</p>
										<p >
										<iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0" height="315" src={loaddatagall.gallery_link} title="YouTube video player" width="560"></iframe></p>
										<p ></p>
										<br></br>
										</div>
										
									  );
								  
									})}

							</div>
						</div>);
				
			});
			
			return mainMenu;
		  } else {
			return null;
		  }



	  }

    render() {

    	$(document).on('click','.img-responsive',function(){
		  var src = $(this).attr('src');

		  $(document).find('.carousel-inner').find('.item').removeClass('active');
		  $(document).find('.carousel-inner').find('img[src$="'+src+'"]').parent('div').addClass('active');
		});

		return (<div>
		
				 <div className=" common-top-div pagesList-main-div">
				    <div className="Pages">
					
					 {/* Header start */}
					 <Header />
					 {/* Header End */}
					 </div>
					 <div className="common-inner-blckdiv">
						<div className="page-banner">
							<img src={innerBanner} />
							<div className="inner-banner-content">
								<h2>Our Videos</h2>
							</div>
						</div>
				   </div>
					 <div className="container common-top-div pagesList-main-div">
						 <div className="cms-page">
							<div className="container-one cms-content">
							{this.listwhatshappenings()}
							</div>
						  </div>
						  
						</div>
					</div>
				 
				 <Footer />		
				 <div id="dvLoading" className="dvLoadrCls"></div>
		       </div>);
    }
}



const mapStateToProps = (state) => {
	
	var pagedataRst = Array();
	if(Object.keys(state.pagedata).length > 0) {
		  if(state.pagedata[0].status === 'ok') {
			 pagedataRst = state.pagedata[0].result_set;
		  }
	}
	
	return {
		pagedata: pagedataRst
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRequestpage: (slug) => {
		dispatch({ type: GET_REQUESTPAGEDATA, slug});
		},
	}
}

Ourvideos.propTypes = {
	history: PropTypes.shape({
	push: PropTypes.func.isRequired
	}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ourvideos));

 
