import { takeEvery, call, put } from "redux-saga/effects";
import { GET_SOCIALSETTINGS, SET_SOCIALSETTINGS } from "../actions";
import { appId, apiUrl, getHeader } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetSocialsetting = function*() {
  yield takeEvery(GET_SOCIALSETTINGS, watchGetSocialsettingData);
};

function* watchGetSocialsettingData() {
  try {
    const uri = apiUrl + "settings/get_social_settings?app_id=" + appId;
    const result = yield call(Axios.get, uri, getHeader());
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_SOCIALSETTINGS, value: resultArr });
  } catch (error) {
    console.log("Get social settings Failed", error);
  }
}
