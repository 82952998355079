/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { appId, cateringId, apiUrlV2, reservationId} from "../Helpers/Config";
import { getReferenceID, randomId, showLoader, hideLoader, showAlert, showCartItemCount, showCustomAlert, stripslashes } from "../Helpers/SettingHelper";
import cookie from 'react-cookies';
import axios from 'axios';
var qs = require('qs');
import Parser from 'html-react-parser';
import ClockImg from "../../common/images/clock.png";
import CantDlyImg from "../../common/images/Cant_Deliver.png";
import moment from 'moment';

import { GET_RES_AVAILABLE_DATE, GET_RES_AVAILABLE_TIME} from '../../actions';

class Reservationdatelist extends Component {


    constructor(props) {
        super(props);

        this.state = {
          avlDatelist: [],
          showDatelist: [],
          AvldateList:'',
          reservation_flag_next: 'NEXT',
          reservation_date_pre:'', 
          reservation_date_post:'',
          reservation_flag_time: '', 
          reservation_flag_key: '', 
          reservation_date_post: '', 
          timePickershow:0,
          selectedDateVal: '',
        };
		
		this.getAvailabileDateHtml = this.getAvailabileDateHtml.bind(this);
		
    }
    
    componentDidMount() {
		
		if(typeof cookie.load('reservation_date')!='undefined' && typeof cookie.load('reservation_date')!='') {
			this.setState({selectedDateVal:cookie.load('reservation_date')});
			this.getReservationTime(cookie.load('reservation_date_array'));
		}
	}
    
    
    componentWillReceiveProps(ResProps) {
		
		var DateListArr = ResProps.dateListOptions.dateListArr;
		
		//console.log('sds',DateListArr['shown_date']);
		if(Object.keys(DateListArr).length > 0) {
			this.setState({avlDatelist:DateListArr},function(){this.getAvailabileDateHtml()});
		}
		
		
	}
	
	showNextDates(lastDate, prevNext){
		console.log(this.props.stateVal.times_divide_pre);
		var day_avail = Array();
		var today = new Date();
		var month = today.getMonth()+1;
		if(today.getMonth()+1<10) {
			month = '0'+(today.getMonth()+1);
		}
		
		var dateDate = today.getFullYear().toString().substr(-2)+'-'+month+'-'+today.getDate();
		if(dateDate!=lastDate) {
			day_avail['app_id'] = appId;
			day_avail['outlet_id'] = this.props.stateVal.reservation_outlet_id;
			if(prevNext!='') {
				day_avail['flag_key'] = prevNext;
			}
			else {
				day_avail['flag_key'] = this.state.reservation_flag_next;	
			}
			
			day_avail['flag_date'] = lastDate;
			day_avail['flag_date_pre'] = this.state.reservation_date_pre;
			day_avail['flag_date_post'] = this.state.reservation_date_post;
			
			this.props.dateAvlfun(day_avail);
		}
		
	}
	
	getReservationTime(seletedDate){
		var timeAvail = Array();
		this.setState({selectedDateVal:seletedDate['day']});
		var selectedTime = '';
		if(typeof this.state.reservation_flag_time!='undefined' && this.state.reservation_flag_time!='') {
			selectedTime = 	this.state.reservation_flag_time;

		}else if(typeof cookie.load('reservation_next_start_time')!='undefined' && cookie.load('reservation_next_start_time')!='') {
			selectedTime = cookie.load('reservation_next_start_time');
		}
		var time_flag_next = '';
		if(typeof this.state.reservation_flag_key!='undefined' && this.state.reservation_flag_key!='') {
			time_flag_next = 	this.state.reservation_flag_key;

		}
		else if(typeof cookie.load('reservation_time_flag_next')!='undefined' && cookie.load('reservation_time_flag_next')!='') {
			time_flag_next = cookie.load('reservation_time_flag_next');
		}
		if(time_flag_next=='') {
			selectedTime = '';
		}
		
		
		timeAvail['app_id'] = appId;
		timeAvail['outlet_id'] = this.props.stateVal.reservation_outlet_id;
		timeAvail['availability_date'] = seletedDate['day'];
		timeAvail['reservation_show_date'] = seletedDate['shown_date'];
		timeAvail['reservation_seleted_date'] = seletedDate;
		timeAvail['flag_time'] = selectedTime;
		timeAvail['flag_key'] = time_flag_next;
		timeAvail['flag_time_pre'] = this.state.reservation_flag_time_pre;
		timeAvail['timeAvail'] = 1;
		this.props.SelectedDateOption(timeAvail);
		
	}
	
	
	getAvailabileDateHtml(){
		
		var AvailabileDateList = this.state.avlDatelist;
		var AvldateList = '';
		var RegDay = '';
		var RegMonth = '';
		var RegDat = '';
		var Dateinc = 1;
		let thisVal = this;
		if(Object.keys(AvailabileDateList).length > 0) {
			
			AvldateList = <div className="reg_dateList">
							{

								this.state.avlDatelist.map(function(datelist,index){
									RegDay = moment(datelist['shown_date']).format("ddd");
									RegMonth = moment(datelist['shown_date']).format("MMM");
									RegDat = moment(datelist['shown_date']).format("D");
									return (<div className="dateLsis" key={index}>
											{(index===10) && <li className="catering_date" onClick={thisVal.showNextDates.bind(thisVal,AvailabileDateList[0].day, 'PRE')}>
													<dl className="cl-next-days">
														<div>
															<span>Go BAck To Previous</span>
														</div>
													</dl>
												</li>}
												{(datelist['close']==1) && <li class="catering_date btn-disable-action"><dl class="date_inner"><div><dt>{RegDay}</dt><dd>{RegMonth} {RegDat}</dd></div><div class="tag"><span class="not-available">Not Available</span></div></dl></li>}
											{(datelist['close']==0) &&
											<li className="catering_date" onClick={thisVal.getReservationTime.bind(thisVal,datelist)}>
													<dl className={(thisVal.state.selectedDateVal===datelist['day'])?"date_inner active":"date_inner"} id={datelist['day']}>
														<div>
															<dt>{RegDay}</dt>
															<dd>{RegMonth} {RegDat}</dd>
														</div>
													</dl>
												</li>
											}
												{(index===12) && <li className="catering_date" onClick={thisVal.showNextDates.bind(thisVal,datelist['day'], 'NEXT')}>
													<dl className="cl-next-days">
														<div>
															<span>Show Next Days</span>
														</div>
													</dl>
												</li>}
										</div>)
								})
							}
						</div>
				
						
			
		}else{
			AvldateList = <div className="reg_dateList">
							<p>No available dates..</p>
						</div>
		}
		
		this.setState({AvldateList:AvldateList});
		
	}
    
	
	
	
    /* ViewProducts */

    render() {
		
        return (
		<div className="choose-date">
			<h3>Choose a date</h3>
			<div className="resp-datepicker datepicker_main_div">
				<ul className="catering_days_ul">
					{this.state.AvldateList}
				</ul>
			</div>
		</div>
		);
     }
}



export default (Reservationdatelist);
