/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { CountryTxt } from "../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  timeToConv12,
  dateConvFun,
  showPriceValue,
  showCustomAlert,
  stripslashes,
  getTimeFrmDate,
} from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import Moment from "moment";

import { GET_CATERINGHISTORY } from "../../actions";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import tickImg from "../../common/images/tick.png";
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import Parser from "html-react-parser";

class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageFromTxt: "others",
      cartTotalItmCount: 0,
      discountAmount: 0,
      deliveryCharge: 0,
      gstPercentage: 0,
      gstAmount: 0,
      result_set: [],
      cartData: [],
      cartItems: [],
      final_total_amount: 0,
      cart_brktime_list: [],
      status: "loading",
    };

    var orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";

    if (orderId !== "") {
      var custmParm =
        "&local_order_no=" + orderId + "&customer_id=" + cookie.load("UserId");
      this.props.getCateringHistory(custmParm);
    } else {
      showAlert("Error", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nxtProps) {
    if (this.state.cartData !== nxtProps.cateringOrderData) {
      this.setState(
        {
          status: "ok",
          cartItems: nxtProps.cateringOrderItems,
          cartData: nxtProps.cateringOrderData,
        },
        function() {
          this.makeBrkTimeList();
        }.bind(this)
      );
    }

    if (nxtProps.cateringOrderStatus === "no") {
      showAlert("Error", "Invalid order detail OR order Id.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentDidMount() {}

  makeBrkTimeList() {
    var cartDetailsSate = this.state.cartData;
    var cartItems = this.state.cartItems;
    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      var breaktimeCount = cartDetailsSate.order_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = Array();
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = Array();
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].item_breaktime_indexflag,
              break_time_start: cartItems[key].item_breaktime_started,
              break_time_end: cartItems[key].item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });

    setTimeout(function() {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 800);
  }

  getCartDetList() {
    var cartDetailsSate = this.state.cartData;
    var cartBrktimeLst = this.state.cart_brktime_list;
    if (Object.keys(cartDetailsSate).length > 0) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      if (breaktimeEnable === "Yes" && Object.keys(cartBrktimeLst).length > 0) {
        return this.cartBrkTimeList();
      } else {
        return this.cartListOnly();
      }
    }
  }

  handlerActTab(indx) {
    var ariaAxp = $("#headingTb" + indx)
      .find("a")
      .attr("aria-expanded");
    if (ariaAxp === "true") {
      $("#headingTb" + indx).addClass("act");
    } else {
      $("#headingTb" + indx).removeClass("act");
    }
    $(".panel-heading")
      .not($("#headingTb" + indx))
      .removeClass("act");
    $(".panel-collapse")
      .not($("#collapse" + indx))
      .removeClass("in");
  }

  cartListOnly() {
    var cartItems = this.state.cartItems;
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading act"
          role="tab"
          id="headingTb0"
          onClick={this.handlerActTab.bind(this, 0)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="true"
              href="#collapse0"
              className=""
            >
              Order Item Details
            </a>
          </h4>
        </div>
        <div
          id="collapse0"
          className="panel-collapse collapse mCustomScrollbar in"
          aria-expanded="true"
          href="#collapse0"
        >
          <div className="panel-body">{this.cartItemlist(cartItems)}</div>
        </div>
      </div>
    );
  }

  cartBrkTimeList() {
    var cartBrktimeLst = this.state.cart_brktime_list;

    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => (
      <div className="panel panel-default" key={indx}>
        <div
          className={indx === 0 ? "panel-heading act" : "panel-heading"}
          role="tab"
          id={"headingTb" + indx}
          onClick={this.handlerActTab.bind(this, indx)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded={indx === 0 ? "true" : "false"}
              href={"#collapse" + indx}
              className={indx === 0 ? "" : "collapsed"}
            >
              {cartBrk["cart_detaile"].break_time_lable} :{" "}
              <span className="hr">
                {timeToConv12(cartBrk["cart_detaile"].break_time_start)}
              </span>
            </a>
          </h4>
        </div>
        <div
          id={"collapse" + indx}
          className={
            indx === 0
              ? "panel-collapse collapse mCustomScrollbar in"
              : "panel-collapse collapse mCustomScrollbar"
          }
          aria-expanded={indx === 0 ? "true" : "false"}
          href={"#collapse" + indx}
        >
          <div className="panel-body">
            {this.cartItemlist(cartBrk["cart_items"])}
          </div>
        </div>
      </div>
    ));
    return cartBrktmhtml;
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1}>
          {/* package div start */}
          <div className="package">
            <div className="row package_cnt">
              <div className="col-sm-4 col-xs-4">
                <a href="javascript:void(0)">
                  <span className="title">
                    {stripslashes(cartLst.item_name)}
                  </span>
                </a>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="qtybxs"> {cartLst.item_qty} pax </div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="amt">
                  <span>{showPriceValue(cartLst.item_total_amount)}</span>
                </div>
              </div>
            </div>

            {this.showModifiers(cartLst.modifiers)}
          </div>
          {/* package div end */}

          <div className="brline"></div>

          {Object.keys(cartLst.addons_setup).length > 0 ? (
            <div className="addon">
              <h4>ADD-ONS</h4>
              {this.addonsSetupLst(cartLst)}
            </div>
          ) : (
            ""
          )}

          <div className="brline"></div>

          {Object.keys(cartLst.setup).length > 0 ? this.setupDet(cartLst) : ""}

          <div className="brline"></div>

          {cartLst.item_specification !== "" ? this.specialNotes(cartLst) : ""}
        </div>
      ));
      return cartlsthtml;
    }
  }

  showModifiers(mdfLst) {
    if (Object.keys(mdfLst).length > 0) {
      const mdflsthtml = mdfLst.map((mdfLt, indx3) => (
        <div key={indx3} className="package-list">
          <p className="one">{stripslashes(mdfLt.order_modifier_name)}</p>
          <p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
        </div>
      ));
      return mdflsthtml;
    }
  }

  showMdfValues(mdfValLst) {
    var mdfValueText = "";
    if (Object.keys(mdfValLst).length > 0) {
      var tempVl = Object.keys(mdfValLst).length - 1;
      for (var key in mdfValLst) {
        var cartmdfprice =
          parseFloat(mdfValLst[key].order_modifier_price) > 0
            ? " ( " + showPriceValue(mdfValLst[key].order_modifier_price) + " )"
            : "";
        mdfValueText +=
          parseInt(tempVl) === parseInt(key)
            ? stripslashes(mdfValLst[key].order_modifier_name) + cartmdfprice
            : stripslashes(mdfValLst[key].order_modifier_name) +
              cartmdfprice +
              ", ";
      }
    }
    return mdfValueText;
  }

  addonsSetupLst(cartLstDat) {
    var addonsSet = cartLstDat.addons_setup;
    const addonhtml = addonsSet.map((addonSt, indx4) => (
      <div key={indx4} className="row addon_list">
        <div className="col-sm-6 col-xs-9">
          <h5>
            <a href="javascript:void(0)">
              <span className="title">{addonSt.as_setup_title}</span>
            </a>
          </h5>
          {this.addonsSetupValues(addonSt.addons_setup_values)}
        </div>
        <div className="col-sm-6 col-xs-3">
          {/*<div className="amt">
										  <span>{showPriceValue(addonSt.as_setup_amount)}</span>
									</div>*/}
        </div>
      </div>
    ));
    return addonhtml;
  }

  addonsSetupValues(addonsVal) {
    if (Object.keys(addonsVal).length > 0) {
      const addonValhtml = addonsVal.map((addonVl, indx5) => (
        <p key={indx5}>
          {addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X{" "}
          {parseFloat(addonVl.asv_setup_val_price) > 0
            ? showPriceValue(addonVl.asv_setup_val_price)
            : ""}
          )
        </p>
      ));
      return addonValhtml;
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].os_setup_type;
      setupPrice += parseFloat(setupDt[key].os_setup_total_price);
    }

    return (
      <div className="buffet_setup">
        <div className="row">
          <div className="col-sm-6 col-xs-9">
            <h5>
              <a href="javascript:void(0)">
                <span className="title">{setupLbl} Setup</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-3">
            {/*<div className="amt">
							<span>{showPriceValue(setupPrice)}</span>
					</div>*/}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">{this.setupList(setupDt)}</div>
        </div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {stripslashes(setupVl.os_setup_name)} :{" "}
          {stripslashes(setupVl.os_setup_description)}{" "}
          {parseFloat(setupVl.os_setup_total_price) > 0
            ? " ( " + showPriceValue(setupVl.os_setup_total_price) + " )"
            : ""}
        </p>
      ));
      return setupDathtml;
    }
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.item_specification;

    return (
      <div className="buffet_setup" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a href="">
                <span className="title">Special Notes</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">
            <p>{special_not}</p>
          </div>
        </div>
      </div>
    );
  }

  cartHallData() {
    var cartDetailsMn = this.state.cartData;
    if (Object.keys(cartDetailsMn).length > 0) {
      if (cartDetailsMn.order_venue_type === "hall") {
        return (
          <div className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              id="headingTbH1"
              onClick={this.handlerActTab.bind(this, "H1")}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="false"
                  href="#collapseH1"
                  className="collapsed"
                >
                  Catering Hall Details
                </a>
              </h4>
            </div>
            <div
              id="collapseH1"
              className="panel-collapse fixed-height-cls collapse mCustomScrollbar"
              aria-expanded="false"
              href="#collapseH1"
            >
              <div className="panel-body">
                <div className="buffet_setup" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 col-xs-8">
                      <h5>
                        <a href="javascript:void(0)">
                          <span className="title">
                            {stripslashes(cartDetailsMn.order_hall_name)}
                          </span>
                        </a>
                      </h5>
                    </div>
                    <div className="col-sm-6 col-xs-4">
                      <div className="amt">
                        <span>
                          {showPriceValue(cartDetailsMn.order_hall_charges)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  getCartTotalDet() {
    var cartDeta = this.state.cartData;
    if (Object.keys(cartDeta).length > 0) {
      var promotionApplied = cartDeta.order_discount_applied;
      var promotionAmount =
        cartDeta.order_discount_amount !== ""
          ? parseFloat(cartDeta.order_discount_amount)
          : 0;

      return (
        <div className="total_amt">
          <div className="subtotal">
            <div className="col-sm-6 col-xs-6">SUBTOTAL</div>
            <div className="col-sm-6 rt">
              {showPriceValue(cartDeta.order_sub_total)}
            </div>
          </div>
          {parseFloat(cartDeta.order_service_charge_amount) > 0 && (
            <div className="subtotal">
              <div className="col-sm-6 col-xs-6">
                {parseFloat(cartDeta.order_service_charge) > 0 ? (
                  <span className="text-uppercase">
                    {cartDeta.order_servicecharge_displaylabel !== "" &&
                    cartDeta.order_servicecharge_displaylabel !== null
                      ? cartDeta.order_servicecharge_displaylabel
                      : "Service Charge"}{" "}
                    ({parseFloat(cartDeta.order_service_charge)}
                    %)
                  </span>
                ) : (
                  <span className="text-uppercase">
                    {cartDeta.order_servicecharge_displaylabel !== "" &&
                    cartDeta.order_servicecharge_displaylabel !== null
                      ? cartDeta.order_servicecharge_displaylabel
                      : "Service Charge"}
                  </span>
                )}
              </div>
              <div className="col-sm-6 rt">
                <span>
                  ${parseFloat(cartDeta.order_service_charge_amount).toFixed(2)}
                </span>
              </div>
            </div>
          )}
          {promotionApplied === "Yes" && (
            <div className="subtotal">
              <div className="col-sm-6 col-xs-6">Discount Amount</div>
              <div className="col-sm-6 rt">
                {showPriceValue(promotionAmount)}
              </div>
            </div>
          )}
          {parseFloat(cartDeta.order_tax_charge) > 0 && (
            <div className="subtotal">
              <div className="col-sm-6 col-xs-6">
                GST ({cartDeta.order_tax_charge} %)
              </div>
              <div className="col-sm-6 rt">
                {showPriceValue(cartDeta.order_tax_calculate_amount)}
              </div>
            </div>
          )}
          <div className="total">
            <div className="col-sm-6 col-xs-6">Total</div>
            <div className="col-sm-6 rt">
              {showPriceValue(cartDeta.order_total_amount)}
            </div>
          </div>

          <div className="clearfix"></div>
        </div>
      );
    }
  }

  showUnitNumber(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }

  setActiveTab = (tabid) => {
    cookie.save("fromOtherPageFlg", tabid);
    /*hashHistory.push("/");*/
  };

  render() {
    if (this.state.status === "ok") {
      var orderDetail = this.state.cartData;
      var custAdrr =
        typeof orderDetail.order_customer_address_line1 !== "undefined"
          ? orderDetail.order_customer_address_line1
          : "";
      custAdrr = custAdrr.replace("Singapore", "");

      return (
        <div>
          {/* Header start */}
          <Header />
          {/* Header End */}

          {/* catering-thankyou-page - start */}
          <div className="container common-top-div catering-thankyou-page">
            {/* innersection_wrap - start */}
            <div className="innersection_wrap tnk-you">
              <div className="mainacc_toptext tick">
                <img src={tickImg} />
                <h2>Thank You</h2>
                <p>Your order has been placed successfully</p>
              </div>

              {/* order-detail-maindiv - start */}
              <div className="thank-order-detaildiv">
                <div className="tnk-detail text-center">
                  <h2>YOUR ORDER DETAILS</h2>
                  <div className="tnk-order">
                    <h3>Order No - {orderDetail.order_local_no}</h3>
                    <p>
                      Order placed at :{" "}
                      {Moment(orderDetail.order_created_on).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </p>
                    <p>Our Staff will contact you for order confirmation</p>
                  </div>
                </div>

                <div className="tnk-delivery">
                  <div className="delivery-cart-div">
                    <div className="cart-direction">
                      <img className="cart-direction-left" src={scotterImg} />
                      <img className="cart-direction-right" src={mapImg} />
                    </div>
                    <div className="cart_row cart-header-first">
                      <div className="row">
                        <div className="col-xs-6 cart_left text-left">
                          <h4>Order Handling By</h4>
                          {orderDetail.outlet_name !== "" &&
                            orderDetail.outlet_name !== undefined && (
                              <p>{stripslashes(orderDetail.outlet_name)}</p>
                            )}
                          <p>{orderDetail.outlet_address_line1}</p>
                          <p>
                            {this.showUnitNumber(
                              orderDetail.outlet_unit_number1,
                              orderDetail.outlet_unit_number2
                            )}{" "}
                            {CountryTxt + " " + orderDetail.outlet_postal_code}
                          </p>
                        </div>
                        <div className="col-xs-6 cart_right text-right">
                          <h4>Catering Location</h4>
                          {orderDetail.order_hall_name !== "" ? (
                            <p>{stripslashes(orderDetail.order_hall_name)}</p>
                          ) : (
                            ""
                          )}
                          <p>{custAdrr}</p>
                          {orderDetail.order_customer_address_line2 !== "" ? (
                            <p>{orderDetail.order_customer_address_line2}</p>
                          ) : (
                            ""
                          )}
                          <p>
                            {this.showUnitNumber(
                              orderDetail.order_customer_unit_no1,
                              orderDetail.order_customer_unit_no2
                            )}{" "}
                            {CountryTxt +
                              " " +
                              orderDetail.order_customer_postal_code}
                          </p>
                          {/*<p>{this.showUnitNumber(orderDetail.order_customer_unit_no1,orderDetail.order_customer_unit_no2)}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="delivery_total delivery_total_number">
                    <div className="delivery_total_left">
                      <h2>Event Date</h2>
                      <h4 className="checkoutDate">
                        {Moment(orderDetail.order_date).format("DD/MM/YYYY")}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>Event time</h2>
                      <h4 className="checkoutTime">
                        {Moment(orderDetail.order_date).format("hh:mm A")}
                      </h4>
                    </div>
                  </div>

                  <div className="order_details">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.getCartDetList()}
                      {this.cartHallData()}
                    </div>
                  </div>

                  {this.getCartTotalDet()}
                </div>

                <div className="tnk-chk-order">
                  <Link className="button" to={"/myaccount"}>
                    Check Order Status
                  </Link>
                </div>
              </div>
              {/* order-detail-maindiv - end */}
            </div>
            {/* innersection_wrap - end */}
          </div>
          {/* thankyou-main-div - end */}

          <Footer />
        </div>
      );
    } else {
      return (
        <div>
          <div id="dvLoading"></div>
        </div>
      );
    }
  }
}

const mapStateTopProps = (state) => {
  var resultSetArr = Array();
  var orderStatus = "";
  var orderData = Array();
  var orderItems = Array();
  if (Object.keys(state.cateringhistory).length > 0) {
    var resultSetArr = !("result_set" in state.cateringhistory[0])
      ? Array()
      : state.cateringhistory[0].result_set;
    if (
      state.cateringhistory[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      orderData = resultSetArr[0];
      orderItems = resultSetArr[0]["items"];
      orderStatus = "yes";
    } else {
      orderStatus = "no";
    }
  }

  return {
    cateringOrderData: orderData,
    cateringOrderItems: orderItems,
    cateringOrderStatus: orderStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCateringHistory: (historyparams) => {
      dispatch({ type: GET_CATERINGHISTORY, historyparams });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Thankyou));
