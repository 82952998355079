/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";

import { stripslashes, hideLoader } from "../Helpers/SettingHelper";

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import Parser from "html-react-parser";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GET_REQUESTPAGEDATA} from '../../actions';
import { appId, apiUrl, apiUrlV2, timThumpUrl, webUrl } from "../Helpers/Config";


import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import innerBanner from "../../common/images/page-banner.jpg";
import Axios from 'axios';
import "bootstrap-icons/font/bootstrap-icons.css";



class GalleryDetail extends Component {

    constructor(props) {
        super(props);
    let page_slug;
		
		if ( this.props.match.params !== "" && this.props.match.params.page_slug !== undefined) {
		   page_slug = this.props.match.params.page_slug;
		} else {
		   page_slug = this.props.match.url.replace(/\\|\//g, "");
		}
	    this.state = {
	      staticblacks: [],
	      homejournyBlk: "",
	      partnerBlk: "",
	      partnerimgpath: "",
	      partnerimg: "",
	      homejournyimgBlk: [],
	      whatshappenings: [],
	      whatshappenings_source: "",
	      page_slug:page_slug
	    };
    }

  componentDidMount() {
    $(".dvLoadrCls").show();
    	Axios.get(apiUrl + 'cms/whats_happening?gallery=yes&gallery_slug='+this.state.page_slug+'&app_id='+appId).then(res => {

				if (res.data.status === "ok") {
					
					 $('.dvLoadrCls').fadeOut(500);

					let whatshappenings_source = res.data.common?res.data.common.image_source:'';
					let whatshappenings = res.data.result_set;

					this.setState({whatshappenings_source:whatshappenings_source, whatshappenings:whatshappenings, page_slug: whatshappenings.wh_name })
				  if(Object.keys(whatshappenings).length > 0) {
						 this.setState({page_slug: whatshappenings[0].wh_name})
					}


				} else if (res.data.status === "error") {
						 $('.dvLoadrCls').fadeOut(500);
				}

				return false;

			});
    setTimeout(function () {
      $(".dvLoadrCls").hide();
    }, 2000);

    setTimeout(function () {

$.fn.chunk = function(size) {
    var arr = [];
    for (var i = 0; i < this.length; i += size) {
        arr.push(this.slice(i, i + size));
    }
    return this.pushStack(arr, "chunk", size);
}

$(".wrapper > .wrap").chunk(3).wrap('<div class="row"></div>');

    }, 2000);

  }


	  listwhatshappenings() {

		  let whatshappenings = this.state.whatshappenings;
		  let whatshappenings_source = this.state.whatshappenings_source;
		  

		  if(Object.keys(whatshappenings).length > 0) {
			const mainMenu = whatshappenings.map((loaddata, index) => {
				
					return (<div className="container-two" key={'wh'+(index+1)}>
								<div className="container dark-gray-font wrapper">
									{loaddata.gallery_image.map((loaddatagall, gallindex) => {
									return(
											<div className="col-md-4 wrap"  key={'whs'+(gallindex+1)}>
												<div className="panel panel-default fixed-width center-block" data-target="#Modal1" data-toggle="modal">
													<div className="panel-body">
														<a href="#"> <img className="img-responsive center-block" src={whatshappenings_source+loaddatagall.gallery_image} ></img> </a></div>
												</div>
											</div>
									  );
								  
									})}

							</div>
						</div>);
				
			});
			
			return mainMenu;
		  } else {
			return null;
		  }



	  }

	  listwhatshappeningsslider() {

		  let whatshappenings = this.state.whatshappenings;
		  let whatshappenings_source = this.state.whatshappenings_source;
		  

		  if(Object.keys(whatshappenings).length > 0) {
			const mainMenu = whatshappenings.map((loaddata, index) => {
				
					return (<div className="container-two" key={'wh'+(index+1)}>
								<div className="container dark-gray-font">
									{loaddata.gallery_image.map((loaddatagall, gallindex) => {
									return(
											<div className={(gallindex == '1'?'active item':'item')}  key={'whss'+(gallindex+1)}>
											<img className="w-100" src={whatshappenings_source+loaddatagall.gallery_image} />
											</div>
									  );
								  
									})}

							</div>
						</div>);
				
			});
			
			return mainMenu;
		  } else {
			return null;
		  }



	  }




    render() {

    	$(document).on('click','.img-responsive',function(){
		  var src = $(this).attr('src');

		  $(document).find('.carousel-inner').find('.item').removeClass('active');
		  $(document).find('.carousel-inner').find('img[src$="'+src+'"]').parent('div').addClass('active');
			});

		return (<div>
		
				 <div className=" common-top-div pagesList-main-div">
				    <div className="Pages">
					 <Header />
					 </div>
					 <div className="common-inner-blckdiv">
						<div className="page-banner hidden">
							<img src={innerBanner} />
							<div className="inner-banner-content">
								<h2>{this.state.page_slug}</h2>
							</div>
						</div>
				   </div>
					 <div className="container common-top-div pagesList-main-div">
						 <div className="cms-page">
							<div className="container-one cms-content">
							{this.listwhatshappenings()}
							</div>
						  </div>
						</div>
					</div>
					<div className="modal fade" id="Modal1">
				<button className="close" data-dismiss="modal" title="Close" type="button"><span className="glyphicon glyphicon-remove"></span></button>
	<div className="modal-dialog">
		<div className="modal-content">
			<div className="modal-header">
				<button className="close" data-dismiss="modal" title="Close" type="button"><span className="glyphicon glyphicon-remove"></span></button></div>
			<div className="modal-body">
				<div className="carousel slide project-slide" data-interval="false" id="Modal1sel">
					<div className="carousel-inner">
							{this.listwhatshappeningsslider()}						
					</div>
					<a className="left carousel-control left-carousel" data-slide="prev" href="#Modal1sel" role="button"> <i className="bi bi-chevron-left"></i></a> <a className="right carousel-control right-carousel" data-slide="next" href="#Modal1sel" role="button"> <i className="bi bi-chevron-right"></i></a>
					</div>
			</div>
		</div>
	</div>
</div>
				 <Footer />		
				 <div id="dvLoading" className="dvLoadrCls"></div>
		       </div>);
    }
}



const mapStateToProps = (state) => {
	
	var pagedataRst = Array();
	if(Object.keys(state.pagedata).length > 0) {
		  if(state.pagedata[0].status === 'ok') {
			 pagedataRst = state.pagedata[0].result_set;
		  }
	}
	
	return {
		pagedata: pagedataRst
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRequestpage: (slug) => {
		dispatch({ type: GET_REQUESTPAGEDATA, slug});
		},
	}
}

GalleryDetail.propTypes = {
	history: PropTypes.shape({
	push: PropTypes.func.isRequired
	}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GalleryDetail));

 
