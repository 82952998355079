/* eslint-disable */
import React, { Component } from "react";

import { Link } from "react-router-dom";

import Slider from "react-slick";

import { stripslashes, hideLoader } from "../Helpers/SettingHelper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import Parser from "html-react-parser";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GET_REQUESTPAGEDATA } from "../../actions";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import innerBanner from "../../common/images/page-banner.jpg";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = { pagedata: [], pagedetail: "", pagetitle: "" };

    let page_slug;

    if (
      this.props.match.params !== "" &&
      this.props.match.params.page_slug !== undefined
    ) {
      page_slug = this.props.match.params.page_slug;
    } else {
      page_slug = this.props.match.url.replace(/\\|\//g, "");
    }
    this.props.getRequestpage(page_slug);
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function() {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.page_slug !== this.props.match.params.page_slug
    ) {
      if ($(".trigger_menu").length > 0) {
        $(".trigger_menu").toggleClass("active");
        if ($(".hmenu_list").hasClass("open")) {
          $(".mega_menu").slideUp();
        }
        $(".hmenu_list").toggleClass("open");
      }
      this.props.getRequestpage(nextProps.match.params.page_slug);
    }

    if (nextProps.pagedata !== this.state.pagedata) {
      $(".dvLoadrCls").fadeOut(500);

      let pagedataTxt =
        Object.keys(nextProps.pagedata).length > 0
          ? nextProps.pagedata[0].cmspage_description
          : "";
      let pageDetails = pagedataTxt !== "" ? Parser(pagedataTxt) : "";
      console.log(nextProps.pagedata, "nextProps.pagedata[0]");
      this.setState({
        pagetitle: nextProps.pagedata.cmspage_title,
        pagedata: nextProps.pagedata,
        pagedetail: pageDetails,
      });
    }
  }

  render() {
    $(document).on("click", ".img-responsive", function() {
      var src = $(this).attr("src");

      $(document)
        .find(".carousel-inner")
        .find(".item")
        .removeClass("active");
      $(document)
        .find(".carousel-inner")
        .find('img[src$="' + src + '"]')
        .parent("div")
        .addClass("active");
    });

    return (
      <div>
        <Header />
        <div className=" common-top-div pagesList-main-div">
          <div className="Pages">
            {/* Header start */}

            {/* Header End */}
          </div>
          <div className="common-inner-blckdiv">
            <div className="page-banner">
              <img src={innerBanner} />
              <div className="inner-banner-content">
                <h2>{this.state.pagetitle}</h2>
              </div>
            </div>
          </div>
          <div className="container common-top-div pagesList-main-div">
            <div className="cms-page">
              <div className="container-one cms-content">
                {this.state.pagedetail}
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var pagedataRst = Array();
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
    }
  }

  return {
    pagedata: pagedataRst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },
  };
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
