/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { appId, apiUrl, stripeReference, baseUrl, stripeCurrency, apiUrlV2  } from "../Helpers/Config";
import {
  removePromoCkValue,
  showAlert,
  showLoader,
  getReferenceID,
} from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import tickImage from "../../common/images/tick_popup.png";
import loadingImage from "../../common/images/loading_popup.gif";
import innerBanner from "../../common/images/page-banner.jpg";

var qs = require("qs");
class Placeordercatering extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_attempt: 0,
      validateimage: loadingImage,
      processingText: "We are processing your order",
    };

    setTimeout(
      function () {
        $.magnificPopup.open({
          items: {
            src: ".processing",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: false,
          closeOnBgClick: false,
        });
      }.bind(this),
      300
    );

    var urlParams = new URLSearchParams(this.props.location.search);
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");
    var referenceNewId = getReferenceID();

    if (
      urlParams.get("status") == "success" &&
      urlParams.get("sessionid") == cookie.load("paymentSession")
    ) {

        var paymentDetails = {};
        paymentDetails = {
          payment_type: "stripe",
          payment_status: "Success",
          payment_reference_1: urlParams.get("sessionid"),
          payment_transaction_amount: cookie.load("paymentAmount"),
          payment_reference_2: '',
          payment_date: '',
          payment_currency: stripeCurrency,
        };

        /*  load process html */
        var postObject = {};
        postObject = {
          app_id: appId,
          request_tableid: cookie.load("request_tableid"),
          order_id: cookie.load("order_id"),
          payment_details: JSON.stringify(paymentDetails),
        };

        if (cookie.load("request_tableid") !== "" && cookie.load("order_id") !== "") {
          axios
            .post(
              apiUrlV2 + "cateringorders/request_catering",
              qs.stringify(postObject)
            )
            .then((res) => {
              if (res.data.status === "ok") {
                this.setState({ placingorderimage: tickImage });
                this.deletePaymentCookie();
                setTimeout(function() {
                  $.magnificPopup.close();
                  window.location.href = "/payment/thankyou";
                }, 1000);
              } else if (res.data.status === "error") {
                showAlert("Error", res.data.message);
              }
            });
        } else {
          showAlert("Error", "Payment id was null.");
        }

    } else if (urlParams.get("status") == "failure") {
      setTimeout(
        function () {
          this.setState({
            processingText: "Please check in myorders, to get order details.",
          });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location = baseUrl;
      }, 5000);
    } else {
      setTimeout(
        function () {
          this.deletePaymentCookie();
          this.setState({
            processingText: "Please check in myorders, to get order details.",
          });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location.href = "/myorders";
      }, 5000);
    }
  }

  /* this function used to  delete all cookie values */
  deletePaymentCookie() {
    cookie.remove("cateringPay");
    cookie.remove("orderIdTxt");
    cookie.remove("paymentIdTxt");
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="placeorder-main-div checkout-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="page-banner">
            <img src={innerBanner} />
            <div className="inner-banner-content">
              <h2>Catering Payment</h2>
            </div>
          </div>
        </div>

        <div className="container common-top-div pagesList-main-div">
          <div className="cms-page">
            <div className="container-one cms-content">
              <h2>Payment Process</h2>
            </div>
          </div>
        </div>

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{this.state.processingText}</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_left">
                  <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                  <h5>Waiting for Payment Confirmation</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Placeordercatering);
