/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { appId, apiUrl, stripeReference, baseUrl } from "../Helpers/Config";
import {
  removePromoCkValue,
  showAlert,
  showLoader,
  getReferenceID,
} from "../Helpers/SettingHelper";
import cookie from "react-cookies";
import tickImage from "../../common/images/tick_popup.png";
import loadingImage from "../../common/images/loading_popup.gif";
import innerBanner from "../../common/images/page-banner.jpg";

var qs = require("qs");
class Placeorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_attempt: 0,
      validateimage: loadingImage,
      processingText: "We are processing your order",
    };

    setTimeout(
      function () {
        $.magnificPopup.open({
          items: {
            src: ".processing",
          },
          type: "inline",
          showCloseBtn: false,
          midClick: false,
          closeOnBgClick: false,
        });
      }.bind(this),
      300
    );

    var urlParams = new URLSearchParams(this.props.location.search);
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");
    var referenceNewId = getReferenceID();

    if (
      urlParams.get("status") == "success" &&
      urlParams.get("sessionid") == cookie.load("paymentSession")
    ) {
      var postObject =
        window.sessionStorage.getItem("postObject") +
        "&payment_session=" +
        cookie.load("paymentSession");

      axios
        .post(apiUrl + "ordersv1/submit_order", postObject)
        .then((res) => {
          if (res.data.status === "ok") {
            var localOrderNo = res.data.common.local_order_no;
            var subscription = res.data.common.subscription;
            var orderID = res.data.common.order_id;

            var statusObject = {};
            statusObject = {
              app_id: appId,
              order_primary_id: res.data.common.order_primary_id,
              payment_reference: stripeReference,
              customer_id: cookie.load("UserId"),
              customer_email: cookie.load("UserEmail"),
              payment_session: urlParams.get("sessionid"),
            };

            axios
              .post(
                apiUrl + "orders/statusUpdateSession",
                qs.stringify(statusObject)
              )
              .then((captureRes) => {
                if (captureRes.data.status === "ok") {
                  this.setState({ validateimage: tickImage });
                  this.showSuccessPage(localOrderNo);
                  return;
                } else {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText:
                      "Please check in myorders, to get order details.",
                  });
                  setTimeout(function () {
                    window.location.href = "/myorders";
                  }, 5000);
                }
              })
              .catch(
                function (error) {
                  this.deleteOrderCookie();
                  this.setState({
                    processingText:
                      "Please check in myorders, to get order details.",
                  });
                  setTimeout(function () {
                    window.location.href = "/myorders";
                  }, 5000);
                }.bind(this)
              );
          } else {
            this.deleteOrderCookie();
            this.setState({ processingText: res.data.message });
            showAlert("Error", res.data.message);
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            setTimeout(function () {
              window.location.href = "/myorders";
            }, 5000);
          }
        })
        .catch(
          function (error) {
            console.log(error, "errorerror");
            this.deleteOrderCookie();
            this.setState({
              processingText: "Please check in myorders, to get order details.",
            });
            setTimeout(function () {
              window.location.href = "/myorders";
            }, 5000);
          }.bind(this)
        );
    } else if (urlParams.get("status") == "failure") {
      setTimeout(
        function () {
          this.setState({
            processingText: "Please check in myorders, to get order details.",
          });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location = baseUrl + "checkout";
      }, 5000);
    } else {
      setTimeout(
        function () {
          this.deleteOrderCookie();
          this.setState({
            processingText: "Please check in myorders, to get order details.",
          });
        }.bind(this),
        300
      );
      setTimeout(function () {
        window.location.href = "/myorders";
      }, 5000);
    }
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    $.magnificPopup.close();
    window.location.href = "/thankyou/" + localOrderNo;
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("ocbcreferenceID", { path: "/" });
    cookie.remove("ocbcCaptureId", { path: "/" });
    cookie.remove("payment_intent", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("customer_tower_number", { path: "/" });

    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    window.sessionStorage.removeItem("postObject");
    window.sessionStorage.removeItem("paymentSession");
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="placeorder-main-div checkout-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="page-banner">
            <img src={innerBanner} />
            <div className="inner-banner-content">
              <h2>Order</h2>
            </div>
          </div>
        </div>

        <div className="container common-top-div pagesList-main-div">
          <div className="cms-page">
            <div className="container-one cms-content">
              <h2>Placing order</h2>
            </div>
          </div>
        </div>

        <div
          id="processing-popup"
          className="white-popup mfp-hide popup_sec processing"
        >
          <div className="pouup_in">
            <h3 className="title1 text-center">{this.state.processingText}</h3>
            <div className="process_inner">
              <div className="process_col">
                <div className="process_left">
                  <img src={this.state.validateimage} />
                </div>
                <div className="process_right">
                  <h5>Waiting for Payment Confirmation</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Placeorder);
