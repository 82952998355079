/* eslint-disable */
import React, { Component } from "react";

import { Link } from "react-router-dom";

import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  stripslashes,
  hideLoader,
  showAlert,
  numariconly,
  addressFormat,
} from "../Helpers/SettingHelper";

import {
  appId,
  apiUrl,
  apiUrlV2,
  timThumpUrl,
  webUrl,
} from "../Helpers/Config";

import Parser from "html-react-parser";

import { Formik, Form, Field, ErrorMessage } from "formik";
import ReactStars from "react-rating-stars-component";

import Select from "react-select";

import Axios from "axios";
import cookie from "react-cookies";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { GET_CONTACTDATA, GET_CONTACTCONTENT } from "../../actions";

import innerBanner from "../../common/images/page-banner.jpg";

import GeorgesMadbarGrill from "../../common/images/georges-madbar-grill.jpg";
import ct_icon1 from "../../common/images/ct-icon1.png";
import ct_icon2 from "../../common/images/ct-icon2.png";
import ct_icon3 from "../../common/images/ct-icon3.png";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outlets: [],
      outletOption: [],
      hearfrom: [
        { label: "Friends", value: "Friends" },
        { label: "Social media", value: "Social media" },
        { label: "Online", value: "Online" },
      ],
      pageTitle: "",
      pageDesc: "",
      status: "",
      windowHeight: 0,
      full_name: "",
      email: "",
      mobile: "",
    };
  }

  componentDidMount() {
    Axios.get(apiUrl + "outlets/getAllOutles?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        let outletOption = [];
        let dropDownEle;

        res.data.result_set.forEach((element) => {
          if (element.outlet_id !== "163") {
            dropDownEle = {
              label: element.outlet_name,
              value: element.outlet_id,
            };
            outletOption.push(dropDownEle);
          }
        });

        this.setState({
          outletOption: outletOption,
          outlets: res.data.result_set,
        });
      } else if (res.data.status === "error") {
      }
      $(".dvLoadrCls").fadeOut(500);
      return false;
    });
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contactdata !== this.props.contactdata) {
      if (nextProps.contactdata[0].status === "ok") {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        setTimeout(function() {
          window.location.reload();
        }, 2000);
      } else {
        this.handleShowAlertFun("success", nextProps.contactdata[0].message);

        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }

    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  getOutletData = () => {
    var outLetLst = this.state.outlets;
    var image_source = webUrl + "/media/dev_team/outlet/";
    if (Object.keys(outLetLst).length > 0) {
      const listhtml = outLetLst.map((item, index) => {
        return item.outlet_id !== "163" ? (
          <li className="outlets-list-column" key={"contact" + index}>
            <div className="outlet-item">
              <img src={image_source + item.outlet_image} />
              <h3>{stripslashes(item.outlet_name)}</h3>
              <div className="outlet-item-list">
                <ul>
                  <li>
                    <span>
                      <img src={ct_icon1} />
                      <p>
                        {addressFormat(
                          item.outlet_unit_number1,
                          item.outlet_unit_number2,
                          item.outlet_address_line1,
                          item.outlet_address_line2,
                          item.outlet_postal_code
                        )}
                      </p>
                    </span>
                  </li>
                  <li>{Parser(stripslashes(item.outlet_informations))}</li>
                  <li>
                    <span>
                      <img src={ct_icon2} />
                      <p>{item.outlet_phone}</p>
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={ct_icon3} />
                      <p>
                        <a
                          href={
                            "https://maps.google.com/maps?ll=" +
                            item.outlet_marker_latitude +
                            "," +
                            item.outlet_marker_longitude +
                            "&q=" +
                            encodeURIComponent(stripslashes(item.outlet_name)) +
                            "&hl=es;output=embed"
                          }
                          target="_blank"
                        >
                          How To Get There
                        </a>
                      </p>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        ) : (
          ""
        );
      });
      return listhtml;
    } else {
      return "";
    }
  };

  handleSubmitcontact = (
    values,
    { props = this.props, setSubmitting, resetForm }
  ) => {
    let selected_outlet = values.selected_outlet.value;
    let how_to_know = values.how_to_know.value;

    let qs = require("qs");
    let postObject = {
      app_id: appId,
      customer_first_name: values.full_name,
      customer_emailaddress: values.email,
      customer_phonenumber: values.mobile_number,
      customer_message: values.message,
      outles: values.selected_outlet.value,
      how_to_know: values.how_to_know.value,
      rating1: values.rating1,
      rating2: values.rating2,
      rating3: values.rating3,
      status: "A",
    };

    this.props.getContactData(qs.stringify(postObject));

    setSubmitting(false);
    resetForm({});
    return;
  };

  render() {
    return (
      <div className=" common-top-div pagesList-main-div">
        <div className="Pages">
          {/* Header start */}
          <Header />
          {/* Header End */}
        </div>
        <div className="common-inner-blckdiv">
          <div className="page-banner">
            <img src={innerBanner} />
            <div className="inner-banner-content">
              <h2>Contact Us</h2>
            </div>
          </div>
        </div>

        <div className="container common-top-div pagesList-main-div ">
          <div className="cms-page">
            <div className="container-one cms-content">
              <div className="contactus_wrapper">
                <div className="row">
                  {/* <div className="ct-left">
                <h2>Outlets</h2>

                <ul className="outlets-list-row">
                {this.getOutletData()}
                </ul>

              </div>
 */}
                  <div className="ct-left">
                    <h2>Feedback</h2>
                    <div className="form-feedback">
                      <Formik
                        initialValues={{
                          full_name: this.state.firstname,
                          mobile_number: this.state.mobile,
                          email: this.state.email,
                          message: this.state.message,
                        }}
                        onChange={() => {
                          // console.log('changing');
                        }}
                        enableReinitialize
                        validate={(values) => {
                          let errors = {};

                          if (!values.email) {
                            errors.email = "This Field is Required";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              values.email
                            )
                          ) {
                            errors.email = "Invalid email address";
                          }

                          if (!values.full_name) {
                            errors.full_name = "This Field is Required";
                          }

                          if (!values.mobile_number) {
                            // errors.mobile_number = "This Field is Required";
                          } else if (values.mobile_number.length < 8) {
                            errors.mobile_number =
                              "Mobile Number must be 8 characters at minimum";
                          }

                          if (!values.message) {
                            errors.message = "This Field is Required";
                          }

                          return errors;
                        }}
                        onSubmit={this.handleSubmitcontact}
                      >
                        {({
                          values,
                          setFieldValue,
                          handleChange,
                          isSubmitting,
                          resetForm,
                        }) => (
                          <Form>
                            <div className="form-group">
                              <div className="controls">
                                <Field
                                  type="text"
                                  placeholder="Name"
                                  name="full_name"
                                  className="form-control"
                                  value={values.full_name}
                                  onChange={handleChange}
                                />

                                <ErrorMessage
                                  component="div"
                                  name="full_name"
                                  className="error"
                                />
                              </div>

                              <div className="controls">
                                <Field
                                  type="text"
                                  placeholder="Email Address"
                                  className="form-control"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  component="div"
                                  name="email"
                                  className="error"
                                />
                              </div>

                              <div className="controls">
                                <Field
                                  type="tel"
                                  placeholder="Mobile Number"
                                  className="form-control"
                                  name="mobile_number"
                                  value={values.mobile_number}
                                  onChange={handleChange}
                                  onKeyPress={(e) => numariconly(e)}
                                  maxLength="12"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="mobile_number"
                                  className="error"
                                />
                              </div>

                              <div className="controls georges-contact">
                                <Select
                                  name="selected_outlet"
                                  className="geores-cont"
                                  options={this.state.outletOption}
                                  value={values.selected_outlet}
                                  placeholder="Select Outlet"
                                  onChange={(getValue) =>
                                    setFieldValue("selected_outlet", getValue)
                                  }
                                />
                              </div>

                              <div className="controls">
                                <Select
                                  name="how_to_know"
                                  className="geores-cont"
                                  options={this.state.hearfrom}
                                  value={values.how_to_know}
                                  placeholder="Where did you hear about us ?"
                                  onChange={(getValue) =>
                                    setFieldValue("how_to_know", getValue)
                                  }
                                />
                              </div>

                              <div className="controls">
                                <Field
                                  component="textarea"
                                  placeholder="Message"
                                  className="form-control"
                                  name="message"
                                  value={values.message}
                                  onChange={handleChange}
                                  rows="4"
                                />
                              </div>
                              <ErrorMessage
                                component="div"
                                name="message"
                                className="error"
                              />
                              <div className="rating-fback">
                                <div className="fback-item">
                                  <p>How would you rate our cleanliness ?</p>
                                  <ReactStars
                                    count={5}
                                    onChange={(getValue) =>
                                      setFieldValue("rating1", getValue)
                                    }
                                    size={30}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>

                              <div className="rating-fback">
                                <div className="fback-item">
                                  <p>How would you rate quality of our food</p>
                                  <ReactStars
                                    count={5}
                                    onChange={(getValue) =>
                                      setFieldValue("rating2", getValue)
                                    }
                                    size={30}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>

                              <div className="rating-fback">
                                <div className="fback-item">
                                  <p>
                                    How would you rate our service, friendliness
                                    & speed ?
                                  </p>
                                  <ReactStars
                                    count={5}
                                    onChange={(getValue) =>
                                      setFieldValue("rating3", getValue)
                                    }
                                    size={30}
                                    activeColor="#ffd700"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn_green btn_upp btn_lg myaccount_form"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Please wait..." : "Send"}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contactdata: state.contactdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject });
    },
  };
};

Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Contact)
);
